import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    useDisclosure,
    SimpleGrid,
    Box,
    Wrap,
    CircularProgress,
    Radio,
    RadioGroup,
    Stack,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { mascaraCpf, mascaraTelefone, retiraMascaraMaterNumeros } from '../../../../util/Masck';
import { useHistory } from "react-router-dom";

export default function FormSeringueiroCadastro() {

    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [nomeTela, setNomeTela] = useState('');

    const [isValidNome, setIsValidNome] = useState(false);
    const [inputTelefoneFixo, setInputTelefoneFixo] = useState('');
    const [operacao, setOperacao] = useState(null);
    const [isProcessComplete, setIsProcessComplete] = useState(false);
    const [mensageAlert, setMensageAlert] = useState('');

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [imputCpf, setImputCpf] = useState('');
    const [isValidCpf, setIsValidCpf] = useState(false);
    const [idUaP, setIdUaP] = useState(null);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [radioSexoP, setRadioSexoP] = useState('MASCULINO');
    const [idPessoaP, setIdPessoaP] = useState(null);
    const [pessoaAtivaP, setPessoaAtivaP] = useState(true);

    const [valueImputCelular, setValueImputCelular] = useState('');
    const [isValidCelular, setIsValidCelular] = useState(false);
    const [idUaDeSafraPessoa, setIdUaDeSafraPessoa] = useState(null);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');
    const [vigente, setVigente] = useState(false);
    const [dtInicioSafra, setDtInicioSafra] = useState(null);
    const [dtFimSafra, setDtFimSafra] = useState(null);
    const [valueImputEmail, setValueImputEmail] = useState('');
    const [messageCardInformacao, setMessageCardInformacao] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            const dadosProdutor = localStorage.getItem('@HE-datosParaEdicaoSeringueiroCadastro');

            if (dadosProdutor != null) {
                setOperacao('update');
                setNomeTela('Editar Seringueiro');
                const param = JSON.parse(dadosProdutor);
                localStorage.removeItem('@HE-datosParaEdicaoSeringueiroCadastro');

                const idPessoa = param.idPessoa;
                setIdPessoaP(idPessoa);

                const url = `api/pessoa/retonaUmaPessoaComUaAndSafra/${idPessoa}`;
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    const objPessoa = res;
                    setImpuNomeSeringueiro(objPessoa.nome != null && objPessoa.nome != '' ? objPessoa.nome : '');
                    setNomeFazenda(objPessoa.nomeFazenda != null && objPessoa.nomeFazenda != '' ? objPessoa.nomeFazenda : '');
                    setNomeSafra(objPessoa.nomeSafra != null && objPessoa.nomeSafra != '' ? objPessoa.nomeSafra : '');
                    setDtInicioSafra(objPessoa.dtInicioSafra != null && objPessoa.dtInicioSafra != '' ? moment(objPessoa.dtInicioSafra).format('DD/MM/YYYY') : null);
                    setDtFimSafra(objPessoa.dtFimSafra != null && objPessoa.dtFimSafra != '' ? moment(objPessoa.dtFimSafra).format('DD/MM/YYYY') : null);
                    setImputCpf(objPessoa.cpf != null && objPessoa.cpf != '' ? mascaraCpf(objPessoa.cpf) : '');
                    setInputTelefoneFixo(objPessoa.telefoneFixo != null && objPessoa.telefoneFixo != '' ? mascaraTelefone(objPessoa.telefoneFixo) : '');
                    setRadioSexoP(objPessoa.sexo != null && objPessoa.sexo != '' ? objPessoa.sexo : 'MASCULINO');
                    setPessoaAtivaP(objPessoa.ativo != null && objPessoa.ativo != '' ? objPessoa.ativo : false);
                    setIdUaP(objPessoa.idUa != null && objPessoa.idUa != '' ? objPessoa.idUa : null);
                    setValueImputEmail(objPessoa.emailPessoal != null && objPessoa.emailPessoal != '' ? objPessoa.emailPessoal : '');
                    const numCelular = objPessoa.celular != null && objPessoa.celular != '' ? objPessoa.celular : '';
                    setValueImputCelular(numCelular != '' ? mascaraTelefone(numCelular) : '');
                    const idSafraPessoa = objPessoa.idUaDeSafraPessoa != null && objPessoa.idUaDeSafraPessoa != '' ? objPessoa.idUaDeSafraPessoa : null;
                    setIdUaDeSafraPessoa(idSafraPessoa);
                    setMessageCardInformacao(idSafraPessoa == null ? 'Seringueiro não associado a nenhuma programação ou safra ativa' : '');
                    setVigente(objPessoa.vigente != null && objPessoa.vigente != '' ? objPessoa.vigente : false);

                    setTelaCarregada(true);
                }
            } else {
                setNomeTela('Cadastrar Seringueiro');
                setMessageCardInformacao('Novo cadastro');
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const salvarProdutor = async () => {
        try {
            let valid = false;

            if (impuNomeSeringueiro.length < 3) {
                setIsValidNome(true);
                valid = true;
            } else {
                setIsValidNome(false);
            }
            if (imputCpf.trim().length < 14) {
                setIsValidCpf(true);
                valid = true;
            } else {
                setIsValidCpf(false);
            }
            if (valueImputCelular.length < 15) {
                setIsValidCelular(true);
                valid = true;
            } else {
                setIsValidCelular(false);
            }

            if (valid) return;

            const permissoesSeringueiro = [
                { idPermissao: 1 },
                { idPermissao: 3 },
            ]

            const cpfSemMascara = retiraMascaraMaterNumeros(imputCpf);
            const fixoSemMascara = retiraMascaraMaterNumeros(inputTelefoneFixo);
            const celularSemMascara = retiraMascaraMaterNumeros(valueImputCelular);
            const emailP = `sangrador${celularSemMascara}@hevea.com`;

            const jsonPessoa = {
                idPessoa: idPessoaP,
                idUa: idUaP,
                nome: impuNomeSeringueiro,
                celular: celularSemMascara,
                ativo: pessoaAtivaP,
                urlImagemPerfil: null,
                dtCadastro: null,       // ESSE CARA PASSO NO BACK
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                sexo: radioSexoP == 'MASCULINO' ? ActionTypes.SEXO_MASCULINO : ActionTypes.SEXO_FEMININO,
                cpf: cpfSemMascara,
                telefoneFixo: fixoSemMascara,
                email: emailP,
                emailPessoal: valueImputEmail,
                senha: '123456',
                idTipoUsuario: ActionTypes.TIPO_USUARIO_SERINGUEIRO,  // ESSE CARA PASSO NO BACK
                permissoes: permissoesSeringueiro,
            }

            onOpen();
            if (operacao === 'update') {
                const response = await Api.putRequest(`api/pessoa/alterarSomentePessoa`, jsonPessoa);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    let valida = false;
                    let mensagem = 'Verifique os campos alterados:';

                    if (res.numeroCelularJaExiste == true || res.numeroCpfJaExiste == true) {

                        if (res.numeroCelularJaExiste == true) {
                            setIsValidCelular(true);
                            valida = true;
                            mensagem = `${mensagem}\nO campo celular foi alterado, mas o novo número informado já existe!`;
                        }
                        if (res.numeroCpfJaExiste == true) {
                            setIsValidCpf(true);
                            valida = true;
                            mensagem = `${mensagem} CPF já existe!`;
                        }
                        if (valida) {
                            setMensageAlert(`${mensagem}`);
                            onOpen();
                        }
                    } else {
                        setMensageAlert('Operação realizada com sucesso!');
                    }
                } else {
                    setMensageAlert('Erro no Processamento da Operação!');
                    setIsProcessComplete(true)
                }
                setIsProcessComplete(true);
            } else {
                const response = await Api.postRequest(`api/pessoa/cadastrarSomentePessoa`, jsonPessoa);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    let valida = false;
                    let mensagem = 'Verifique os campos:\n';

                    if (res.numeroCelularJaExiste == true || res.numeroCpfJaExiste == true) {

                        if (res.numeroCelularJaExiste == true) {
                            setIsValidCelular(true);
                            valida = true;
                            mensagem = `${mensagem} Celular já existe!`;
                        }
                        if (res.numeroCpfJaExiste == true) {
                            setIsValidCpf(true);
                            valida = true;
                            mensagem = `${mensagem} CPF já existe!`;
                        }
                        if (valida) {
                            setMensageAlert(`${mensagem}`);
                            onOpen();
                        }
                    } else {
                        setMensageAlert('Operação realizada com sucesso!');
                    }
                } else {
                    setMensageAlert('Erro no Processamento da Operação!');
                    setIsProcessComplete(true)
                }
                setIsProcessComplete(true);
            }
            setIsProcessComplete(true);
        } catch (error) {
            setIsProcessComplete(true);
            console.log('Erro na classe FormSeringueiroCadastro metodo salvarProdutor', error);
        }
    }

    const changeCelular = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setValueImputCelular(telComMascara);
    }

    const changeTelefonefixo = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputTelefoneFixo(telComMascara);
    }

    const changeCpf = (e) => {
        const tel = e.target.value;
        const val = mascaraCpf(tel);
        setImputCpf(val);
    }

    const closeAndGoBack = () => {
        onClose();
        if (operacao != 'update') history.goBack();
    }

    function renderInputs() {
        return (
            <Card direction='column' w='99%' mx='5px' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }} borderWidth={3} >
                <Box w={'98%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>Nome *</Text>
                            <Input
                                value={impuNomeSeringueiro}
                                onChange={(e) => setImpuNomeSeringueiro(e.target.value)}
                                isInvalid={isValidNome}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>CPF *</Text>
                                <Input
                                    value={imputCpf}
                                    onChange={changeCpf}
                                    isInvalid={isValidCpf}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>Celular *</Text>
                                <Input
                                    value={valueImputCelular}
                                    onChange={changeCelular}
                                    isInvalid={isValidCelular}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </Box>
                <Box w={'98%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>E-mail</Text>
                            <Input
                                value={valueImputEmail}
                                onChange={(e) => setValueImputEmail(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="email"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>Telefone Fixo</Text>
                                <Input
                                    value={inputTelefoneFixo}
                                    onChange={changeTelefonefixo}
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </Box>
                <Box p={4} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between" >
                    <RadioGroup onChange={setRadioSexoP} value={radioSexoP} defaultValue='2'>
                        <Stack spacing={5} direction='row'>
                            <Radio size='lg' name='1' colorScheme='blue' value='MASCULINO'>
                                <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Masculino</Text>
                            </Radio>
                            <Radio size='lg' name='1' colorScheme='blue' value='FEMININO'>
                                <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Feminino</Text>
                            </Radio>
                        </Stack>
                    </RadioGroup>
                </Box>
            </Card>
        )
    }

    function renderDadosSeringueiro() {
        return (
            <Box>
                <Box mx={'10px'} py={'0px'} my={'30px'} borderRadius={'10px'} >
                    {idUaDeSafraPessoa != null ?
                        <Box bgGradient={ActionTypes.LINEAR_GRADIENT_GREEN} borderRadius={'10px'}>
                            <Box py={'10px'}>
                                <Flex px='10px' justify='center' align='center'>
                                    <Text color={textColor} fontSize='20px' fontWeight='bold' lineHeight='100%'>Seringueiro em programação</Text>
                                </Flex>
                                <Flex px='10px' py={'10px'} justify='start' align='center' >
                                    <Text color={'gray.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>Propriedade:</Text>
                                    <Text ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>{nomeFazenda}</Text>
                                </Flex>
                                <Flex px='10px' mb={'10px'} justify='start' align='center' >
                                    <Text color={'gray.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>Safra:</Text>
                                    <Text ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>{nomeSafra}</Text>
                                </Flex>
                                <Flex px='10px' mb={'10px'} justify='start' align='center' >
                                    <Text color={'gray.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>Inicio:</Text>
                                    <Text ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>{dtInicioSafra}</Text>
                                </Flex>
                                <Flex px='10px' mb={'10px'} justify='start' align='center' >
                                    <Text color={'gray.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>Fim:</Text>
                                    <Text ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>{dtFimSafra}</Text>
                                </Flex>
                            </Box>
                        </Box>
                        :
                        <Box>
                            {messageCardInformacao != '' &&
                                <Box bgGradient={ActionTypes.LINEAR_GRADIENT_ORANGE} borderRadius={'10px'}>
                                    <Box py={'10px'}>
                                        <Flex px='10px' justify='center' align='center'>
                                            <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%'>{messageCardInformacao}</Text>
                                        </Flex>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    function renderModal() {
        return (
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cadastro</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex align='center' justifyContent={'center'} mb='1px' mt={'1px'} >
                            {isProcessComplete ?
                                <Text color={textColor} fontSize='16px' lineHeight='100%'>{mensageAlert}</Text>
                                :
                                <CircularProgress isIndeterminate color='orange.400' thickness={'12px'} size='50px' />
                            }
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={closeAndGoBack}>Ok</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Flex px='25px' justify='space-between' mb='20px' align='center'>
                    <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>{nomeTela}</Text>
                </Flex>
                {renderInputs()}
                {renderDadosSeringueiro()}
                <Wrap spacing='30px' mx={'10px'} justify='space-between'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button variant="brand" onClick={salvarProdutor}>Salvar</Button>
                </Wrap>
                {renderModal()}
            </Card>
        </Box>
    );
}
