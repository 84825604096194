import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Box,
    Flex,
} from "@chakra-ui/react";

function ModalDialogFinalizarSangria({ isOpen, onPressOk, onPressCancel }) {

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE} >
                <ModalHeader>{'Atenção'}</ModalHeader>
                <ModalBody>
                    <Text color={'blackAlpha.900'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{'Finalizando a sangria ocorrerá as seguintes ações:'}</Text>

                    <Box pl={'10px'} mt={'10px'}>
                        <Flex justify='start' align='start'>
                            <Text color={'blackAlpha.900'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'blackAlpha.900'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{'O processo de programação automático será encerrado.'}</Text>
                        </Flex>
                        <Flex mt={'5px'} justify='start' align='start'>
                            <Text color={'blackAlpha.900'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'blackAlpha.900'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{'Os dados estarão disponíveis no sistema, porém não será possível altera-los.'}</Text>
                        </Flex>
                        <Flex mt={'5px'} justify='start' align='start'>
                            <Text color={'blackAlpha.900'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'blackAlpha.900'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{'Após a finalização, os seringueiros registrados nessa safra continuarão cadastrados no sistema podendo ser incluídos em nova safra.'}</Text>
                        </Flex>
                    </Box>
                    <Text mt={'10px'} color={'blackAlpha.900'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{'Deseja realmente finalizar?'}</Text>
                </ModalBody>
                <ModalFooter borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button variant="brand" mr={3} onClick={onPressCancel}>Não</Button>
                    <Button variant="brand" onClick={onPressOk}>Sim</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalDialogFinalizarSangria;