import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Input,
    Button,
    SimpleGrid,
    Select,
    Icon,
    InputGroup,
    InputRightElement,
    Badge,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { MASCARAR_VALOR_SAZONALIDADE, SOMA_VALORES_SAZONALIDADE, CRIAR_JSON_SAZONALIDADE } from '../../../util/CalculoSazonalidade';
import { LuRefreshCw, LuPercent } from "react-icons/lu";
import MessageSreen from 'components/message/MessageSreen';
import ModalDialog from 'components/modal/ModalDialog';

export default function SazonalidadeList() {

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [mesageDialog, setMesageDialog] = useState('');

    const [JANEIRO, setJANEIRO] = useState('');
    const [FEVEREIRO, setFEVEREIRO] = useState('');
    const [MARCO, setMARCO] = useState('');
    const [ABRIL, setABRIL] = useState('');
    const [MAIO, setMAIO] = useState('');
    const [JUNHO, setJUNHO] = useState('');
    const [JULHO, setJULHO] = useState('');
    const [AGOSTO, setAGOSTO] = useState('');
    const [SETEMBRO, setSETEMBRO] = useState('');
    const [OUTUBRO, setOUTUBRO] = useState('');
    const [NOVEMBRO, setNOVEMBRO] = useState('');
    const [DEZEMBRO, setDEZEMBRO] = useState('');
    const [valorTotalPercent, setValorTotalPercent] = useState('0');

    const [listUa, setListUa] = useState([]);
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [listUaCompleto, setListUaCompleto] = useState([]);
    const [listSafra, setListSafra] = useState([]);
    const [safraSelecionado, setSafraSelecionado] = useState('');
    const [listSazonalidadeOriginal, setListSazonalidadeOriginal] = useState([]);

    const [vigente, setVigente] = useState(true);
    const [sangriaAtiva, setSangriaAtiva] = useState(true);


    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const response = await Api.getRequest(`sazonalidade/listAllUa`);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                setListUaCompleto(res);

                if (res.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            res.map(function (item) {
                                const obj = {
                                    label: item.nomeFazenda,
                                    value: `${item.idUa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListUa(arrayPess);
                }
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, []);

    async function carregarInputs(res = []) {
        let totalPercent = 0;

        for (let i = 0; i < res.length; i++) {
            const item = res[i];

            const xx = parseFloat(item.valor);
            const aa = xx * 100;
            totalPercent = totalPercent + aa;

            const valStr = aa.toFixed(1);
            const val = await MASCARAR_VALOR_SAZONALIDADE(valStr);

            if (item.numMes == 1) {
                setJANEIRO(val);
            } else if (item.numMes == 2) {
                setFEVEREIRO(val);
            } else if (item.numMes == 3) {
                setMARCO(val);
            } else if (item.numMes == 4) {
                setABRIL(val);
            } else if (item.numMes == 5) {
                setMAIO(val);
            } else if (item.numMes == 6) {
                setJUNHO(val);
            } else if (item.numMes == 7) {
                setJULHO(val);
            } else if (item.numMes == 8) {
                setAGOSTO(val);
            } else if (item.numMes == 9) {
                setSETEMBRO(val);
            } else if (item.numMes == 10) {
                setOUTUBRO(val);
            } else if (item.numMes == 11) {
                setNOVEMBRO(val);
            } else if (item.numMes == 12) {
                setDEZEMBRO(val);
            }
        }
        const ttGeral = totalPercent.toFixed(1);
        setValorTotalPercent(ttGeral);
        setTelaCarregada(true);
    }

    const validarValores = async () => {
        const valor = await somaValores();

        if (valor != '100.0') {
            const mesage = 'A soma dos valores está diferente de 100%, deseja continuar';
            setMesageDialog(mesage);
            setOpenModalDialog(true);
        } else {
            setMesageDialog('');
            salvarValores();
        }
    }

    const salvarValores = async () => {
        try {
            setOpenModalDialog(false);

            const arryPercentual = [
                { numMes: 1, nomeMes: 'JANEIRO', valor: JANEIRO },
                { numMes: 2, nomeMes: 'FEVEREIRO', valor: FEVEREIRO },
                { numMes: 3, nomeMes: 'MARCO', valor: MARCO },
                { numMes: 4, nomeMes: 'ABRIL', valor: ABRIL },
                { numMes: 5, nomeMes: 'MAIO', valor: MAIO },
                { numMes: 6, nomeMes: 'JUNHO', valor: JUNHO },
                { numMes: 7, nomeMes: 'JULHO', valor: JULHO },
                { numMes: 8, nomeMes: 'AGOSTO', valor: AGOSTO },
                { numMes: 9, nomeMes: 'SETEMBRO', valor: SETEMBRO },
                { numMes: 10, nomeMes: 'OUTUBRO', valor: OUTUBRO },
                { numMes: 11, nomeMes: 'NOVEMBRO', valor: NOVEMBRO },
                { numMes: 12, nomeMes: 'DEZEMBRO', valor: DEZEMBRO }
            ];

            const jsonParam = await CRIAR_JSON_SAZONALIDADE(arryPercentual, listSazonalidadeOriginal, uaSelecionado, safraSelecionado);  ///safraSelecionado
            const response = await Api.putRequest(`sazonalidade/atualisarSazonalidadeProducao`, jsonParam);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Error na classe SazonalidadeList metodo salvarValores', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const changeJaneiro = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setJANEIRO(vm);
    }
    const changeFevereiro = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setFEVEREIRO(vm);
    }
    const changeMarco = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setMARCO(vm);
    }
    const changeAbril = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setABRIL(vm);
    }
    const changeMaio = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setMAIO(vm);
    }
    const changeJunho = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setJUNHO(vm);
    }
    const changeJulho = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setJULHO(vm);
    }
    const changeAgosto = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setAGOSTO(vm);
    }
    const changeSetembro = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setSETEMBRO(vm);
    }
    const changeOutubro = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setOUTUBRO(vm);
    }
    const changeNovembro = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setNOVEMBRO(vm);
    }
    const changeDezembro = async (val) => {
        const vm = await MASCARAR_VALOR_SAZONALIDADE(val);
        setDEZEMBRO(vm);
    }

    const somaValores = async () => {
        const soma = await SOMA_VALORES_SAZONALIDADE(JANEIRO, FEVEREIRO, MARCO, ABRIL, MAIO, JUNHO, JULHO, AGOSTO, SETEMBRO, OUTUBRO, NOVEMBRO, DEZEMBRO);
        setValorTotalPercent(soma);
        return soma;
    }

    const changeUa = async (val, res) => {
        try {
            setSafraSelecionado('');
            setUaSelecionado(val);
            if (val != '') {

                const arrayUa = res.filter(x => x.idUa == val);
                const safras = arrayUa.length > 0 ? arrayUa[0].listaSafraItem : [];

                if (safras.length > 0) {

                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            safras.map(function (item) {
                                const obj = {
                                    label: item.nomeSafra,
                                    value: `${item.idSafra}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSafra(arrayPess);
                }else{
                    setListSafra([]);
                }
            }
        } catch (error) {
            console.log('Error na classe SazonalidadeList metodo changeUa', error);
        }
    }

    const pesquiarSazonalidade = async (val) => {
        try {
            setSafraSelecionado(val);
            if (val != '') {
                const response = await Api.getRequest(`sazonalidade/pesquisaSazonalidadeProducaoPorSafra?idSafra=${val}`);        // 111111111111111111111

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    setVigente(res.vigente);
                    setSangriaAtiva(res.sangriaAtiva);


                    const listaSazonalidade = res.listaSazonalidade;
                    setListSazonalidadeOriginal(listaSazonalidade);
                    await carregarInputs(listaSazonalidade);
                }
            }
        } catch (error) {
            console.log('Error na classe SazonalidadeList metodo pesquiarSazonalidade', error);
        }
    }

    function renderUaAndSafra() {
        const ischeckd = listSafra.length > 0 ? false : true;
        return (
            <Flex px={'5px'} py={'5px'} justify='space-around' align='end' borderWidth={'2px'} borderRadius={'10px'} borderColor={'orange.600'}>
                <Flex flexDirection={'column'} justify='center' align='center'>
                    <Box ml={'10px'}>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%'>Propriedades</Text>
                    </Box>
                    <Select
                        value={uaSelecionado}
                        errorBorderColor='crimson'
                        placeholder="selecione"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={(e) => changeUa(e.target.value, listUaCompleto)}
                        w={'350px'}
                        h={'35px'}
                    >
                        {listUa.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
                <Flex flexDirection={'column'} justify='center' align='center'>
                    <Box ml={'10px'}>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%'>Safras</Text>
                    </Box>
                    <Select
                        value={safraSelecionado}
                        errorBorderColor='crimson'
                        placeholder="selecione"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={(e) => pesquiarSazonalidade(e.target.value)}
                        w={'350px'}
                        h={'35px'}
                        disabled={ischeckd}
                    >
                        {listSafra.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
            </Flex>
        )
    }

    function renderInputs() {
        const fontSizeP = '15px';
        return (
            <Box 
                pointerEvents={sangriaAtiva ? 'auto' : 'none'}
                opacity={sangriaAtiva ? '1' : '0.5'}
                my={'20px'} 
                w={'100%'} 
                borderWidth={'1px'} 
                borderRadius={'10px'} 
                borderColor={'blackAlpha.600'}>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 12 }} gap='5px' mb='20px' mt={'5px'} >
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Janeiro</Text>
                            <InputGroup>
                                <Input
                                    value={JANEIRO}
                                    onChange={(e) => changeJaneiro(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Fevereiro</Text>
                            <InputGroup>
                                <Input
                                    value={FEVEREIRO}
                                    onChange={(e) => changeFevereiro(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Março</Text>
                            <InputGroup>
                                <Input
                                    value={MARCO}
                                    onChange={(e) => changeMarco(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Abril</Text>
                            <InputGroup>
                                <Input
                                    value={ABRIL}
                                    onChange={(e) => changeAbril(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Maio</Text>
                            <InputGroup>
                                <Input
                                    value={MAIO}
                                    onChange={(e) => changeMaio(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Junho</Text>
                            <InputGroup>
                                <Input
                                    value={JUNHO}
                                    onChange={(e) => changeJunho(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Julho</Text>
                            <InputGroup>
                                <Input
                                    value={JULHO}
                                    onChange={(e) => changeJulho(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Agosto</Text>
                            <InputGroup>
                                <Input
                                    value={AGOSTO}
                                    onChange={(e) => changeAgosto(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Setembro</Text>
                            <InputGroup>
                                <Input
                                    value={SETEMBRO}
                                    onChange={(e) => changeSetembro(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Outubro</Text>
                            <InputGroup>
                                <Input
                                    value={OUTUBRO}
                                    onChange={(e) => changeOutubro(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Novembro</Text>
                            <InputGroup>
                                <Input
                                    value={NOVEMBRO}
                                    onChange={(e) => changeNovembro(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Dezembro</Text>
                            <InputGroup>
                                <Input
                                    value={DEZEMBRO}
                                    onChange={(e) => changeDezembro(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={5}
                                />
                                <InputRightElement width={'35px'} height={'35px'}>
                                    <Icon as={LuPercent} width='15px' height='15px' color='inherit' />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Card>
                </SimpleGrid>
            </Box>
        )
    }

    function renderTotal() {
        let tt = valorTotalPercent.replace('.', ',');
        let corTotal = 'green';
        if (valorTotalPercent == '100.0') {
            corTotal = 'green';
            tt = '100'
        } else {
            corTotal = 'red';
        }
        return (
            <Flex justify='space-between' align='center'>
                <Button isDisabled={safraSelecionado == '' ? true : false} variant="brand" onClick={validarValores}>Salvar</Button>
                <Flex px={'5px'} py={'5px'} justify='end' align='center' borderWidth={'2px'} borderLeftRadius={'20px'} borderColor={'orange.600'}>
                    <Button
                        variant="brand"
                        rightIcon={<LuRefreshCw />}
                        size="sm"
                        onClick={somaValores}>Atualizar Total</Button>
                    <Text color={corTotal} fontSize={'20px'} fontWeight='bold' ml={'20px'}>{`${tt}%`}</Text>
                </Flex>
            </Flex>
        )
    }

    function renderAlert() {
        return (
            <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                <Flex px='25px' justify='center' my='5px' align='center' >
                    <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                </Flex>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'20px'} py={'10px'} >
                <Box position={'relative'}>
                    {isVisibleAlert && renderAlert()}
                </Box>

                <Box py={'10px'}>
                    <Flex flexDirection={'column'} px='10px' justify='center' align='center'>
                        <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%'>{'Cada safra tem sua sazonalidade individual'}</Text>
                        <Text color={textColor} fontSize='13px' fontWeight='light' lineHeight='100%'>{'Selecione uma propriedade e uma safra para carregar os valores'}</Text>
                        {!sangriaAtiva && <Badge colorScheme='red' variant='solid'>Finalizada</Badge>}
                    </Flex>
                </Box>

                {renderUaAndSafra()}
                {renderInputs()}
                {renderTotal()}
            </Card>
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { salvarValores() }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={mesageDialog} />}
        </Box>
    );
}
