import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Image,
    Button,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Icon,
    StatLabel,
    Link,
    Stat,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import moment from 'moment';
import * as ActionTypes from '../../../../constants/ActionTypes';
import * as Api from 'store/ApiSpring';
import ModalDialog from 'components/modal/ModalDialog';
import RelatorioEmitido from 'assets/img/layout/relatorio_emitido.png';
import RelatorioEsporadico from 'assets/img/layout/relatorio_emitido_esporadico.png';
import { IoTrashSharp } from "react-icons/io5";

export default function RelatorioEmitidoPersonalizadoAndEsporadido() {

    const [openModalDialogPersonalizado, setOpenModalDialogPersonalizado] = useState(false);
    const [openModalDialogEsporadico, setOpenModalDialogEsporadico] = useState(false);
    const [dadosItemRelatorio, setDadosItemRelatorio] = useState(false);
    const [arrayUrlPersonalizados, setArrayUrlPersonalizados] = useState([]);
    const [arrayUrlEsporadico, setArrayUrlEsporadico] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');
    const bg = useColorModeValue("white", "navy.700");

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            const idUser = localStorage.getItem('@HE-idUsuarioLogado');

            const url = `api/urlArquivo/listaUrlRelatoriosPersonalizadosAndEsporadicos?idUa=${ua}&idSafra=${idSafra}&idUsuario=${idUser}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

                const res = response.respostaRequisicao.data;
                const arrPersonalizados = res.listUrlArquivoPersonalizado;
                const arrEsporadico = res.listUrlArquivoEsporadico;

                if (arrPersonalizados.length > 0) {
                    const val = await criaArrayComUrl(arrPersonalizados, RelatorioEmitido);
                    setArrayUrlPersonalizados(val);
                }
                if (arrEsporadico.length > 0) {
                    const val = await criaArrayComUrl(arrEsporadico, RelatorioEmitido);
                    setArrayUrlEsporadico(val);
                }
            }
        }
        loaderScreen();
    }, []);

    const criaArrayComUrl = async (arr, imgP) => {
        let result = arr.map(x => {
            const dtCricao = moment(x.dtCriacao).format('DD/MM/YYYY');
            return {
                idUrldArquivo: x.idUrldArquivo,
                url: x.urlStorage,
                img: imgP,
                titulo: x.nomeUsuario,
                idUsuarioDoRelatorio: x.idUsuario,
                dataRelatorio: dtCricao
            }
        })
        return result;
    }

    const confirmacaoExclusaoPersonalizado = async (item) => {
        setDadosItemRelatorio(item);
        setOpenModalDialogPersonalizado(true);
    }
    const inserirFlagDeletadoPersonalizado = async (item) => {
        setOpenModalDialogPersonalizado(false);
        const json = {
            idUrldArquivo: item.idUrldArquivo,
        }
        const url = `api/urlArquivo/gravarFlagDeArquivoDeletado`;
        const response = await Api.putRequest(url, json);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const arrayFil = arrayUrlPersonalizados.filter(x => x.idUrldArquivo != item.idUrldArquivo);
            setArrayUrlPersonalizados(arrayFil);
        }
    }

    const confirmacaoExclusaoEsporadico = async (item) => {
        setDadosItemRelatorio(item);
        setOpenModalDialogEsporadico(true);

    }
    const inserirFlagDeletadoEsporadico = async (item) => {
        setOpenModalDialogEsporadico(false);
        const json = {
            idUrldArquivo: item.idUrldArquivo,
        }
        const url = `api/urlArquivo/gravarFlagDeArquivoDeletado`;
        const response = await Api.putRequest(url, json);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const arrayFil = arrayUrlEsporadico.filter(x => x.idUrldArquivo != item.idUrldArquivo);
            setArrayUrlEsporadico(arrayFil);
        }
    }

    function renderRelatoriosPersonalizados() {
        if (arrayUrlPersonalizados.length == 0) {
            return (
                <Card align='center' direction='column' w='100%'>
                    <Text color={textColor} fontSize='20px' fontWeight='700' lineHeight='100%' align={'center'} >Sem itens</Text>
                </Card>
            )
        } else {
            return (
                <Card py={'10px'} align='center' direction='column' w='100%'>
                    <Text color={textColor} fontSize='20px' fontWeight='bold' align={'center'} lineHeight={'100%'}>Clique na imagem para baixar</Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 9 }} gap='20px' mb='20px' mt={'30px'}>
                        {arrayUrlPersonalizados.map((item, index) => {
                            return (
                                <Card key={index} py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                        <Stat h={'100%'} my='auto' ms={"0px"}>
                                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{item.titulo}</StatLabel>
                                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{item.dataRelatorio}</StatLabel>
                                            <Flex align='end' mt={'10px'} justify={'center'}>
                                                <Link color='teal.500' href={`${item.url}`}>
                                                    <Image h={'50px'} w={'50px'} src={RelatorioEmitido} />
                                                </Link>
                                                <Box pos="absolute" right={0} bottom={0}>
                                                    <Button h="25px" w={'25px'} bg="transparent" onClick={() => { confirmacaoExclusaoPersonalizado(item) }}>
                                                        <Icon as={IoTrashSharp} width='25px' height='25px' color='black' />
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </Stat>
                                    </Flex>
                                </Card>
                            )
                        })}
                    </SimpleGrid>
                </Card>
            )
        }
    }

    function renderRelatoriosEsporadicos() {
        if (arrayUrlEsporadico.length == 0) {
            return (
                <Card align='center' direction='column' w='100%'>
                    <Text color={textColor} fontSize='20px' fontWeight='700' lineHeight='100%' align={'center'} >Sem itens</Text>
                </Card>
            )
        } else {
            return (
                <Card py={'10px'} align='center' direction='column' w='100%'>
                    <Text color={textColor} fontSize='20px' fontWeight='bold' align={'center'} lineHeight={'100%'}>Clique na imagem para baixar</Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 9 }} gap='20px' mb='20px' mt={'30px'}>
                        {arrayUrlEsporadico.map((item, index) => {
                            return (
                                <Card key={index} py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                        <Stat h={'100%'} my='auto' ms={"0px"}>
                                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{item.titulo}</StatLabel>
                                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{item.dataRelatorio}</StatLabel>
                                            <Flex align='end' mt={'10px'} justify={'center'}>
                                                <Link color='teal.500' href={`${item.url}`}>
                                                    <Image h={'50px'} w={'50px'} src={RelatorioEsporadico} />
                                                </Link>
                                                <Box pos="absolute" right={0} bottom={0}>
                                                    <Button h="25px" w={'25px'} bg="transparent" onClick={() => { confirmacaoExclusaoEsporadico(item) }}>
                                                        <Icon as={IoTrashSharp} width='25px' height='25px' color='black' />
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </Stat>
                                    </Flex>
                                </Card>
                            )
                        })}
                    </SimpleGrid>
                </Card>
            )
        }
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%' px='5px' py='0px'>
                <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Opções de Relatório</Text>
                <Box w={'100%'} mt={'10px'}>
                    <Tabs isFitted variant='enclosed' borderRadius={'15px'} borderWidth={'2px'} borderColor={'blackAlpha.700'}>
                        <TabList>
                            <Tab _selected={{ color: 'white', bg: 'blue.600' }} borderTopLeftRadius={'15px'} fontSize={'15px'} fontWeight={'bold'}>Personalizado</Tab>
                            <Tab _selected={{ color: 'white', bg: 'blue.600' }} borderTopRightRadius={'15px'} fontSize={'15px'} fontWeight={'bold'}>Esporádico</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {renderRelatoriosPersonalizados()}
                            </TabPanel>
                            <TabPanel>
                                {renderRelatoriosEsporadicos()}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Card>
            {openModalDialogPersonalizado &&
                <ModalDialog
                    isOpen={openModalDialogPersonalizado}
                    onPressOk={() => { inserirFlagDeletadoPersonalizado(dadosItemRelatorio) }}
                    onPressCancel={() => { setOpenModalDialogPersonalizado(false) }}
                    descricao={'Deseja Realmente excluir?'} />}
            {openModalDialogEsporadico &&
                <ModalDialog
                    isOpen={openModalDialogEsporadico}
                    onPressOk={() => { inserirFlagDeletadoEsporadico(dadosItemRelatorio) }}
                    onPressCancel={() => { setOpenModalDialogEsporadico(false) }}
                    descricao={'Deseja Realmente excluir?'} />}
        </Box>
    );
}
