import { Box, SimpleGrid } from "@chakra-ui/react";
import ListSeringueiro from "views/admin/historicoSeringueiro/list/ListSeringueiro";

import React from "react";

export default function Settings() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}>
                <ListSeringueiro />
            </SimpleGrid>
        </Box>
    );
}
