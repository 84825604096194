import React from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Icon,
} from "@chakra-ui/react";
import * as ActionTypes from '../../constants/ActionTypes';
import { MdBrightness1 } from "react-icons/md";

const CardValidacaoSafra = ({
    validarSafra,
    isAbilitarGeracao = true,
    isSangriaAtiva = true,
    isVigente = true,
    isDtInicioSafraMaiorQueDataAtual = true,
    isDtFimSafraMenorQueDataAtual = true,
    isDtInicioSangriaMaiorQueDataAtual = true,
    isDtFimSangriaMenorQueDataAtual = true,
    isDtInicioSangriaEstaNull = true,
    isDtFimSangriaEstaNull = true,
    isProgramacaoEmAndamento = true,
}) => {

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const fontSizeP = '14px';

    return (
        <Box w={'100%'}>
            {!isAbilitarGeracao &&
                <Box py={'10px'} borderRadius={'20px'} mb={'0px'} bgGradient={ActionTypes.LINEAR_GRADIENT_ORANGE}>
                    <Text color={'black'} fontSize='16px' fontWeight='bold' lineHeight='100%' mb={'10px'}>{'Não é possível gerar programação, verifique os seguintes itens:'}</Text>
                    {!validarSafra.sangriaAtiva && isSangriaAtiva &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' align={'start'}>{'A safra está inativa.'}</Text>
                        </Flex>
                    }
                    {!validarSafra.vigente && isVigente &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' align={'start'}>{'O período de vigência está finalizado.'}</Text>
                        </Flex>
                    }
                    {validarSafra.dtInicioSafraMaiorQueDataAtual && isDtInicioSafraMaiorQueDataAtual &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' align={'start'}>{'Data de INÍCIO da SAFRA é maior que a data atual'}</Text>
                        </Flex>
                    }
                    {validarSafra.dtFimSafraMenorQueDataAtual && isDtFimSafraMenorQueDataAtual &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' align={'start'}>{'Data FIM da SAFRA é menor que a data atual'}</Text>
                        </Flex>
                    }
                    {validarSafra.dtInicioSangriaMaiorQueDataAtual && isDtInicioSangriaMaiorQueDataAtual &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' align={'start'}>{'Data INÍCIO da SANGRIA é menor que a data atual'}</Text>
                        </Flex>
                    }
                    {validarSafra.dtFimSangriaMenorQueDataAtual && isDtFimSangriaMenorQueDataAtual &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' align={'start'}>{'Data FIM da SANGRIA é menor que a data atual'}</Text>
                        </Flex>
                    }
                    {validarSafra.dtInicioSangriaEstaNull && isDtInicioSangriaEstaNull &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' align={'start'}>{'A data de início da sangria não foi informada'}</Text>
                        </Flex>
                    }
                    {validarSafra.programacaoEmAndamento && isProgramacaoEmAndamento &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' ml={'10px'} align={'start'}>{'Existe uma programação em andamento para essa safra. é preciso encerrar a programação para gerar uma nova.'}</Text>
                        </Flex>
                    }
                    {validarSafra.qtdPessoaCadastrada == 0 &&
                        <Flex px={'10px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='13px' height='13px' color={'black'} />
                            <Text color={textColor} fontSize={fontSizeP} fontWeight='bold' lineHeight='100%' ml={'10px'} align={'start'}>{'Não existe seringueiros cadastrados para essa safra.'}</Text>
                        </Flex>
                    }
                </Box>
            }
        </Box>
    );
};

export default CardValidacaoSafra;
