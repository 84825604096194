import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    TableContainer,
    Box,
    Center,
    Image,
    Button,
    Wrap,
    SimpleGrid,
    Stat,
    Icon,
    Checkbox,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { useHistory } from "react-router-dom";
import { IoEllipse } from "react-icons/io5";
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import LoaderBlack from 'components/loading/LoaderBlack';
import { SALVAR_RELATORIO_PDF_SERVIDOR } from '../../../../util/SalvarImagem';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import * as ActionTypes from '../../../../constants/ActionTypes';

const jsonPers = {
    opcaoEsporadico1: true,
    opcaoEsporadico2: true,
    opcaoEsporadico3: true,
}

export default function GerarRelatorioEsporadico() {

    const pdfRef1 = useRef();
    const pdfRef2 = useRef();
    const pdfRef3 = useRef();

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [arrayAssunto, setarrayAssunto] = useState([]);
    const [arrayImagensIndicadoresMensais, setArrayImagensIndicadoresMensais] = useState([]);
    const [arrayIndicadoresMensais, setArrayIndicadoresMensais] = useState([]);
    const [marginText, setMarginText] = useState('0px');

    const [idUf, setIdUf] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [comentarioObservacao, setComentarioObservacao] = useState('');
    const [objOpcoesPersonalizado, setObjOpcoesPersonalizado] = useState(jsonPers);
    const [gerarCopiaPdfServidor, setGerarCopiaPdfServidor] = useState(false);
    const [nomeRelatorioSeringual, setNomeRelatorioSeringual] = useState('Relatorio-do-Seringal-Esporadico');
    const [imgLogoRelatorio, setImgLogoRelatorio] = useState(null);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorTituloModulo = "orange.700";
    const titleSize = '30px';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const authBg = useColorModeValue("whitesmoke", "navy.900");
    const borWidthItem = '0px';
    const borRadiostem = '15px';
    const borColortem = 'blackAlpha.300';

    useEffect(() => {
        async function loadScreen() {
            try {
                window.scrollTo(0, 0);

                const imgStr = localStorage.getItem('@HE-imagemDaLogoDoUsuarioTecnico');
                const imgFile = imgStr != null ? JSON.parse(imgStr) : [];
                const xxh1 = imgFile.length > 0 ? imgFile[0].fileBase64 : LogoHevea;
                setImgLogoRelatorio(xxh1);

                const objPer = localStorage.getItem('@HE-opcoes-relatorio-esporadico');
                const objOpcoesX = JSON.parse(objPer);
                setObjOpcoesPersonalizado(objOpcoesX);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const descData = `${nmMes}/${ano}`;
                setNomeMesCorrente(descData);

                const nmRelatorio = `Relatorio-do-Seringal-Esporadico-${dia}-${nmMes}-${ano}`;
                setNomeRelatorioSeringual(nmRelatorio);

                // DADOS FAZENDA
                const ddFaz = localStorage.getItem('@HE-esporadico-dados-fazenda');
                if (ddFaz != null) {
                    const ddd = JSON.parse(ddFaz);
                    setNomeFazenda(ddd.nomeFazenda);
                    setIdUf(ddd.idUf);
                    setNomeMunicipio(ddd.nomeMunicipio);
                }

                // ASSUNTO
                let arrayAssuntoP = [];
                const arr = localStorage.getItem('@HE-array-assunto-esporadico');
                if (arr != null) {
                    arrayAssuntoP = JSON.parse(arr);
                    arrayAssuntoP.sort(function (a, b) { return a.idAssunto - b.idAssunto });
                    setarrayAssunto(arrayAssuntoP);
                }

                // COMENTARIOS
                const com = localStorage.getItem('@HE-comentario-tela-observacoes-esporadico');
                const obsComentarios = com != null && com != '' ? com : '';
                setComentarioObservacao(obsComentarios);

                // INDICADORES MENSAIS
                const arrInd = localStorage.getItem('@HE-array_IndicadoresMensais');
                const arrInd2 = arrInd != null ? JSON.parse(arrInd) : [];
                setArrayIndicadoresMensais(arrInd2);

                const arrImg = localStorage.getItem('@HE-array-imagens-indicadores-mensais');
                const zzQ = arrImg != null ? JSON.parse(arrImg) : [];
                setArrayImagensIndicadoresMensais(zzQ);

                setTelaCarregada(true);
            } catch (error) {
                console.log('Erro na classe metodo useEffect')
            }
        }
        loadScreen();
    }, []);

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1000);
    }
    /*
    ╔═══════════════════════════════════════════════════════════════════════════╗
        
    ╚═══════════════════════════════════════════════════════════════════════════╝*/
    const gerarPDF = async () => {

        const input1 = pdfRef1.current;
        let imgData1 = null;
        let imgHeigh1 = 0;
        let imgWWIDTH1 = 0;

        let imgData2 = null;
        let imgHeigh2 = 0;

        let imgData3 = null;
        let imgHeigh3 = 0;

        await html2canvas(input1).then((canvas) => {
            imgData1 = canvas.toDataURL('image/png');
            imgHeigh1 = canvas.height;
            imgWWIDTH1 = canvas.width;          // ESSE CARA E O MESMO VALOR PRA TODOS
        });
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeigh = pdf.internal.pageSize.getHeight();
        const ratio1 = Math.min(pdfWidth / imgWWIDTH1, pdfHeigh / imgHeigh1);

        pdf.addImage(imgData1, 'PNG', 5, 10, pdfWidth - 10, imgHeigh1 * ratio1);

        if (objOpcoesPersonalizado.opcaoEsporadico1 || objOpcoesPersonalizado.opcaoEsporadico2) {
            const input2 = pdfRef2.current;
            await html2canvas(input2).then((canvas) => {
                imgData2 = canvas.toDataURL('image/png');
                imgHeigh2 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData2, 'PNG', 5, 10, pdfWidth - 10, imgHeigh2 * ratio1);
            });
        }

        if (objOpcoesPersonalizado.opcaoEsporadico3) {
            const input3 = pdfRef3.current;
            await html2canvas(input3).then((canvas) => {
                imgData3 = canvas.toDataURL('image/png');
                imgHeigh3 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData3, 'PNG', 5, 10, pdfWidth - 10, imgHeigh3 * ratio1);
            });
        }

        pdf.save(`${nomeRelatorioSeringual}.pdf`);

        if (gerarCopiaPdfServidor) {
            const blob = pdf.output('blob');
            SALVAR_RELATORIO_PDF_SERVIDOR(blob, ActionTypes.ID_TIPO_ANEXO_ESPORADICO_PDF);
        }
    }

    function renderAssunto() {
        const mbP = '2';
        const sizeF = '13px';
        return (
            <TableContainer>
                <Table size='sm' variant='striped' colorScheme='blackAlpha'>
                    <Thead px={'0px'}>
                        <Tr>
                            <Th w={'60px'}>
                                <Box textTransform={'none'} >
                                    <Text color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} mb={mbP}>Assunto</Text>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'} mb={mbP}>{'Sugestões Técnicas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'} mb={mbP}>{'Local'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'} mb={mbP}>{'Prazo'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'} mb={mbP}>{'Responsável'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {arrayAssunto.map((item, key) => {
                            return (
                                <Tr key={key + 1}>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} mb={mbP}>{item.nomeAssunto}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'} mb={mbP}>{item.sugestoesTecnicas}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'} mb={mbP}>{item.local}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'} mb={mbP}>{item.prazo}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'} mb={mbP}>{item.responsavel}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderIndicadoresMensais() {
        let corLinha = false;
        return (
            <Box mt={'20px'}>
                {arrayIndicadoresMensais.length > 0 &&
                    arrayIndicadoresMensais.map((item, index) => {
                        corLinha = !corLinha;
                        return (
                            <Box key={index} px={'5'}>
                                <Flex minH={'10'} align={'center'} justify={'start'} border={'1px'} borderRadius={'1px'} borderColor={'blackAlpha.300'} bg={corLinha ? 'blackAlpha.100' : 'white'}>
                                    <Box px={'10px'}>
                                        <Center>
                                            <Icon as={IoEllipse} width={'25px'} height={'25px'} color={'blue.600'} />
                                        </Center>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} w={'100%'} minH={'10'} px={'10px'} py={'2'}>
                                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} mb={'2'}>{item}</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' py={'1'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' overflowX={{ sm: "scroll", lg: "hidden" }} w='100%' pt='0px' px='0px' borderRadius={'15px'} bg={authBg}>
                <Box ref={pdfRef1} w='100%' bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center' mb={'20px'}>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>Relatório do Seringal</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesCorrente}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeFazenda}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            <Image h='60px' w='180px' objectFit={'contain'} src={imgLogoRelatorio} alt='logo' />
                        </Box>
                    </Box>
                    <Box h={'500px'} w='100%' px={'20px'}>
                        <Flex h={'100%'} flexDirection={'column'} justify={'center'}>
                            <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Sumário</Text>
                            {objOpcoesPersonalizado.opcaoEsporadico1 && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'}>1 - Pendências e Ações de Campo</Text>}
                            {objOpcoesPersonalizado.opcaoEsporadico2 && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'}>2 - Observações diversas e Comentários</Text>}
                            {objOpcoesPersonalizado.opcaoEsporadico3 && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'}>3 - Indicadores Mensais</Text>}
                        </Flex>
                    </Box>
                </Box>
                <Box ref={pdfRef2}>
                    <Box w='100%' mt={'30px'} bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                        {objOpcoesPersonalizado.opcaoEsporadico1 &&
                            <Box w='100%'>
                                <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Pendências e Ações de Campo</Text>
                                <Box w='100%' px={'2'} mt={'20px'}>
                                    <Box mt='10px' bg={'white'} mb={'30px'}>
                                        {renderAssunto()}
                                    </Box>
                                </Box>
                            </Box>
                        }
                        {objOpcoesPersonalizado.opcaoEsporadico2 &&
                            <Box w='100%' mt={'80px'} px={'20px'}>
                                <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Observações diversas e Comentários</Text>
                                <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} mt={'20px'}>{comentarioObservacao}</Text>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box ref={pdfRef3}>
                    {objOpcoesPersonalizado.opcaoEsporadico3 &&
                        <Box w='100%' mt={'30px'} bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                            <Box w='100%'>
                                <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Indicadores Mensais</Text>
                            </Box>
                            {renderIndicadoresMensais()}
                            <Box mt={'5'} px={'5'}>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                                    {arrayImagensIndicadoresMensais.length > 0 &&
                                        arrayImagensIndicadoresMensais.map((item, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Card py='10px' align='center' justify={'start'}>
                                                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                                                <Flex align='center' justify={'center'}>
                                                                    <Image boxSize='200px' objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                                </Flex>
                                                            </Stat>
                                                        </Flex>
                                                    </Card>
                                                </Box>
                                            )
                                        })
                                    }
                                </SimpleGrid>
                            </Box>
                        </Box>
                    }
                </Box>
                <Box>
                    <Center mt={'20px'} px={'5'} alignItems={'center'} justifyContent={'end'}>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} px={'3'}>Salvar uma cópia no servidor</Text>
                        <Checkbox
                            size="lg"
                            colorScheme='blue'
                            isChecked={gerarCopiaPdfServidor}
                            onChange={() => setGerarCopiaPdfServidor(!gerarCopiaPdfServidor)}
                            bg={'white'}>
                        </Checkbox>
                    </Center>
                </Box>
                <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button variant="brand" onClick={downloadPdf}>Gerar PDF</Button>
                </Wrap>
            </Card>
            <LoaderBlack isOpen={loading} />
        </Box>
    );
}