import { Box, SimpleGrid } from "@chakra-ui/react";
import HistoricoSeringueiro from "views/admin/historicoSeringueiro/form/HistoricoSeringueiro";
import iconPdf from "assets/img/layout/pdf_icon_download.png";

import React from "react";

export default function Settings() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}>
                <HistoricoSeringueiro iconPdf={iconPdf} />
            </SimpleGrid>
        </Box>
    );
}
