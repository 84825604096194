import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    useDisclosure,
    SimpleGrid,
    Box,
    Wrap,
    CircularProgress,
} from "@chakra-ui/react";
import { LIST_UFS } from 'model/MockData'
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { mascaraTelefone, retiraMascaraMaterNumeros } from '../../../../util/Masck';
import { useHistory } from "react-router-dom";

export function FormProdutor() {

    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [nomeTela, setNomeTela] = useState('');

    const [isValidNome, setIsValidNome] = useState(false);
    const [inputNome, setInputNome] = useState('');

    const [inputEndereco, setInputEndereco] = useState('');
    const [idEnderecoP, setIdEnderecoP] = useState(null);

    const [isValidEmail, setIsValidEmail] = useState(false);
    const [inputEmail, setInputEmail] = useState('');

    const [isValidCelular, setIsValidCelular] = useState(false);
    const [inputCelular, setInputCelular] = useState('');

    const [isValidUf, setIsValidUf] = useState(false);
    const [ufSelecionado, setUfSelecionado] = useState('');

    const [isValidMunicipio, setIsValidMunicipio] = useState(false);
    const [inputTelefoneFixo, setInputTelefoneFixo] = useState('');

    const [listMunicipio, setListMunicipio] = useState([]);
    const [municipioSelecionado, setMunicipioSelecionado] = useState('');
    const [isDisabledMunicipio, setIsDisabledMunicipio] = useState(true);

    const [idUsuarioP, setIdUsuarioP] = useState(null);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [operacao, setOperacao] = useState(null);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [isProcessComplete, setIsProcessComplete] = useState(false);
    const [mensageAlert, setMensageAlert] = useState('');

    useEffect(() => {
        async function loaderScreen() {
            const dadosProdutor = localStorage.getItem('@HE-dadosProdutorParaEdicao');
            if (dadosProdutor !== null) {

                setNomeTela('Editar Produtor');
                const param = JSON.parse(dadosProdutor);

                setOperacao('update');
                localStorage.removeItem('@HE-dadosProdutorParaEdicao');

                const idUser = param.idUsuario;
                setIdUsuarioP(idUser);

                setInputNome(param.nome !== null && param.nome !== '' ? param.nome : '');
                setInputTelefoneFixo(param.telefoneFixo !== null && param.telefoneFixo !== '' ? mascaraTelefone(param.telefoneFixo) : '');
                setInputCelular(param.numeroCelular !== null && param.numeroCelular !== '' ? mascaraTelefone(param.numeroCelular) : '');
                setInputEmail(param.emailPessoal !== null && param.emailPessoal !== '' ? param.emailPessoal : '');

                const url = `api/usuario/retornaUsuarioProdutorPorId?idTipoUsuario=1&idUsuario=${idUser}`;
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    if (res.length > 0) {

                        const obj = res[0].objEndereco;
                        console.log('');
                        const idMu = obj.idMunicipio !== null && obj.idMunicipio !== '' ? `${obj.idMunicipio}` : null;
                        setMunicipioSelecionado(idMu);
                        setIdEnderecoP(obj.idEndereco);
                        setInputEndereco(obj.endereco !== null && obj.endereco !== '' ? obj.endereco : '');
                        carregarUf(obj.idUf);
                        setTelaCarregada(true);
                    }
                }
            } else {
                setNomeTela('Cadastrar Produtor');
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const changeUf = async (e) => {
        const uf = e.target.value;
        carregarUf(uf);
    }

    const carregarUf = async (e) => {
        const uf = e;
        setUfSelecionado(`${uf}`)
        const response = await Api.getRequest(`api/ua/listaMunicipios?idUf=${uf}`);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;

            if (res.length > 0) {
                let arr = res.map((item, index) => {
                    return {
                        value: `${item.idMunicipio}`,
                        label: item.nomeMunicipio,
                    }
                })
                setListMunicipio(arr);
                setIsDisabledMunicipio(false);
            }
        }
    }

    const changeMunicipio = async (e) => {
        const muni = e.target.value
        setMunicipioSelecionado(muni);
    }

    const salvarProdutor = async () => {
        try {
            let valid = false;

            if (inputNome.length < 3) {
                setIsValidNome(true);
                valid = true;
            } else {
                setIsValidNome(false);
            }

            if (inputEmail.length < 3) {
                setIsValidEmail(true);
                valid = true;
            } else {
                setIsValidEmail(false);
            }

            if (inputCelular.length < 15) {
                setIsValidCelular(true);
                valid = true;
            } else {
                setIsValidCelular(false);
            }

            if (ufSelecionado === null || ufSelecionado === '') {
                setIsValidUf(true);
                valid = true;
            } else {
                setIsValidUf(false);
            }
            if (municipioSelecionado === null || municipioSelecionado === '') {
                setIsValidMunicipio(true);
                valid = true;
            } else {
                setIsValidMunicipio(false);
            }

            if (valid) return;

            const permissoesP = [
                { idPermissao: 1 },
                { idPermissao: 2 },
                { idPermissao: 3 },
                { idPermissao: 4 }
            ]

            const objEnderecoP = {
                idEndereco: idEnderecoP,
                idUf: ufSelecionado,
                idMunicipio: municipioSelecionado,
                endereco: inputEndereco !== '' ? inputEndereco : null,
            }

            const fixoSemMascara = retiraMascaraMaterNumeros(inputTelefoneFixo);
            const celularSemMascara = retiraMascaraMaterNumeros(inputCelular);
            const emailP = `sangrador${celularSemMascara}@hevea.com`;

            const jsonUsuario = {
                idUsuario: idUsuarioP,
                nome: inputNome,
                email: emailP,
                numeroCelular: celularSemMascara,
                senha: '123456',
                emailPessoal: inputEmail,
                telefoneFixo: fixoSemMascara,
                idTipoUsuario: ActionTypes.TIPO_USUARIO_PRODUTOR,
                permissoes: permissoesP,
                redefinirSenha: true,
                objEndereco: objEnderecoP,
            }

            onOpen();
            if (operacao === 'update') {
                const response = await Api.putRequestSemAccessToken(`api/usuario/alterarUsuario`, jsonUsuario);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    if (res.numeroCelularJaExiste == true) {
                        setMensageAlert(`Ops! Número de celular já existe, cadastrado por: ${res.nome}.`);
                        setIsProcessComplete(true);
                        setIsValidCelular(true);
                    } else {
                        setMensageAlert('Processo Realizado com Sucesso!');
                        setIsProcessComplete(true);
                        setIsValidCelular(false);
                    }
                } else {
                    setMensageAlert('Erro no Processamento da Operação!');
                    setIsProcessComplete(true)
                }

            } else {
                const response = await Api.postRequestSemAccessToken(`api/usuario/cadastrarUsuario`, jsonUsuario);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    if (res.numeroCelularJaExiste == true) {
                        setMensageAlert(`Ops! Número de celular já existe, cadastrado por: ${res.nome}.`);
                        setIsProcessComplete(true);
                        setIsValidCelular(true);
                    } else {
                        setMensageAlert('Processo Realizado com Sucesso!');
                        setIsProcessComplete(true);
                        setIsValidCelular(false);
                    }
                } else {
                    setMensageAlert('Erro no Processamento da Operação!');
                    setIsProcessComplete(true)
                }
            }
            setIsProcessComplete(true);
        } catch (error) {
            setIsProcessComplete(true);
            console.log('Erro na classe FormProdutores metodo salvarProdutor', error);
        }
    }

    const changeCelular = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputCelular(telComMascara);
    }

    const changeTelefonefixo = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputTelefoneFixo(telComMascara);
    }

    const goBackPage = () => {
        history.goBack();
    }

    function renderInputs() {
        return (
            <Card direction='column' w='99%' mx='5px' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }} borderWidth={3} >
                <Box w={'98%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Nome *</Text>
                            <Input
                                value={inputNome}
                                onChange={(e) => setInputNome(e.target.value)}
                                isInvalid={isValidNome}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Endereço</Text>
                            <Input
                                value={inputEndereco}
                                onChange={(e) => setInputEndereco(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box w={'98%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>E-mail</Text>
                            <Input
                                value={inputEmail}
                                onChange={(e) => setInputEmail(e.target.value)}
                                isInvalid={isValidEmail}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="email"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Celular *</Text>
                                <Input
                                    value={inputCelular}
                                    onChange={changeCelular}
                                    isInvalid={isValidCelular}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Telefone Fixo</Text>
                                <Input
                                    value={inputTelefoneFixo}
                                    onChange={changeTelefonefixo}
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </Box>
                <Box w={'98%'}>
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>UF</Text>
                            <Select
                                value={ufSelecionado}
                                isInvalid={isValidUf}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={changeUf}
                            >
                                {LIST_UFS.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Municipio</Text>
                            <Select
                                value={municipioSelecionado}
                                isInvalid={isValidMunicipio}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={changeMunicipio}
                                disabled={isDisabledMunicipio}
                            >
                                {listMunicipio.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                </Box>
            </Card>
        )
    }

    function renderModal() {
        return (
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cadastro</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex align='center' justifyContent={'center'} mb='1px' mt={'1px'} >
                            {isProcessComplete ?
                                <Text color={textColor} fontSize='16px' lineHeight='100%'>{mensageAlert}</Text>
                                :
                                <CircularProgress isIndeterminate color='orange.400' thickness={'12px'} size='50px' />
                            }
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Ok</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>{nomeTela}</Text>
            </Flex>
            {renderInputs()}
            <Wrap spacing='30px' mx={'10px'} mt={'20px'} justify='space-between'>
                <Button variant="brand" onClick={goBackPage}>Voltar</Button>
                <Button variant="brand" onClick={salvarProdutor}>Salvar</Button>
            </Wrap>
            {renderModal()}
        </Card>
    );
}

export default FormProdutor;
