import { Box, Flex, Stack } from "@chakra-ui/react";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
//import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";

function SidebarContent(props) {

    const { routes } = props;

    // TODO ESSE E O CARDE QUE FICA NO MENU PARA COMPRA DO TEMPLATE PRO
    return (
        <Flex direction='column' height='100%' pt='2px' borderRadius='30px'>
            <Brand />
            <Stack direction='column' mb='auto' mt='0px'>
                <Box ps='5px' pe={{ md: "16px", "2xl": "1px" }} mb={'50px'}>
                    <Links routes={routes} />
                </Box>
            </Stack>
            <Box
                ps='20px'
                pe={{ md: "16px", "2xl": "0px" }}
                mt='60px'
                mb='40px'
                borderRadius='30px'>
                {/* <SidebarCard /> */}
            </Box>
        </Flex>
    );
}

export default SidebarContent;
