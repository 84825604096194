import React, { useState, useEffect } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Icon,
    SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';
import { MdBrightness1, MdCheckCircle, MdCircle } from "react-icons/md";
import { ImArrowRight } from "react-icons/im";
import CardValidacaoSafra from 'components/card/CardValidacaoSafra';
import MessageSreen from 'components/message/MessageSreen';
import LoaderBlack from 'components/loading/LoaderBlack';
import ModalDialog from 'components/modal/ModalDialog';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function GerarProgramacao() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idUa, setIdUa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [idUsuario, setIdUsuario] = useState(null);
    const [idUf, setIdUf] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');

    const [dtInicioFormatado, setDtInicioFormatado] = useState('');
    const [dtFimFormatado, setDtFimFormatado] = useState('');
    const [dtInicioSangriaFormatado, setDtInicioSangriaFormatado] = useState('');
    const [dtInicioSangria, setDtInicioSangria] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');
    const [qtdPessoaCadastrada, setQtdPessoaCadastrada] = useState('');

    const [listaPessoa, setListaPessoa] = useState([]);
    const [validarSafraDto, setValidarSafraDto] = useState({});
    const [isAbilitarGeracao, setIsAbilitarGeracao] = useState(true);

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const cor2 = 'rgba(0, 0, 0, 0.7)';
    const cor4 = 'rgba(0, 0, 0, 0.01)';
    const LINEAR_GRADIENT_GREEN = [`linear-gradient(to top, ${cor2} 1%, ${cor4}, ${cor4}, ${cor4}, ${cor2})`];

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dados = localStorage.getItem('@HE-datosParaProgramacaoIndividualAndReprogracao');
            const param = JSON.parse(dados);

            const idUsuarioP = param.idUsuario;
            const idSafraP = param.idSafra;
            const idUaP = param.idUa;
            const nomeFazendaP = param.nomeFazenda;
            const dtInicioP = param.dtInicio;
            const dtFimP = param.dtFim;
            const dtInicioSangriaP = param.dtInicioSangria;
            const nomeSafraP = param.nomeSafra;
            const qtdPessoaCadastradaP = param.qtdPessoaCadastrada;

            setIdUsuario(idUsuarioP);            
            setQtdPessoaCadastrada(qtdPessoaCadastradaP);
            setNomeFazenda(nomeFazendaP);
            setNomeSafra(nomeSafraP);
            setIdUa(idUaP);
            setIdSafra(idSafraP);
            setDtInicioSangria(dtInicioSangriaP);

            const dtInitSafra = moment(dtInicioP).format('DD/MM/YYYY');
            const dtIFimtSafra = moment(dtFimP).format('DD/MM/YYYY');
            const dtInitSangria = moment(dtInicioSangriaP).format('DD/MM/YYYY');

            setDtInicioFormatado(dtInitSafra);
            setDtFimFormatado(dtIFimtSafra);
            setDtInicioSangriaFormatado(dtInitSangria);

            const url = `api/pessoa/listaSeringueirosParaProgramacao?idUa=${idUaP}&idSafra=${idSafraP}&dtInicio=${dtInicioP}&dtFim=${dtFimP}&deletado=false`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res1 = await response.respostaRequisicao.data;
                const validacao = res1.validarSafraDto;

                setValidarSafraDto(validacao);
                setNomeMunicipio(validacao.nomeMunicipio);
                setIdUf(validacao.idUf);

                if (!validacao.sangriaAtiva
                    || !validacao.vigente
                    || validacao.dtInicioSafraMaiorQueDataAtual
                    || validacao.dtFimSafraMenorQueDataAtual
                    || validacao.dtInicioSangriaMaiorQueDataAtual
                    || validacao.dtFimSangriaMenorQueDataAtual
                    || validacao.dtInicioSangriaEstaNull
                    || validacao.programacaoEmAndamento
                    || validacao.qtdPessoaCadastrada == 0
                ) {
                    setIsAbilitarGeracao(false);
                }
                const arr = res1.listPessoaAlternanciaAndDiasUteisDto;
                setListaPessoa(arr);
                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        }
        loaderScreen();
    }, []);

    const validarAndGerarProgramacao = async () => {
        try {
            setOpenModalDialog(false);

            const dataAtual = moment();
            const dataAtualFormatada = moment(dataAtual).format('YYYY-MM-DD');
            const dtAtualMais06Dias = moment(dataAtual).add(6, 'days').format('YYYY-MM-DD');     // YYYY-MM-DD HH:mm:ss

            let dtIniSangria = `${dataAtualFormatada} 00:00:00`;
            if (dtInicioSangria != '') {
                dtIniSangria = `${dtInicioSangria} 00:00:00`
            }

            const jsonPessoa = {
                idUsuario: idUsuario,
                //idPessoa: idPessoaP,      // PARA GERACAO DE TODOS OS SERINGUEIROS NAO PRECISA DESSE CARA
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                idUa: idUa,
                idSafra: idSafra,
                dtInicio: `${dataAtualFormatada} 00:00:00`,
                dtInicioSangria: dtIniSangria,
                dtFim: `${dtAtualMais06Dias} 00:00:00`,
                gerarComDadosIndividualDoSeringueiro: true,
                sangriaAtiva: true,
                programacaoEmAndamento: true,
            }

            setLoading(true);
            const response = await Api.postRequest(`api/programacao/gerarProgramacaoParaUmaUaAndSafra`, jsonPessoa);
            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setOpenModalConfimacao(true);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Error na classe validarAndGerarProgramacao metodo changePessoa', error);
        }
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    function renderTitulo() {
        return (
            <Flex flexDirection={'column'} w={'100%'} justify={'start'} align="start">
                <Flex justify={'start'} align="center">
                    <Text color={'gray.700'} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Propriedade:'}</Text>
                    <Text color={'gray.700'} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' ml={'10px'}>{nomeFazenda}</Text>
                </Flex>
                <Flex justify={'start'} align="center">
                    <Text color={'gray.700'} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Safra:'}</Text>
                    <Text color={'gray.700'} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' ml={'10px'}>{nomeSafra}</Text>
                </Flex>
            </Flex>
        )
    }

    function renderMensagemDaSafra() {
        return (
            <Flex w={'100%'} justify={'center'} align="center" mt={'20px'} mb={'20px'}>
                <CardValidacaoSafra validarSafra={validarSafraDto} isAbilitarGeracao={isAbilitarGeracao} />
            </Flex>
        )
    }

    function renderInfo() {
        const color1 = 'gray.900';
        const color2 = 'gray.700';
        return (
            <Box mt={'10px'}>
                <Box mx={'2px'} borderRadius={'20px'} mb={'0px'} bgGradient={LINEAR_GRADIENT_GREEN}>
                    <SimpleGrid columns={{ base: 3, md: 6, lg: 6, "2xl": 6 }} gap='5px' py={'10px'} mb='0px' mt={'0px'}>
                        <Flex flexDirection={'column'} justify={'center'} align="center" borderRightWidth={'2px'} borderColor={'blackAlpha.400'}>
                            <Text color={color1} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Início Safra'}</Text>
                            <Text color={color2} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{dtInicioFormatado}</Text>
                        </Flex>
                        <Flex flexDirection={'column'} justify={'center'} align="center" borderRightWidth={'2px'} borderColor={'blackAlpha.500'}>
                            <Text color={color1} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Fim Safra'}</Text>
                            <Text color={color2} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{dtFimFormatado}</Text>
                        </Flex>
                        <Flex flexDirection={'column'} justify={'center'} align="center" borderRightWidth={'2px'} borderColor={'blackAlpha.500'}>
                            <Text color={color1} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Início Sangria'}</Text>
                            <Text color={color2} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{dtInicioSangriaFormatado}</Text>
                        </Flex>
                        <Flex flexDirection={'column'} justify={'center'} align="center" borderRightWidth={'2px'} borderColor={'blackAlpha.500'}>
                            <Text color={color1} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'UF'}</Text>
                            <Text color={color2} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{idUf}</Text>
                        </Flex>
                        <Flex flexDirection={'column'} justify={'center'} align="center" borderRightWidth={'2px'} borderColor={'blackAlpha.500'}>
                            <Text color={color1} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Municipio'}</Text>
                            <Text color={color2} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{nomeMunicipio}</Text>
                        </Flex>
                        <Flex flexDirection={'column'} justify={'center'} align="center">
                            <Text color={color1} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Seringueiros'}</Text>
                            <Text color={color2} fontSize='15px' fontWeight={'light'} align={'center'} lineHeight='100%' mt={'5px'}>{qtdPessoaCadastrada}</Text>
                        </Flex>
                    </SimpleGrid>
                </Box>
            </Box>
        )
    }

    function renderLegendas() {
        return (
            <Box mt={'10px'}>
                <Flex justify='space-between' align='center'>
                    <Box>
                        <Flex justify='start' align='center'>
                            <Icon as={MdBrightness1} width='18px' height='18px' color='orange.600' />
                            <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Frequência'}</Text>
                        </Flex>
                        <Flex justify='start' align='center'>
                            <Icon as={MdBrightness1} width='18px' height='18px' color='green.500' />
                            <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Dias úteis'}</Text>
                        </Flex>
                        <Flex justify='start' align='center'>
                            <Icon as={MdBrightness1} width='18px' height='18px' color='yellow' />
                            <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Sem programação'}</Text>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='end' align='center'>
                            <Button
                                rightIcon={<ImArrowRight size={'20px'} />}
                                variant="solid"
                                _hover={{ bg: "#e67300" }}
                                bg={'orange.600'}
                                color={'white'}
                                fontWeight={'bold'}
                                size='sm'
                                h={'50px'}
                                borderWidth={'2px'}
                                borderColor={'white'}
                                onClick={() => { history.push("/admin/gerar-programacao-individual") }}>
                                <Box>
                                    <Text color={'white'} fontSize='14px' fontWeight='bold' >{'Programação'}</Text>
                                    <Text color={'white'} fontSize='14px' fontWeight='bold' >{'individual'}</Text>
                                </Box>
                            </Button>
                            <Button
                                rightIcon={<ImArrowRight size={'20px'} />}
                                variant="solid"
                                _hover={{ bg: "#e67300" }}
                                bg={'orange.600'}
                                color={'white'}
                                fontWeight={'bold'}
                                size='sm'
                                h={'50px'}
                                borderWidth={'2px'}
                                borderColor={'white'}
                                onClick={() => { history.push("/admin/reprogramacao") }}>
                                <Box>
                                    <Text color={'white'} fontSize='14px' fontWeight='bold' >{'Re-Programação'}</Text>
                                    <Text color={'white'} fontSize='14px' fontWeight='bold' >{'individual'}</Text>
                                </Box>
                            </Button>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        )
    }

    function renderListaSeringueiros() {
        let corLinha = false;
        return (
            <Box mt={'20px'} px={'10px'} borderWidth={'2PX'} borderRadius={'10px'} borderColor={'blackAlpha.700'}>
                <Flex w={'100%'} justify='space-between' align='center'>
                    <Box w={'42%'}>
                        <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{''}</Text>
                    </Box>
                    <Box w={'50px'}>
                        <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{''}</Text>
                    </Box>
                    <Box w={'52%'}>
                        <Flex justify='space-between' align='center'>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'T'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'Q'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'Q'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'D'}</Text>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
                {listaPessoa.map((item, index) => {
                    const bgName = item.isExisteProgramacao != true ? '#ffff4d' : 'transparent';
                    corLinha = !corLinha;
                    return (
                        <Flex key={index} w={'100%'} justify='space-between' align='center' borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} bg={'blackAlpha.50'}>
                            <Box py={'5px'} w={'42%'} bg={bgName} borderRadius={'5px'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{item.nome}</Text>
                            </Box>
                            <Box my={'3px'} w={'50px'}>
                                <Box py={'5px'} w={'35px'} bg={'orange.600'} borderRadius={'5px'}>
                                    <Text color={'white'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{`D${item.idQuantidadeTarefas}`}</Text>
                                </Box>
                            </Box>
                            <Box w={'52%'}>
                                <Flex justify='space-between' align='center'>
                                    {item.listaDiasUteis != null && item.listaDiasUteis.length > 0 ?
                                        item.listaDiasUteis.map((x, y) => {
                                            let corIcon = 'green';
                                            let isIcone = false;

                                            if (x == 'MONDAY') {
                                                isIcone = true;
                                            } else if (x == 'TUESDAY') {
                                                isIcone = true;
                                            } else if (x == 'WEDNESDAY') {
                                                isIcone = true;
                                            } else if (x == 'THURSDAY') {
                                                isIcone = true;
                                            } else if (x == 'FRIDAY') {
                                                isIcone = true;
                                            } else if (x == 'SATURDAY') {
                                                isIcone = true;
                                            } else if (x == 'SUNDAY') {
                                                isIcone = true;
                                            } else {
                                                isIcone = false;
                                                corIcon = 'gray.300';
                                            }
                                            return (
                                                <Flex key={y} w={'100%'} justify='center' align='center'>
                                                    <Icon as={isIcone ? MdCheckCircle : MdCircle} width='20px' height='20px' color={corIcon} />
                                                </Flex>
                                            )
                                        })
                                        :
                                        <Text color={textColor} fontSize='14px' fontWeight='bold' >{'S'}</Text>
                                    }
                                </Flex>
                            </Box>
                        </Flex>
                    )
                })}
            </Box>
        )
    }

    function renderButton() {
        return (
            <Box mt={'20px'}>
                <Flex px='10px' justify='space-between' align='center'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button isDisabled={!isAbilitarGeracao} variant="brand" onClick={() => { setOpenModalDialog(true) }}>Gerar Programação</Button>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                {renderAlert()}
                {renderTitulo()}
                {renderMensagemDaSafra()}
                {renderInfo()}
                {renderLegendas()}
                {renderListaSeringueiros()}
                {renderButton()}
            </Card>
            {loading && <LoaderBlack isOpen={loading} subTitulo={'Dependendo da quantidade de registros, esse processo pode levar mais de um minuto'} />}
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { validarAndGerarProgramacao() }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={'Iniciar geração da programação?'} />}
            {openModalConfimacao && <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />}
        </Box>
    );
}
