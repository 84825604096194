import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Text,
    Box,
    Button,
    Wrap,
    Input,
    RadioGroup,
    Radio,
    Stack,
    WrapItem,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import { ARRAY_TAREFA } from '../../../../model/MockData';
import { retiraMascaraMaterNumeros } from '../../../../util/Masck';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function LancarProducao() {

    const history = useHistory();

    const [arrayAlternacia, setArrayAlternacia] = useState([]);
    const [alternanciaSelecionado, setAlternanciaSelecionado] = useState('3');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [totalPrevMes, setTotalPrevMes] = useState('');
    const [totalValorMes, setTotalValorMes] = useState('');
    const [idSafraPessoa, setIdSafraPessoa] = useState('');
    const [idPessoa, setIdPessoa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [idUa, setIdUa] = useState('');
    const [descMesAno, setDescMesAno] = useState('');

    const [arrayDadosGeralCompleto, setArrayDadosGeralCompleto] = useState([]);
    const [idTipoLancamento, setIdTipoLancamento] = useState('2');

    const [isValidQtdArvore, setIsValidQtdArvore] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [arrayListaMeses, setArrayListaMeses] = useState([]);
    const [numMesSelecionado, setNumMesSelecionado] = useState('');
    const [numAnoSelecionado, setNumAnoSelecionado] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const GRADIENTE = 'linear-gradient(to bottom, #e6e6e6, #ffffff, #e6e6e6)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const tePr = localStorage.getItem('@HE-dadosTelaLancamentoProducao');
            const obj = JSON.parse(tePr);

            const dadosSeringueiro = obj.dadosSeringueiro;
            const mesSelecionado = obj.mesSelecionado;
            const idUaX = obj.idUa;
            const idSafraX = obj.idSafra;
            const idSafraPessoaX = dadosSeringueiro.idSafraPessoa;
            const idPessoaX = dadosSeringueiro.idPessoa;
            const nomeX = dadosSeringueiro.nome;
            const listM = dadosSeringueiro.listMeses;

            const listMeses = listM.filter(x=> x.valorParcial != true && x.valorAnual != true )

            setArrayListaMeses(listMeses);
            setIdUa(idUaX);
            setIdSafra(idSafraX);
            setIdSafraPessoa(idSafraPessoaX);
            setIdPessoa(idPessoaX);
            setNomeSeringueiro(nomeX);

            const descMesX = mesSelecionado.descMes;
            const numMesSelecionadoP = mesSelecionado.numMes;
            const numAnoSelecionadoP = mesSelecionado.numAno;
            const valorPrevistoMesSelecionadoP = mesSelecionado.valorPrevisto;
            const valorRealizadoMesSelecionadoP = mesSelecionado.valorRealizado;
            const descMesAnoX = `${descMesX}/${numAnoSelecionadoP}`;

            setNumMesSelecionado(numMesSelecionadoP);
            setNumAnoSelecionado(numAnoSelecionadoP);
            setDescMesAno(descMesAnoX);
            setTotalPrevMes(`${valorPrevistoMesSelecionadoP}`);
            setTotalValorMes(`${valorRealizadoMesSelecionadoP}`);

            const url3 = `producao/listaProducaoPessoaAlternancia?idUa=${idUaX}&idSafra=${idSafraX}&idSafraPessoa=${idSafraPessoaX}&idPessoa=${idPessoaX}&mes=${numMesSelecionadoP}&ano=${numAnoSelecionadoP}`;
            const response = await Api.getRequest(url3);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setArrayDadosGeralCompleto(res);

                const idTipoLancamentoP = res.idTipoLancamento;
                const totalValorMesP = res.totalValorMes;
                setIdTipoLancamento(`${idTipoLancamentoP}`);
                setTotalValorMes(`${totalValorMesP}`);

                const qtdTarefas2 = res.idQuantidadeTarefas != null && res.idQuantidadeTarefas != '' ? res.idQuantidadeTarefas : '3';
                setAlternanciaSelecionado(qtdTarefas2);
                atualizaDadosGeralNaTela(qtdTarefas2, res.listProducaoPessoaAlternanciaItem);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        }
        loaderScreen();
    }, []);

    const validarAndcadastrarValores = async () => {
        try {
            let valid = false;
            const arrayP = await montarArrayItensDinamicos();

            if (totalValorMes.trim().length < 1) {
                setIsValidQtdArvore(true);
                valid = true;
            } else {
                setIsValidQtdArvore(false);
            }

            if (valid) {
                setNumStatusResposta(ActionTypes.CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS);
                showAlert();
                window.scrollTo(0, 0);
                return;
            }
            let set = 0;
            let out = 0;
            let nov = 0;
            let dez = 0;
            let jan = 0;
            let fev = 0;
            let mar = 0;
            let abr = 0;
            let mai = 0;
            let jun = 0;
            let jul = 0;
            let ago = 0;
            let idSet = 1;
            let idOut = 1;
            let idNov = 1;
            let idDez = 1;
            let idJan = 1;
            let idFev = 1;
            let idMar = 1;
            let idAbr = 1;
            let idMai = 1;
            let idJun = 1;
            let idJul = 1;
            let idAgo = 1;
            const newArray = arrayListaMeses.map(x => x.numMes == numMesSelecionado ? { ...x, valorRealizado: totalValorMes, idTipoLancamento: idTipoLancamento } : x);  // ATUALIZO DOIS CAMPOS DO ARRAY DO MES SELECIONADO, O RESTANTE DOS CAMPOS MANTNEHO OS MESMOS VALORES
            await new Promise(resolve => {
                resolve(
                    newArray.map(x => {
                        if (x.numMes == 1) {
                            jan = x.valorRealizado;
                            idJan = x.idTipoLancamento;
                        } else if (x.numMes == 2) {
                            fev = x.valorRealizado;
                            idFev = x.idTipoLancamento;
                        } else if (x.numMes == 3) {
                            mar = x.valorRealizado;
                            idMar = x.idTipoLancamento;
                        } else if (x.numMes == 4) {
                            abr = x.valorRealizado;
                            idAbr = x.idTipoLancamento;
                        } else if (x.numMes == 5) {
                            mai = x.valorRealizado;
                            idMai = x.idTipoLancamento;
                        } else if (x.numMes == 6) {
                            jun = x.valorRealizado;
                            idJun = x.idTipoLancamento;
                        } else if (x.numMes == 7) {
                            jul = x.valorRealizado;
                            idJul = x.idTipoLancamento;
                        } else if (x.numMes == 8) {
                            ago = x.valorRealizado;
                            idAgo = x.idTipoLancamento;
                        } else if (x.numMes == 9) {
                            set = x.valorRealizado;
                            idSet = x.idTipoLancamento;
                        } else if (x.numMes == 10) {
                            out = x.valorRealizado;
                            idOut = x.idTipoLancamento;
                        } else if (x.numMes == 11) {
                            nov = x.valorRealizado;
                            idNov = x.idTipoLancamento;
                        } else if (x.numMes == 12) {
                            dez = x.valorRealizado;
                            idDez = x.idTipoLancamento;
                        } else {
                            console.log(' --- nao encontrou valor');
                        }
                    })
                )
            });
            const arrPro = [{
                setembro: set,
                outubro: out,
                novembro: nov,
                dezembro: dez,
                janeiro: jan,
                fevereiro: fev,
                marco: mar,
                abril: abr,
                maio: mai,
                junho: jun,
                julho: jul,
                agosto: ago,
                idPessoa: idPessoa,
                idSafraPessoa: idSafraPessoa,
                idTipoLancamentoSet: idSet,
                idTipoLancamentoOut: idOut,
                idTipoLancamentoNov: idNov,
                idTipoLancamentoDez: idDez,
                idTipoLancamentoJan: idJan,
                idTipoLancamentoFev: idFev,
                idTipoLancamentoMar: idMar,
                idTipoLancamentoAbr: idAbr,
                idTipoLancamentoMai: idMai,
                idTipoLancamentoJun: idJun,
                idTipoLancamentoJul: idJul,
                idTipoLancamentoAgo: idAgo,
            }]
            const jsonPessoa = {
                idPessoa: idPessoa,
                idUa: idUa,
                idSafra: idSafra,
                idSafraPessoa: idSafraPessoa,
                totalValorMes: totalValorMes,
                mes: numMesSelecionado,
                ano: numAnoSelecionado,
                listProducaoPessoaAlternanciaItem: arrayP,
                idTipoLancamento: idTipoLancamento,
                listArrayProducaoDto: arrPro,
            }      

            const response = await Api.postRequest(`producao/inserirLancamentoProducao`, jsonPessoa);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setOpenModalConfimacao(true);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            console.log('Erro na classe LancarProducao metodo validarAndcadastrarValores', error);
        }
    };

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const montarArrayItensDinamicos = async () => {
        const arrayCompleto = arrayDadosGeralCompleto.listProducaoPessoaAlternanciaItem;
        const num = Number(alternanciaSelecionado);
        const array = [];

        for (let i = 0; i < num; i++) {

            const idProducaoPessoaAlternancia = arrayCompleto[i] != undefined ? arrayCompleto[i].idProducaoPessoaAlternancia : null;
            const valorTarefa = arrayAlternacia[i] != undefined ? arrayAlternacia[i].valorTarefa : '0';     // ISSO MESMO SEM VALOR RETORNA ZERO POR QUE ESSE CAMPO E NOT NULL
            const idTarefa = ARRAY_TAREFA[i].value;

            const json = {
                idProducaoPessoaAlternancia: idProducaoPessoaAlternancia,
                idSafraPessoa: idSafraPessoa,
                idSafra: idSafra,
                idPessoa: idPessoa,
                idTarefa: idTarefa,
                letraTarefa: '',        // ESSE CARA EU PEGO NO BACK
                valorTarefa: valorTarefa,
                mes: numMesSelecionado,
                ano: numAnoSelecionado,
            }
            array.push(json);
        }
        return array;
    }

    const atualizaDadosGeralNaTela = async (val = '3', arr = []) => {
        try {
            const num = Number(val);
            const array = arr;
            let arrTarefa = [];
            let arrTamanhoLote = [];

            if (num > 0) {
                for (let i = 0; i < num; i++) {

                    if (array[i] != undefined) {
                        const item = array[i];

                        const valorTarefa = item.valorTarefa != null && item.valorTarefa != 0 ? `${item.valorTarefa}` : '0';
                        const valorPrevTarefa = item.valorPrevTarefa != null && item.valorPrevTarefa != 0 ? `${item.valorPrevTarefa}` : '0';
                        const letraTarefa = item.letraTarefa != null && item.letraTarefa != 0 ? `${item.letraTarefa}` : '';
                        const jsonTamanhoLote = { id: i, valorTarefa: valorTarefa, valorPrevTarefa: valorPrevTarefa, letraTarefa: letraTarefa };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const jsonTarefa = {
                            id: i,
                            value: ARRAY_TAREFA[i].value,
                            label: ARRAY_TAREFA[i].label,
                        }
                        arrTarefa.push(jsonTarefa);
                    } else {
                        const jsonTamanhoLote = { id: i, valorTarefa: '0', valorPrevTarefa: '0', letraTarefa: ARRAY_TAREFA[i].label };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const jsonTarefa = {
                            id: i,
                            value: ARRAY_TAREFA[i].value,
                            label: ARRAY_TAREFA[i].label,
                        }
                        arrTarefa.push(jsonTarefa);
                    }
                }
            } else {
                for (let i = 0; i < 3; i++) {

                    const jsonTamanhoLote = { id: i, valorTarefa: '0', valorPrevTarefa: '0', letraTarefa: ARRAY_TAREFA[i].label };
                    arrTamanhoLote.push(jsonTamanhoLote);

                    const jsonTarefa = {
                        id: i,
                        value: ARRAY_TAREFA[i].value,
                        label: ARRAY_TAREFA[i].label,
                    }
                    arrTarefa.push(jsonTarefa);
                }
            }
            setArrayAlternacia(arrTamanhoLote);
        } catch (error) {
            console.log('Erro na classe LancarProducao metodo atualizaDadosGeralNaTela', error);
        }
    }

    const changeSelectTamanhoLote = async (id, event) => {      // TAMANHO LOTE
        const { value } = event.target;

        const val3 = retiraMascaraMaterNumeros(value);
        const val4 = Number(val3);
        const val = `${val4}`;
        let tt = 0;
        let temp = arrayAlternacia.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    valorTarefa: val,
                };
            }
            if (id != product.id) {
                tt = product.valorTarefa != '' ? tt + Number(product.valorTarefa) : tt + 0;
            }
            return product;
        });
        setArrayAlternacia(temp);
        setTotalValorMes(`${tt}`)
    }

    const rarioOpcoes = (val) => {
        setIdTipoLancamento(val);

        if (val == '1') {
            const arrTamLote = arrayAlternacia.map((item) => {
                return { ...item, valorTarefa: '0' };
            });
            setArrayAlternacia(arrTamLote);
        } else {
            atualizaDadosGeralNaTela(alternanciaSelecionado, arrayDadosGeralCompleto.listProducaoPessoaAlternanciaItem);
        }
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    function renderInfo() {
        return (
            <Box my={'10px'} >
                <Wrap spacing='10px' mb={'5px'} justify='space-evenly' px={'1'} mx={'10px'} bgGradient={GRADIENTE} borderRadius={'10px'}>
                    <WrapItem w={'48%'}>
                        <Box w='100%'>
                            <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Seringueiro'}</Text>
                            <Text color={'orange.700'} fontSize='16px' fontWeight='700' align={'center'}>{nomeSeringueiro}</Text>
                        </Box>
                    </WrapItem>
                    <WrapItem w={'48%'}>
                        <Box w='100%'>
                            <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Mês/Ano'}</Text>
                            <Text color={'orange.700'} fontSize='16px' fontWeight='700' align={'center'} textTransform={'capitalize'}>{descMesAno}</Text>
                        </Box>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderRario() {
        return (
            <Box mt={'40px'} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between" >
                <RadioGroup onChange={rarioOpcoes} value={idTipoLancamento} defaultValue='2'>
                    <Stack spacing={5} direction='row'>
                        <Radio size='md' name='1' colorScheme='blue' value='1' borderWidth={'1px'} borderColor={'blue.900'}>
                            <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Inserir valor total</Text>
                        </Radio>
                        <Radio size='md' name='1' colorScheme='blue' value='2' borderWidth={'1px'} borderColor={'blue.900'}>
                            <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Inserir por frequência</Text>
                        </Radio>
                    </Stack>
                </RadioGroup>
            </Box>
        )
    }

    function renderValoresAlternancia() {
        return (
            <Box mt={'20px'}>
                <Flex px='2px' h={'30px'} justify='start' align='center' bgGradient={gradientHeader} borderRadius={'8px'}>
                    <Box w={'100px'}>
                        <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Frequência'}</Text>
                    </Box>
                    <Box w={'150px'}>
                        <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Previsto'}</Text>
                    </Box>
                    <Box w={'105px'}>
                        <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                    </Box>
                </Flex>
                {arrayAlternacia.map((item, index) => {
                    return (
                        <Box key={index}>
                            <Box py={'5px'}>
                                <Flex px='2px' justify='start' align='center'>
                                    <Box w={'100px'}>
                                        <Text color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.letraTarefa}</Text>
                                    </Box>
                                    <Box w={'150px'}>
                                        <Text color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorPrevTarefa}</Text>
                                    </Box>
                                    <Box w={'105px'}>
                                        <Input
                                            key={index}
                                            value={item.valorTarefa}
                                            onChange={e => changeSelectTamanhoLote(item.id, e)}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            maxLength={8}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            h={'30px'}
                                            w={'100px'}
                                            disabled={idTipoLancamento == '2' ? false : true}
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    )
                })}
                <Flex px='2px' h={'35px'} mt={'10px'} justify='start' align='center' bgGradient={gradientHeader} borderRadius={'8px'}>
                    <Box w={'100px'} >
                        <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Total'}</Text>
                    </Box>
                    <Box w={'150px'}>
                        <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalPrevMes}</Text>
                    </Box>
                    <Box w={'105px'}>
                        <Box >
                            <Input
                                value={totalValorMes}
                                isInvalid={isValidQtdArvore}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _focus={{ boxShadow: 'none' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'30px'}
                                w={'100px'}
                                bg={'white'}
                                _disabled={{ bg: 'white', textColor: 'gray.400' }}
                                disabled={idTipoLancamento == '1' ? false : true}
                                onChange={e => setTotalValorMes(e.target.value)}
                            />
                        </Box>
                    </Box>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'10px'} mt={'10px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button variant="brand" onClick={validarAndcadastrarValores}>Salvar</Button>
            </Wrap>
        )
    }

    return (
        <Box mt={'50px'}>
            <Card direction='column' px='20px' py='10px'>
                {renderAlert()}
                {renderInfo()}
                {renderRario()}
                {renderValoresAlternancia()}
                {renderButton()}
            </Card>
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />
        </Box>
    );
}
