import React, { useEffect } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Stat,
    StatLabel,
    Image,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import Pesquisa from 'assets/img/layout/pesquisa.png';

export default function HomeProgramacao() {

    const history = useHistory();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const bg = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
        }
        loaderScreen();
    }, []);

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Opções de Programação</Text>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 9 }} gap='20px' mb='20px' mt={'15px'}>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Log da Programação'}</StatLabel>
                                <Flex align='end' mt={'10px'} justify={'center'}>
                                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/log-programacao") }}>
                                        <Image h={'70px'} w={'90px'} ml={'0'} src={Pesquisa} />
                                    </Button>
                                </Flex>
                            </Stat>
                        </Flex>
                    </Card>
                </SimpleGrid>
            </Card>
        </Box>
    );
}
