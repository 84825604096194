import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Flex,
    Spinner,
} from "@chakra-ui/react";

function Loader({ isOpen }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bg={'blackAlpha.500'}>
                <ModalBody>
                    <Flex my={'25px'} justify='center' align='center' >
                        <Spinner
                            thickness='5px'
                            speed='0.65s'
                            color='orange.400'
                            size='lg'
                        />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default Loader;
