import { Box, SimpleGrid } from "@chakra-ui/react";
import AssociarSeringueiroSafra from "views/admin/seringueiros/form/AssociarSeringueiroSafra";
import React from "react";

export default function Settings() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "55px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}>
                <AssociarSeringueiroSafra />
            </SimpleGrid>
        </Box>
    );
}
