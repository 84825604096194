import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Select,
    SimpleGrid,
    Box,
    Button,
    Center,
    Icon,
    Image,
    Stat,
    StatNumber,
    StatLabel,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LoaderBlack from 'components/loading/LoaderBlack';
import { IoArrowRedo } from "react-icons/io5";
import { calculoGeralRelatorio, carregarArvoresParaUmaSangria } from '../../../../util/CalculosRelatorioQualidadeSangria';
import { calculaTotalDePontosArvoreSemDivisao, retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { LISTA_MESES2 } from 'model/MockData';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import PdfIconDownload from 'assets/img/layout/pdf_icon_download.png';

const ListQualidadeSangria = () => {

    const pdfRef = useRef();
    const pdfArray02 = useRef();
    const pdfArray03 = useRef();
    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);

    const [arrayQualidade01, setArrayQualidade01] = useState([]);
    const [arrayQualidade02, setArrayQualidade02] = useState([]);
    const [arrayQualidade03, setArrayQualidade03] = useState([]);

    const [mediaGeralDePontos, setMediaGeralDePontos] = useState(0);
    const [notaGeral, setNotaGeral] = useState(0);
    const [avaliacaoGeral, setAvaliacaoGeral] = useState('');
    const [corNotaGeral, setCorNotaGeral] = useState('');
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [marginText, setMarginText] = useState('0px');
    const [loading, setLoading] = useState(false);
    const [nomeRelatorio, setNomeRelatorio] = useState('Qualidade-sangria');
    const [nomeUa, setNomeUa] = useState('');
    const [nomeMesSelecionado, setNomeMesSelecionado] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorInfo = useColorModeValue("gray.400", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            localStorage.removeItem('@HE-propriedadeParaEdicao');

            const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Qualidade-sangria-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);
            setAnoCorrente(ano);

            setNomeMesSelecionado(nmMes);
            setNomeMunicipio(nomeMunicipioP);
            setIdUf(idUfP);

            const nmUa = localStorage.getItem('@HE-nomeUa');
            setNomeUa(nmUa);

            const primeiroDiaDoMesCorrente = moment().startOf('month').format('YYYY-MM-DD');
            setMesSelecionado(primeiroDiaDoMesCorrente);

            const dtAtual = moment().format('YYYY-MM-DD');
            await pesquisaQualidadeSan(dtAtual);
            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const pesquisaQualidadeSan = async (dataPesquisa) => {
        try {
            const dt = moment(dataPesquisa).format('YYYY-MM-DD');
            const primeiroDiaDoMesCorrente = moment(dt).startOf('month').format('YYYY-MM-DD');
            const UltimoDiaDoMesCorrente = moment(dt).endOf('month').format("YYYY-MM-DD");
            let pontosGeral = 0;
            let notaGeral = 0;
            let qtdDivisao = 0;
            let ultimoId = 0;

            setArrayQualidade01([]);
            setArrayQualidade02([]);
            setArrayQualidade03([]);

            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const url = `api/qualidadeSangria/relatorioQualidadeSangria?idUa=${ua}&idSafra=${idSafra}&dtInicio=${primeiroDiaDoMesCorrente}&dtFim=${UltimoDiaDoMesCorrente}`;

            const response = await Api.getAllSemAccessToken(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                let arrTemp = [];
                if (res.length > 0) {
                    /*
                    ╔══════════════════════════════════════════════════════════════════╗
                        PRECISEI ORDENAR DO MENOR PARA O MAIOR PARA TRABALHAR NA LOGINA
                        MAIS ABAIXO
                    ╚══════════════════════════════════════════════════════════════════╝ */
                    const arrayOrdenado = await res.sort(function (a, b) { return a.idQualidadeSangria - b.idQualidadeSangria });
                    ultimoId = arrayOrdenado[arrayOrdenado.length - 1].idQualidadeSangria;

                    await arrayOrdenado.forEach(async (res2) => {
                        const jsonDadosSeringueiro = {
                            idPessoa: res2.idPessoa,
                            nome: res2.nome,
                        }
                        /*
                        ╔══════════════════════════════════════════════════════════════════╗
                            CARREGAR AS ARVORES
                        ╚══════════════════════════════════════════════════════════════════╝ */
                        const arrayArvoresParaTela = await carregarArvoresParaUmaSangria(res2, jsonDadosSeringueiro);
                        let urlImagEvidencia = null;
                        if (res2.urlArquivo != null) {
                            const urlArq = res2.urlArquivo;
                            //const imgP = `${ActionTypes.LINK_API_SPRING}${urlArq.url}`;      // TODO_URL_ALTERADA
                            const imgP = urlArq.urlStorage;
                            urlImagEvidencia = imgP;
                        }

                        let totalDePontosP = 0;
                        const arr = res2.listArvore;

                        if (arr.length > 0) {
                            //totalDePontosP = await calculaTotalDePontosArvore(arr);
                            totalDePontosP = await calculaTotalDePontosArvoreSemDivisao(arr);
                        }
                        /*
                        ╔══════════════════════════════════════════════════════════════════╗
                            CALCULANDO VALORES E CORES DOS CARDS
                        ╚══════════════════════════════════════════════════════════════════╝ */
                        totalDePontosP = totalDePontosP.toFixed(2);
                        totalDePontosP = Number(totalDePontosP);

                        const pontosSangria = Number(res2.pontos);
                        let notaSeringueiroP = pontosSangria - totalDePontosP;
                        notaSeringueiroP = notaSeringueiroP.toFixed(2);
                        notaSeringueiroP = Number(notaSeringueiroP);

                        const idAvaliacao = res2.idAvaliacao != null && res2.idAvaliacao != undefined ? res2.idAvaliacao : 1;

                        let avalia = '';
                        let corN = 'black';
                        if (idAvaliacao == 1) {
                            corN = 'gray.500';
                            avalia = 'Branco';
                        } else if (idAvaliacao == 2) {
                            corN = 'green.500';
                            avalia = 'Boa';
                        } else if (idAvaliacao == 3) {
                            corN = 'orange.600';
                            avalia = 'Média';
                        } else if (idAvaliacao == 4) {
                            corN = 'red';
                            avalia = 'Ruim';
                        }

                        if (totalDePontosP > 0) {
                            pontosGeral = pontosGeral + totalDePontosP;
                            notaGeral = notaGeral + notaSeringueiroP;
                            qtdDivisao = qtdDivisao + 1;
                        }

                        const ss = parseFloat(notaSeringueiroP.toFixed(2));
                        const xx = parseFloat(totalDePontosP.toFixed(2));

                        const jsonF = {
                            idQualidadeSangria: res2.idQualidadeSangria,
                            idPessoa: res2.idPessoa,
                            nome: res2.nome,
                            observacao: res2.observacao != null ? res2.observacao : '',
                            descMes: res2.descMes,
                            avaliacaoFinalizada: res2.avaliacaoFinalizada,
                            dtPeriodoDisponivel: res2.dtPeriodoDisponivel,
                            listArvore: arrayArvoresParaTela,
                            notaSeringueiro: ss,
                            totalPontos: xx,
                            avaliacaoSangria: avalia,
                            corNota: corN,
                            urlImage: urlImagEvidencia,
                            deletado: res2.deletado,
                        }

                        arrTemp.push(jsonF);

                        if (ultimoId == res2.idQualidadeSangria) {
                            const res = await calculoGeralRelatorio(pontosGeral, notaGeral, qtdDivisao);
                            setMediaGeralDePontos(res.mediaGeralDePontos);
                            setNotaGeral(res.notaGeral);
                            setAvaliacaoGeral(res.avaliacaoGeral);
                            setCorNotaGeral(res.corNotaGeral);

                            let array01 = [];
                            let array02 = [];
                            let array03 = [];
                            const TotalItens = arrTemp.length;
                            arrTemp.map((item, index) => {
                                if (index <= 10) {
                                    array01.push(item);
                                } else if (index > 10 && index < 20) {
                                    array02.push(item);
                                } else {
                                    array03.push(item);
                                }
                            })

                            setArrayQualidade01(array01);
                            setArrayQualidade02(array02);
                            setArrayQualidade03(array03);
                        }
                    })
                } else {
                    setMediaGeralDePontos(0);
                    setNotaGeral(0);
                    setAvaliacaoGeral('N/A');
                    setCorNotaGeral('black');
                }
            } else {
                console.log('a');
                return;
            }
        } catch (error) {
            console.log('Erro na classe RelatorioListaSerin metodo pesquisaQualidadeSan', error);
        }
    }

    const realizarPesquisaDoMesSelecionado = async (e) => {

        const dataSelecionada = e.target.value;
        const nmMes = await retornaNomeDoMes(moment(dataSelecionada).format('YYYY-MM-DD'));
        setNomeMesSelecionado(nmMes);

        const startTime = moment(dataSelecionada);
        const end = moment();
        const duration = moment.duration(startTime.diff(end));
        const dias = duration.asMonths();
        const no2 = parseFloat(dias.toFixed(0));

        if (no2 <= 0) {
            setMesSelecionado(dataSelecionada);
            const dtc = moment(dataSelecionada).format('YYYY-MM-DD');

            await pesquisaQualidadeSan(dtc);
        } else {

            return;
        }
    }

    const detalhesQualidadeSangria = async (item) => {
        localStorage.setItem('@HE-dadosParaRelatorioQualidadeSangria', JSON.stringify(item));
        history.push("/admin/relatorioQualidade-sangria");
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1000);
    }

    const gerarPDF = async () => {
        const input1 = pdfRef.current;

        let imgData1 = null;
        let imgHeigh1 = 0;
        let imgWWIDTH1 = 0;

        let imgData2 = null;
        let imgHeigh2 = 0;

        let imgData3 = null;
        let imgHeigh3 = 0;

        await html2canvas(input1).then((canvas) => {
            imgData1 = canvas.toDataURL('image/png');
            imgHeigh1 = canvas.height;
            imgWWIDTH1 = canvas.width;          // ESSE CARA E O MESMO VALOR PRA TODOS
        });
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeigh = pdf.internal.pageSize.getHeight();
        const ratio1 = Math.min(pdfWidth / imgWWIDTH1, pdfHeigh / imgHeigh1);

        pdf.addImage(imgData1, 'PNG', 5, 10, pdfWidth - 10, imgHeigh1 * ratio1);

        if (arrayQualidade02.length > 0) {
            const input2 = pdfArray02.current;

            await html2canvas(input2).then((canvas) => {
                imgData2 = canvas.toDataURL('image/png');
                imgHeigh2 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData2, 'PNG', 5, 10, pdfWidth - 10, imgHeigh2 * ratio1);
            });
        }
        if (arrayQualidade03.length > 0) {
            const input3 = pdfArray03.current;

            await html2canvas(input3).then((canvas) => {
                imgData3 = canvas.toDataURL('image/png');
                imgHeigh3 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData3, 'PNG', 5, 10, pdfWidth - 10, imgHeigh3 * ratio1);
            });
        }
        pdf.save(`${nomeRelatorio}.pdf`);
    }

    function renderSelect() {
        return (
            <Box mt={'-10px'}>
                <Flex px='20px' justify='space-between' align='center'>
                    <Box>
                        <Text color={textColor} fontSize='17px' lineHeight='100%' p={'5px'} ml={'25px'}>Meses</Text>
                        <Select
                            value={mesSelecionado}
                            errorBorderColor='crimson'
                            placeholder="selecione"
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={realizarPesquisaDoMesSelecionado}
                            w={'400px'}
                            h={'35px'}
                            ml={'20px'}
                        >
                            {LISTA_MESES2.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                    <Image boxSize='35px' objectFit='contain' src={PdfIconDownload} alt='Dan Abramov' cursor={'pointer'} onClick={downloadPdf} />
                </Flex>
            </Box>
        )
    }

    function renderCardsQualidade() {
        let mediaG = `${mediaGeralDePontos}`;
        mediaG = mediaG.length == 1 ? `${mediaG}.0` : mediaG;
        mediaG = mediaG.replace('.', ',');
        let notaG = `${notaGeral}`;
        notaG = notaG.length == 1 ? `${notaG}.0` : notaG;
        notaG = notaG.replace('.', ',');
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'} px={'3'}>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Total de pontos'}</StatLabel>
                            <StatNumber mb={marginText} color={textColor} fontSize={{ base: "xl", }}>{mediaG}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Nota dos seringueiro'}</StatLabel>
                            <StatNumber mb={marginText} color={corNotaGeral} fontSize={{ base: "xl", }}>{`${notaG}%`}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Avaliação da sangria'}</StatLabel>
                            <StatNumber mb={marginText} color={corNotaGeral} fontSize={{ base: "xl", }}>{avaliacaoGeral}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
            </SimpleGrid>
        )
    }

    function renderSeringueiros(array = []) {
        return (
            <Card direction='column' w='100%' px='10px' py='0px'>
                {array.map((item, index) => {
                    let pontosS = `${item.totalPontos}`;
                    pontosS = pontosS.length == 1 ? `${pontosS}.0` : pontosS;
                    pontosS = pontosS.replace('.', ',');
                    let notaS = `${item.notaSeringueiro}`;
                    notaS = notaS.length == 1 ? `${notaS}.0` : notaS;
                    notaS = notaS.replace('.', ',');
                    const deletado = item.deletado != null ? item.deletado : false;
                    return (
                        <Box
                            key={index}
                            position={'relative'}
                            borderWidth={'2px'}
                            borderRadius='20px'
                            p={'1px'}
                            bg={'white'}
                            mt={'6px'}
                            mb={'5px'}
                            borderColor={'blackAlpha.400'}>
                            {deletado &&
                                <Flex w={'90px'} justify='center' py={'5px'} align='center' bg={'transparent'} borderRadius={'10px'} ml={'15px'} mt={'5px'} mb={'-10px'}>
                                    <Text color={'red'} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Desativado</Text>
                                </Flex>
                            }
                            <Flex justify='space-between' py={'1px'} align='center'>
                                <Box w={'100%'} >
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nome</Text>
                                    <Text mb={marginText} color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.nome}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Total de pontos</Text>
                                    <Text mb={marginText} color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{pontosS}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nota do seringueiro</Text>
                                    <Text mb={marginText} color={item.corNota} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${notaS}%`}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Avaliação</Text>
                                    <Text mb={marginText} color={item.corNota} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.avaliacaoSangria}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Center alignItems={'center'} h={'100%'}>
                                        <Button h="70px" bg="transparent" _hover={{ cursor: "pointer" }} onClick={() => { detalhesQualidadeSangria(item) }}>
                                            <Icon as={IoArrowRedo} width='30px' height='30px' color='orange.600' />
                                        </Button>
                                    </Center>
                                </Box>
                            </Flex>
                        </Box>
                    )
                })}
            </Card>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'10px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='5px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            {renderSelect()}
            <Box ref={pdfRef} w='100%' mt={'10px'}>
                <Box px={'5'}>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center' mb={'20px'}>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>{`Qualidade de Sangria ${anoCorrente}`}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesSelecionado}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                        </Box>
                    </Box>
                </Box>
                {renderCardsQualidade()}
                {arrayQualidade01.length > 0 ?
                    renderSeringueiros(arrayQualidade01)
                    :
                    <Text color={'gray.400'} fontSize='25px' fontWeight={'bold'} align={'center'}>{`Sem Resultados`}</Text>
                }
            </Box>
            <Box ref={pdfArray02}>
                {arrayQualidade02.length > 0 && renderSeringueiros(arrayQualidade02)}
            </Box>
            <Box ref={pdfArray03}>
                {arrayQualidade03.length > 0 && renderSeringueiros(arrayQualidade03)}
            </Box>
            <LoaderBlack isOpen={loading} />
        </Card>
    );
};

export default ListQualidadeSangria;
