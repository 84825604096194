import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Flex,
    Spinner,
    Text,
} from "@chakra-ui/react";

function LoaderBlack({ isOpen, titulo = 'Processando...', subTitulo = '' }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay
                bg='none'
                backdropFilter='auto'
                backdropInvert='20%'
                backdropBlur='10px'
            />
            <ModalContent>
                <ModalBody>
                    <Flex 
                        h={subTitulo == '' ? '70px' : '100px'} 
                        flexDirection={'column'} 
                        my={'10px'} 
                        justify='space-between' 
                        align='center'>
                        <Text color={'blackAlpha.900'} fontSize='18px' fontWeight='bold' align={'center'} lineHeight='100%'>{titulo}</Text>
                        <Spinner thickness='5px' speed='0.65s' color='orange.400' size='lg' />
                        <Text color={'blackAlpha.900'} fontSize='13px' fontWeight='bold' align={'center'} lineHeight='100%'>{subTitulo}</Text>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default LoaderBlack;
