//import decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import * as Api from '../store/ApiSpring';
import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import { ARRAY_PERMISSOES_MOCK } from 'model/MockData';

export async function VALIDAR_AUTENTICACAO_USUARIO() {
    var resultado = true;
    try {
        const tokenUser = localStorage.getItem('@HE-access_token');
        if (tokenUser != null) {

            const ss = await verificaValidadeAccessToken();
            resultado = ss == '02' ? true : false;

        } else {
            resultado = false;
        }
    } catch (error) {
        resultado = false;
        //console.error("Erro na classe ValidarAutenticacao metodo VALIDAR_AUTENTICACAO_USUARIO", error.message);
    }
    return resultado;
}

/*
╔═══════════════════════════════════════════════════════════════════════════╗
   VALIDO O TEMPO DE EXPIRACAO, CASO ESTEJA EXPIRADO AUTENTICO NOVAMENTE
╚═══════════════════════════════════════════════════════════════════════════╝*/
const verificaValidadeAccessToken = async () => {
    var validation = '02';
    try {
        const alt = localStorage.getItem('@HE-altenticacaoNecessaria');
        const altenticacaoNecessaria = alt === 'true' ? true : false;

        const accessToken = localStorage.getItem('@HE-access_token');
        const tokenDecoder = jwtDecode(accessToken);

        if (Date.now() >= tokenDecoder.exp * 1000 || altenticacaoNecessaria == true) {

            const senha = localStorage.getItem('@HE-senhalUsuariLogin');
            const emailParaLogin = localStorage.getItem('@HE-emailParaLogin');

            const urlUsuarioAndSenha = `username=${emailParaLogin}&password=${senha}&grant_type=password`;
            const response = await Api.postAutenticacaoApp(urlUsuarioAndSenha);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

                const res = await response.respostaRequisicao.data;
                await gerenciamentoListaDeUaQueUsuarioLogadoTemAcesso(res);
                await altenticacaoNecessariaP(false);
            }
            validation = response.numeroStatusResposta;

        } else {
            await armazenarPermissoesUsuario(accessToken);

        }
    } catch (error) {
        //console.log('Erro na classe ValidarAutenticacao metodo verificaValidadeAccessToken', error.message);
    }
    return validation;
};

export async function armazenarDadosControleSangriaDosSeringueiros(arrayControleSangriaDosSeringueiros) {
    var resul = false;
    const dataAtual = moment().format('YYYY-MM-DD HH:mm:ss');
    try {
        if (arrayControleSangriaDosSeringueiros.length > 0) {

            localStorage.setItem('@HE-dataDaUltimaAtualizacaoDosDados', `${dataAtual}`);
            localStorage.setItem('@HE-arrayControleSangriaDosSeringueiros', JSON.stringify(arrayControleSangriaDosSeringueiros));
            resul = true;
        }
    } catch (error) {
        //console.error("Erro na classe util/ValidarAutenticacao metodo armazenarDadosControleSangriaDosSeringueiros ", error);
    }
    return resul;
}

/*
╔═══════════════════════════════════════════════════════════════════════════╗
    DEPOIS QUE O USUARIO ESTA LOGADO PESQUISO VERIFICAR SE EXISTE ALGUMA 
    UA VINCULADA 
    OBS:
        PRECIA SER UMA UA QUE TENHA SAFRA VIGENTE
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function gerenciamentoListaDeUaQueUsuarioLogadoTemAcesso(res) {
    var resultado = 0;
    try {
        await armazenarPermissoesUsuario(res.access_token);

        localStorage.setItem('@HE-access_token', res.access_token);
        const tempoDeExpiracaoDoAccessTokenEmSegundos = res.expires_in;
        const dtAtual = moment();
        const xx = moment(dtAtual).add(tempoDeExpiracaoDoAccessTokenEmSegundos, 'seconds');
        const dataAndHoraDeExpiracaoDoAccessToken = moment(xx).format('YYYY-MM-DD HH:mm:ss');
        localStorage.setItem('@HE-dataAndHoraDeExpiracaoDoAccessToken', dataAndHoraDeExpiracaoDoAccessToken);

        const idUaAsync = localStorage.getItem('@HE-idUa');
        const idSafraAsync = localStorage.getItem('@HE-idSafra');

        let url = '';
        if (idUaAsync != null) {
            url = `api/usuario/retornaUmaUaComUmaSafraAtiva?idUsuario=${res.idUsuario}&idUa=${idUaAsync}&idSafra=${idSafraAsync}`;
        } else {
            url = `api/usuario/retornaUmaUaComUmaSafraAtiva?idUsuario=${res.idUsuario}`;        // NO CASO DE PRIMEIRO ACESSO OU USUARIO CLICOU EM SAIR
        }

        const response = await Api.getAllSemAccessToken(url);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const resSafra = response.respostaRequisicao.data;

            resultado = resSafra.length;

            if (resSafra.length > 0) {

                const idUaP = resSafra[0].idUa;

                await inserirInformacoesAutenticacao(res, idUaP);
                await inserirInformacoesUaAndSafra(resSafra[0]);

            } else {
                //console.log(` USUARIO ${res.idUsuario} NAO TEM ACESSO OU SAFRA CADASTRADA PARA ELE `);
            }
        }

    } catch (error) {
        resultado = 0;
        //console.log("Erro na classe ValidarAutenticacao metodo gerenciamentoListaDeUaQueUsuarioLogadoTemAcesso", error);
    }
    return resultado;
}

/*
╔═══════════════════════════════════════════════════════════════════════════╗
   IMPORTANTE CENTRALIZAR EM UM LUGAR SO, ESSAS GRAVACOES NO  localStorage
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function inserirInformacoesAutenticacao(res, idUa) {
    var validation = true;
    try {
        localStorage.setItem('@HE-idUa', `${idUa}`);
        localStorage.setItem('@HE-telefoneUsuariLogado', res.numeroCelular);
        localStorage.setItem('@HE-nomeCompletoUsuarioLogado', res.nome);
        localStorage.setItem('@HE-idUsuarioLogado', `${res.idUsuario}`);
        localStorage.setItem('@HE-emailUsuarioLogado', res.email);
        localStorage.setItem('@HE-emailPessoalUsuario', res.emailPessoal);
        localStorage.setItem('@HE-idTipoUsuarioLogado', `${res.idTipoUsuario}`);
    } catch (error) {
        validation = false;
        //console.log('Erro na classe ValidarAutenticacao metodo inserirInformacoesAutenticacao', error.message);
    }
    return validation;
};

export async function inserirInformacoesUaAndSafra(res) {
    var validation = true;
    try {
        localStorage.setItem('@HE-idUa', `${res.idUa}`);
        localStorage.setItem('@HE-nomeUa', res.descricao);
        localStorage.setItem('@HE-idUfCadastradadoNaUa', res.idUf);       // ESSE CARA E STRING MESMO
        localStorage.setItem('@HE-idNomeUfCadastradadoNaUa', res.nomeUf);
        localStorage.setItem('@HE-nomeMunicipioCadastradadoNaUa', res.nomeMunicipio);
        localStorage.setItem('@HE-idSafra', `${res.idSafra}`);
        localStorage.setItem('@HE-nomeSafra', res.nomeSafra);
        localStorage.setItem('@HE-dtInicioSafraAsync', res.dtInicio);
        localStorage.setItem('@HE-dtFimSafraAsync', res.dtFim);
        localStorage.setItem('@HE-sangriaAtivaAsync', `${res.sangriaAtiva}`);
    } catch (error) {
        validation = false;
        //console.log('Erro na classe ValidarAutenticacao metodo inserirInformacoesUaAndSafra', error.message);
    }
    return validation;
};

/*
╔═══════════════════════════════════════════════════════════════╗
    AQUI CALCULO SE A DATA/HORA DO TOKEN AINDA ESTA VIGENTE
╚═══════════════════════════════════════════════════════════════╝*/
export async function executarApiSpring() {
    var validacaoToken = false;
    try {
        const dtAtual = moment();

        const xx = localStorage.getItem('@HE-dataAndHoraDeExpiracaoDoAccessToken');
        const dataFutura = moment(xx);
        const duration = moment.duration(dataFutura.diff(dtAtual));
        const resultado = duration.asMinutes();
        const no2 = parseFloat(resultado.toFixed(0));

        validacaoToken = no2 > 3 ? true : false;
    } catch (error) {
        //console.log('Erro na classe ValidarAutenticacao metodo tempoExpiracaoAccessToken ', error);
    }
    return validacaoToken;
}

/*
╔═══════════════════════════════════════════════════════════════╗
    AQUI CALCULO SE A DATA/HORA DO TOKEN AINDA ESTA VIGENTE
╚═══════════════════════════════════════════════════════════════╝*/
export async function tempoExpiracaoAccessToken() {
    var validacaoToken = false;
    try {
        const dtAtual = moment();
        const xx = localStorage.getItem('@HE-dataAndHoraDeExpiracaoDoAccessToken');
        const dataFutura = moment(xx);
        const duration = moment.duration(dataFutura.diff(dtAtual));
        const resultado = duration.asMinutes();
        const no2 = parseFloat(resultado.toFixed(0));

        validacaoToken = no2 > 3 ? true : false;
    } catch (error) {
        //console.log('Erro na classe ValidarAutenticacao metodo tempoExpiracaoAccessToken ', error);
    }
    return validacaoToken;
}

export async function armazenarPermissoesUsuario(accessToken) {

    let result = '0';
    let pesquisar = false;
    let gerente = false;
    let seringueiro = false;
    let produtor = false;
    let admin = false;
    let tecnico = false;
    let desenv_arquiteto = false;

    try {
        const tokenDecoder = jwtDecode(accessToken);
        const arr = tokenDecoder.authorities;

        if (arr.length > 0) {
            let temp = ARRAY_PERMISSOES_MOCK.map((item, index) => {
                let exist = false;

                if (arr[index] != undefined) {
                    const xx = arr[index];

                    if (xx === 'ROLE_PESQUISAR') pesquisar = true;
                    if (xx === 'ROLE_GERENTE') gerente = true;
                    if (xx === 'ROLE_SERINGUEIRO') seringueiro = true;
                    if (xx === 'ROLE_PRODUTOR') produtor = true;
                    if (xx === 'ROLE_ADMIN') admin = true;
                    if (xx === 'ROLE_TECNICO') tecnico = true;
                    if (xx === 'ROLE_DESENV_ARQUITETO') desenv_arquiteto = true;

                    exist = true;
                    return {
                        nome: item.nome,
                        status: exist
                    }
                }
                if (arr[index] == undefined) {
                    return {
                        nome: item.nome,
                        status: exist
                    }
                }
            })
            result = temp;

            // console.log('');
            // console.log('pesquisar     ', pesquisar);
            // console.log('gerente       ', gerente);
            // console.log('seringueiro   ', seringueiro);
            // console.log('produtor      ', produtor);
            // console.log('admin         ', admin);

            localStorage.setItem('@HE-permissoesUsuarioLogado', JSON.stringify(temp));

            await GRAVAR_PERMISSOES_ASYNKSTORAGE(pesquisar, seringueiro, gerente, produtor, admin, tecnico, desenv_arquiteto);

            await altenticacaoNecessariaP(true);
        }
    } catch (error) {
        //console.log('Erro na classe ValidarAutenticacao metodo armazenarPermissoesUsuario ', error);
    }
    return result;
}

export async function GRAVAR_PERMISSOES_ASYNKSTORAGE(pesquisar = false, seringueiro = false, gerente = false, produtor = false, admin = false, tecnico = false, desenv_arquiteto = false) {

    let tipo = '';

    if (admin) {
        tipo = 'Administrador';
    } else if (tecnico) {
        tipo = 'Técnico';
    } else if (produtor) {
        tipo = 'Produtor';
    } else if (gerente) {
        tipo = 'Gerente';
    } else if (seringueiro) {
        tipo = 'Seringueiro';
    } else {
        tipo = 'Indefinido';
    }

    const objPermissao = {
        pesquisar: pesquisar,
        seringueiro: seringueiro,
        gerente: gerente,
        produtor: produtor,
        tecnico: tecnico,
        admin: admin,
        desenv: desenv_arquiteto,
    }

    localStorage.setItem('@HE-descPerfilUsuarioLogado', tipo);
    localStorage.setItem('@HE-obj-com-permissoes-acesso', JSON.stringify(objPermissao));
    altenticacaoNecessariaP(true);
}

export async function altenticacaoNecessariaP(val = false) {
    localStorage.setItem('@HE-altenticacaoNecessaria', `${val}`);
}

export async function REMOVE_CACHE_RELATORIO_PERSONALIZADO() {
    localStorage.removeItem('@HE-array-assunto');
    localStorage.removeItem('@HE-comentario-tela-observacoes');
    localStorage.removeItem('HE@-sangriasDiarias-personalizado');
    localStorage.removeItem('@HE-qualidadeSangria-personalizado');
    localStorage.removeItem('HE@-dadosRelatorioAcompanhamentoProducao');
    localStorage.removeItem('@HE-comentario-tela-sangria');
    localStorage.removeItem('@HE-comentario-tela-qualidade');
    localStorage.removeItem('@HE-comentario-tela-producao');
    localStorage.removeItem('@HE-array-imagens-sangria-personalizado');
    localStorage.removeItem('@HE-array-imagens-qualidade-personalizado');
    localStorage.removeItem('@HE-array-imagens-producao-personalizado');
    localStorage.removeItem('@HE-opcoes-relatorio-personalizado');
    localStorage.removeItem('@HE-dados-tela-assunto');
}

export async function REMOVE_CACHE_RELATORIO_ESPORADICO() {
    localStorage.removeItem('@HE-array-assunto-esporadico');
    localStorage.removeItem('@HE-dados-tela-assunto-esporadico');
    localStorage.removeItem('@HE-opcoes-relatorio-esporadico');
    localStorage.removeItem('@HE-esporadico-dados-fazenda');
    localStorage.removeItem('@HE-array_IndicadoresMensais');
    localStorage.removeItem('@HE-array-imagens-indicadores-mensais');
    localStorage.removeItem('@HE-comentario-tela-observacoes-esporadico');
}

/*
╔═══════════════════════════════════════════════════════════════════════════╗
    EM CASO DE LOGOUNT APAGO AS INFOMACOES DO USUARIO  localStorage
╚═══════════════════════════════════════════════════════════════════════════╝*/
export async function LogoutUsuario() {
    var resultado = true;
    try {
        // localStorage.removeItem('@HE-telefoneUsuariParaTelaLogin');       // DEIXAR ESSE CARA AQUI!!!!! ESSE CARA E USADO PARA PREENCHE O CAMPO NA TELA DE LOGIN
        // localStorage.removeItem('@HE-senhalUsuariParaTelaLogin');         // DEIXAR ESSE CARA AQUI!!!!! ESSE CARA E USADO PARA PREENCHE O CAMPO NA TELA DE LOGIN
        // localStorage.removeItem('@HE-imagemPerfilUsuarioLogado'); // DEIXAR ESSE CARA AQUI!!!!! E A IMAGEM DE PERFIL

        localStorage.removeItem('@HE-emailParaLogin');
        localStorage.removeItem('@HE-senhalUsuariLogin');
        localStorage.removeItem('@HE-telefoneUsuariLogado');
        localStorage.removeItem('@HE-nomeCompletoUsuarioLogado');
        localStorage.removeItem('@HE-idUsuarioLogado');
        localStorage.removeItem('@HE-emailUsuarioLogado');
        localStorage.removeItem('@HE-emailPessoalUsuario');
        localStorage.removeItem('@HE-primeiroNomeUsuarioLogado');
        localStorage.removeItem('@HE-access_token');
        localStorage.removeItem('@HE-idTipoUsuarioLogado');
        localStorage.removeItem('@HE-objSeringueiroParaTelaAvaliacao');
        localStorage.removeItem('@HE-dataDaUltimaAtualizacaoDosDados');
        localStorage.removeItem('@HE-idUa');
        localStorage.removeItem('@HE-idSafra');
        localStorage.removeItem('@HE-nomeUa');
        localStorage.removeItem('@HE-nomeSafra');
        localStorage.removeItem('@HE-dtInicioSafraAsync');
        localStorage.removeItem('@HE-dtFimSafraAsync');
        localStorage.removeItem('@HE-permissoesUsuarioLogado');
        localStorage.removeItem('@HE-altenticacaoNecessaria');
        localStorage.removeItem('@HE-objSangriaParaTelaGaleria');
        localStorage.removeItem('@HE-objSangriaParaTelaFotoModal');
        localStorage.removeItem('@HE-idUfCadastradadoNaUa');
        localStorage.removeItem('@HE-idNomeUfCadastradadoNaUa');
        localStorage.removeItem('@HE-nomeMunicipioCadastradadoNaUa');
        localStorage.removeItem('@HE-descPerfilUsuarioLogado');
        localStorage.removeItem('@HE-obj-com-permissoes-acesso');
        localStorage.removeItem('@HE-dadosParaSazonalidade');
        localStorage.removeItem('@HE-idUsuarioSelecionadoTelaListaPropridade');
        localStorage.removeItem('@HE-dadosParaTelaLancamentoDeTarefa');
        localStorage.removeItem('@HE-datosParaProgramacaoIndividualAndReprogracao');
        localStorage.removeItem('@HE-sangriaAtivaAsync');

    } catch (error) {
        //console.error("Erro na classe util/ValidarAutenticacao metodo LogoutUsuario ", error.message);
    }
    return resultado;
}

export async function verificarExisteNotificacoes() {
    var result = false;

    try {
        const ua = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
        const fim = localStorage.getItem('@HE-dtFimSafraAsync');

        const url = `notificacao/checkExistNotification?idUa=${ua}&idSafra=${idSafra}&dtInicio=${ini}&dtFim=${fim}`;
        const response = await Api.getRequest(url);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = response.respostaRequisicao.data;

            result = res == true ? true : false;
        }

    } catch (error) {
        result = false;
        //console.log('Erro na classe ValidarAutenticacao metodo verificarExisteNotificacoes', error);
    }

    return result;
};
