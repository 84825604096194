import React from 'react';
import {
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Flex,
    Button,
    VStack,
} from '@chakra-ui/react';

export default function VideoModalUpload({ isOpen, onPressOk, onPressCancel, videoSrc = '' }) {

    const gradientHeader = 'linear-gradient(gray.200, gray.300, gray.400)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent maxW="600px" position="relative" bgGradient={gradientHeader}>
                <ModalCloseButton onClick={onPressCancel} borderWidth={'1px'} borderColor={'white'} bg={'blackAlpha.200'} />
                <ModalBody p={0}>
                    <Box px={'10px'}>
                        <Text my={'10px'} color={'gray.600'} fontSize={'18px'} fontWeight={'bold'} align={'center'}>{'Novo Vídeo'}</Text>
                        <Flex p={'10px'} mt={'15px'} flexDirection={'column'} w={'100%'} justify={'center'} align={'start'} borderTopWidth={'1px'} borderColor={'blackAlpha.600'}>
                            <Text color={'gray.600'} fontSize={'14px'} fontWeight={'light'} align={'center'} lineHeight={'100%'}>{'Anexo'}</Text>
                        </Flex>
                        <Flex flexDirection={'column'} justify={'center'} align={'center'} bg={'gray.100'} borderWidth={'1px'} borderColor={'blackAlpha.400'} borderRadius={'10px'}>
                            <Box px={'10px'} py={'10px'} maxH={'500px'}>
                                <VStack spacing={4} align="center">
                                    {videoSrc != '' && (
                                        <Box>
                                            <video
                                                src={videoSrc}
                                                controls
                                                width="500"
                                            />
                                        </Box>
                                    )}
                                </VStack>
                            </Box>
                        </Flex>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Flex w={'100%'} justify={'center'} align={'center'}>
                        <Button variant="brand" w={'100%'} onClick={onPressOk}>Salvar</Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
