import { Box, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
//import Ilustration from 'assets/img/dashboards/auth.png'
import * as ActionTypes from '../../constants/ActionTypes';
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// import * as ActionTypes from '../../constants/ActionTypes';
// import { NavLink } from "react-router-dom";
// import { FaChevronLeft } from "react-icons/fa";

// TODO LINK NA TELA DE LOGIN PARA VOLTAR PARA DASHBOARD
function AuthIllustration(props) {

    const { children, illustrationBackground } = props;

    //console.log('aaaaa', children);

    return (
        <Flex position='relative' h='max-content'>
            <Flex
                h={{
                    sm: "initial",
                    md: "unset",
                    lg: "100vh",
                    xl: "97vh",
                }}
                w='100%'
                maxW={{ md: "66%", lg: "1313px" }}
                mx='auto'
                pt={{ sm: "50px", md: "0px" }}
                px={{ lg: "30px", xl: "0px" }}
                ps={{ xl: "70px" }}
                justifyContent='start'
                direction='column'>
                {children}
                <Box
                    display={{ base: "none", md: "block" }}
                    h='100%'
                    minH='100vh'
                    w={{ lg: "50vw", "2xl": "44vw" }}
                    position='absolute'
                    right='0px'>
                    <Flex
                        //backgroundImage={Ilustration} // TODO A IMAGEM FIXA NAO CARREGA EM PROD, PRECISA RESOLVER
                        //bg={`url(${illustrationBackground})`}
                        //backgroundImage={`url(${illustrationBackground})`}
                        backgroundImage={`url('${ActionTypes.LINK_GOOGLE_STORAGE}/auth.png')`}
                        justify='center'
                        align='end'
                        w='100%'
                        h='100%'
                        bgSize='cover'
                        bgPosition='50%'
                        position='absolute'
                        borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}>
                    </Flex>
                </Box>
                <Footer />
            </Flex>
            {/* <FixedPlugin /> */}
        </Flex>
    );
}

AuthIllustration.propTypes = {
    illustrationBackground: PropTypes.string,
    image: PropTypes.any,
};

export default AuthIllustration;
