import React from "react";
import { Box, Flex, Drawer, DrawerBody, Icon, useColorModeValue, DrawerOverlay, useDisclosure, DrawerContent, DrawerCloseButton } from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import { renderThumb, renderTrack, renderView } from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { IoMenuOutline } from "react-icons/io5";

function Sidebar(props) {

    const { routes } = props;
    let variantChange = "0.2s linear";
    let shadow = useColorModeValue("14px 17px 40px 4px rgba(112, 144, 176, 0.08)", "unset");
    let colorBgMenu = useColorModeValue("navy.700", "white");
    const gradientColorOrange = [
        'linear(to-tr, orange.300, orange.900)',
        'linear(to-t, orange.200, orange.500)',
        'linear(to-b, orange.900, orange.300)',
    ]
    const gradientColorBlack = 'linear(to-r, navy.900, navy.900)';
    /*
    ╔══════════════════════════════════════════════════════════════════╗
        COR DO MENU LATERAL
    ╚══════════════════════════════════════════════════════════════════╝ */
    let sidebarMargins = "0px";

    return (
        <Box display={{ sm: "none", xl: "block" }} position='fixed' minH='100%'>
            <Box
                bgGradient={colorBgMenu === 'white' ? gradientColorBlack : gradientColorOrange}
                transition={variantChange}
                w='255px'           //  TODO  ESSE CARA E A LARGURA DO MENU
                h='100vh'
                m={sidebarMargins}
                minH='100%'
                overflowX='hidden'
                boxShadow={shadow}>
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}>
                    <Content routes={routes} />
                </Scrollbars>
            </Box>
        </Box>
    );
}

export function SidebarResponsive(props) {

    let menuColor = useColorModeValue("gray.400", "white");
    let colorBgMenu = useColorModeValue("navy.700", "white");
    const gradientColorBlack = 'linear(to-r, navy.900, navy.900)';
    const gradientColorOrange = [
        'linear(to-tr, orange.300, orange.900)',
        'linear(to-t, orange.200, orange.500)',
        'linear(to-b, orange.900, orange.300)',
    ]
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    const { routes } = props;

    return (
        <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
            <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
                <Icon
                    as={IoMenuOutline}
                    color={menuColor}
                    my='auto'
                    w='20px'
                    h='20px'
                    me='10px'
                    _hover={{ cursor: "pointer" }}
                />
            </Flex>
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement={document.documentElement.dir === "rtl" ? "right" : "left"}
                finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent
                    w='285px'
                    maxW='285px'
                    bgGradient={colorBgMenu === 'white' ? gradientColorBlack : gradientColorOrange}
                >
                    <DrawerCloseButton
                        zIndex='3'
                        onClose={onClose}
                        _focus={{ boxShadow: "none" }}
                        _hover={{ boxShadow: "none" }}
                    />
                    <DrawerBody maxW='285px' px='0rem' pb='0'>
                        <Scrollbars
                            autoHide
                            renderTrackVertical={renderTrack}
                            renderThumbVertical={renderThumb}
                            renderView={renderView}>
                            <Content routes={routes} />
                        </Scrollbars>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}

Sidebar.propTypes = {
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    variant: PropTypes.string,
};

export default Sidebar;
