import React from "react";
import {
    Flex,
    Link,
    List,
    ListItem,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {

    const textColor = useColorModeValue("secondaryGray.900", "white");
    let linkColor = useColorModeValue({ base: "gray.400", lg: "white" }, "white");
    
    return (
        <Flex
            zIndex='3'
            flexDirection={{ base: "column", lg: "row", }}
            alignItems={{ base: "center", xl: "start", }}
            justifyContent='space-between'
            px={{ base: "30px", md: "0px" }}
            pb='20px'>
            <Text color={textColor} textAlign={{ base: "center", xl: "start", }} mb={{ base: "20px", lg: "0px" }}>
                {" "}
                &copy; {1900 + new Date().getYear()}
                <Text as='span' fontWeight='500' ms='4px'>
                    HEVEA
                    <Link mx='3px' color={textColor} href='#' target='_blank' fontWeight='700'>Todos os direitos reservados!</Link>
                </Text>
            </Text>
            <List display='flex'>
                <ListItem me={{ base: "20px", md: "44px", }}>
                    <Link fontWeight='500' color={linkColor} href='mailto:cesar.savoia@consultecagro.com'>Suporte</Link>
                </ListItem>
                {/* <ListItem me={{ base: "20px", md: "44px", }}>
                    <Link fontWeight='500' color={linkColor} href='#'>Licença</Link>
                </ListItem> */}
                <ListItem me={{ base: "20px", md: "44px", }}>
                    <Link fontWeight='500' color={linkColor} href='#'>Termos de Uso</Link>
                </ListItem>
                <ListItem>
                    <Link fontWeight='500' color={linkColor} target="_blank" href='https://www.consultecagro.com/blog/'>Blog</Link>
                </ListItem>
            </List>
        </Flex>
    );
}
