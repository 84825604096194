import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Button, Select, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import { RiEdit2Fill, RiAddCircleLine } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';

const columns = [
    {
        Header: "nome",
        accessor: "nome"
    },
    {
        Header: "emailPessoal",
        accessor: "emailPessoal"
    },
    {
        Header: "numeroCelular",
        accessor: "numeroCelular"
    },
    {
        Header: "idUsuario",
        accessor: "idUsuario"
    },
];

const initialState = {
    pageSize: 10,
    pageIndex: 0
};

const ListaGerentes = () => {

    const [arrayProdutores, setArrayProdutores] = useState([]);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [idUsuarioP, setIdUsuarioP] = useState('');
    const [idUaP, setIdUaP] = useState('');
    const [idSafraP, setIdSafraP] = useState('');
    const [dtFimP, setDtFimP] = useState('');
    const [dtInicioP, setDtInicioP] = useState('');

    useEffect(() => {
        async function loaderScreen() {

            const param = localStorage.getItem('@HE-dadosGerenteParaEdicao');
            if (param !== null) {
                const dadosProdutor = JSON.parse(param);

                const idUser = dadosProdutor.idUsuario;

                setNomeFazenda(dadosProdutor.nomeFazenda);
                setIdUsuarioP(idUser);
                setIdUaP(dadosProdutor.idUa);
                setIdSafraP(dadosProdutor.idSafra);
                setDtFimP(dadosProdutor.dtFim);
                setDtInicioP(dadosProdutor.dtInicio);

                const url = `api/usuario/retornaUsuariosTipoGerente?idTipoUsuario=${idUser}&idUa=${dadosProdutor.idUa}`;
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    setArrayProdutores(res);
                }
            }
        }
        loaderScreen();
    }, [])

    const history = useHistory();
    const data = React.useMemo(() => arrayProdutores, [arrayProdutores]);
    const inputText = useColorModeValue("gray.700", "gray.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            initialState
        },
        usePagination
    );

    const novorProdutor = () => {
        history.push("/admin/form-gerente");
    }

    const editarProdutor = (item) => {
        const user = item.row.values;
        const zz = arrayProdutores.filter(x => x.idUsuario === user.idUsuario);

        if (zz.length > 0) {
            const objUser = zz[0];
            localStorage.setItem('@HE-dadosGerenteParaEdicao', JSON.stringify(objUser));
            history.push("/admin/form-gerente");
        }
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>

            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%'>
                    Lista de Produtores
                </Text>
                <Flex align='center'>
                    <Button
                        rightIcon={<RiAddCircleLine />}
                        variant="brand"

                        onClick={() => { novorProdutor() }}
                    >
                        Novo
                    </Button>
                </Flex>
            </Flex>
            <Table {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                const xxx = column.render("Header") === 'idUsuario' ? '' : column.render("Header");
                                return (
                                    <Th {...column.getHeaderProps()}>{xxx}</Th>
                                )
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let data = "";
                                    if (cell.column.Header === "nome") {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "email") {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "emailPessoal") {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "numeroCelular") {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );

                                    } else if (cell.column.Header === "idUsuario") {
                                        data = (
                                            <Flex align='center'>
                                                <Button
                                                    rightIcon={<RiEdit2Fill />}
                                                    variant="brand"
                                                    key={index}
                                                    onClick={() => { editarProdutor(cell) }}
                                                >
                                                    Editar
                                                </Button>
                                            </Flex>
                                        );
                                    }
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{ sm: "14px" }}
                                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <div className="pagination">
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {"<<"}
                </Button>{" "}
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {"<"}
                </Button>{" "}
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                </Button>{" "}
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {">>"}
                </Button>{" "}
                <span>
                    Página{" "}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{" "}
                </span>
                <span>
                    | Vá para página:{" "}
                    <Input
                        type="number"
                        color={inputText}
                        fontWeight='500'
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: "100px" }}
                    />
                </span>{" "}
                <Select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    <optgroup style={{ background: 'white' }}>
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Page {pageSize}
                            </option>
                        ))}
                    </optgroup>
                </Select>
            </div>
        </Card>
    );
};

export default ListaGerentes;
