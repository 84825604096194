import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Button,
    Wrap,
    WrapItem,
    SimpleGrid,
    Select,
    Icon,
    Textarea,
    Center,
    useDisclosure,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    IconButton,
    Badge,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { FREQUENCIA, TAREFAS, CASCA, SENTIDO, AVALIACAO_SANGRIA } from 'model/MockData';
import { calculaTotalDePontosArvoreSemDivisao, carregarArvoresParaUmaSangria } from '../../../../util/FuncoesUteis';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import ArvoreIcon from 'assets/img/layout/arvore_icon2.png';
import ModalDialog from 'components/modal/ModalDialog';
import { IoArrowRedoSharp } from "react-icons/io5";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ARRAY_MESES } from 'model/MockData';

export default function QualidadeSangria() {

    const history = useHistory();

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [arrayArvores, setArrayArvores] = useState([]);
    const [totalPontos, setTotalPontos] = useState(0);
    const [notaSeringueiro, setNotaSeringueiro] = useState(0);
    const [avaliacaoSangria, setAvaliacaoSangria] = useState('');
    const [observacao, setObservacao] = useState('');
    const [descMesP, setDescMesP] = useState('');
    const [numMesP, setNumMesP] = useState(0);
    const [avaliacaoFinalizadaP, setAvaliacaoFinalizadaP] = useState(false);
    const [dtPeriodoDisponivelP, setDtPeriodoDisponivelP] = useState('');
    const [nomeSeringueiro, setnomeSeringueiro] = useState('');
    const [idQualidadeSangriaP, setIdQualidadeSangriaP] = useState(null);
    const [idPessoaP, setIdPessoaP] = useState('');
    const [idUaP, setIdUaP] = useState('');
    const [idSafraP, setIdSafraP] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');
    const [corNota, setCorNota] = useState('red');

    const [tarefaSelecionado, setTarefaSelecionado] = useState('');
    const [frequenciaSelecionado, setFrequenciaSelecionado] = useState('');
    const [cascaSelecionado, setCascaSelecionado] = useState('');
    const [sentidoSelecionado, setSentidoSelecionado] = useState('');
    const [avaliacaoSelecionado, setAvaliacaoSelecionado] = useState('');
    const [isValidTarefa, setIsValidTarefa] = useState(false);
    const [isValidFrequencia, setIsValidFrequencia] = useState(false);
    const [isValidCasca, setIsValidCasca] = useState(false);
    const [isValidSentido, setIsValidSentido] = useState(false);
    const [qtdImages, setQtdImages] = useState(0);
    const [qtdVideos, setQtdVideos] = useState(0);

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [listUrlImages, setListUrlImages] = useState([]);
    const [listUrlVideos, setListUrlVideos] = useState([]);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [maxCharacterObservacao, setMaxCharacterObservacao] = useState(2000);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const bg = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.5)', 'unset');
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            setArrayMesInicio(ARRAY_MESES);

            const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
            setSangriaAtiva(sangriaAti == 'true' ? true : false);

            let currentDate = '';
            const param1 = localStorage.getItem('@HE-mesSelecionadoTelaQualidadeSangria');

            if (param1 != null) {
                currentDate = param1;
            } else {
                currentDate = moment().format('YYYY-MM-DD');
                localStorage.setItem('@HE-mesSelecionadoTelaQualidadeSangria', `${currentDate}`);
            }

            await carregarInformacoesDaTela(currentDate);
            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            setArrayArvores([]);
            setListUrlImages([]);
            setListUrlVideos([]);
        }
    }, []);

    async function carregarInformacoesDaTela(data) {
        try {
            window.scrollTo(0, 0);

            localStorage.removeItem('@HE-objSangriaParaTelaGaleria');
            localStorage.removeItem('@HE-objSangriaParaTelaFotoModal');
            localStorage.removeItem('@HE-objSeringueiroParaTelaAvaliacao');   // TENHO QUE REMOVER AQUI, ESSE CARA ESTA SENDO USADO PARA ENVIO DE INFOMACOES PARA A TELA MODAL
            localStorage.removeItem('@HE-objUrlImagensAndVideosTelaGaleria');

            const Param2 = localStorage.getItem('@HE-dadosParaTelaQualidadeSangria');
            const dadosSeringueiro = JSON.parse(Param2);

            const idPessoa = dadosSeringueiro.idPessoa;
            setnomeSeringueiro(dadosSeringueiro.nome);
            setIdPessoaP(idPessoa);

            const ano = moment().format('YYYY');
            setAnoCorrente(ano);
            const numMes = moment(data).endOf('month').format("MM");
            setNumMesP(numMes);

            const primeiroDiaDoMesCorrente = moment(data).startOf('month').format('YYYY-MM-DD');
            const UltimoDiaDoMesCorrente = moment(data).endOf('month').format("YYYY-MM-DD");
            setMesSelecionado(primeiroDiaDoMesCorrente);

            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            const idUser = localStorage.getItem('@HE-idUsuarioLogado');

            setIdSafraP(idSafra);
            setIdUaP(idUa);

            const url = `api/qualidadeSangria/qualidadeSangriaParaOperacaoOFFLINE?idUa=${idUa}&idSafra=${idSafra}&idPessoa=${idPessoa}&dtInicio=${primeiroDiaDoMesCorrente}&dtFim=${UltimoDiaDoMesCorrente}&idUsuario=${idUser}`;
            const response = await Api.getAllSemAccessToken(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                const listaQualidadeSangria = res.listaQualidadeSangria;

                if (listaQualidadeSangria.length > 0) {
                    const x = listaQualidadeSangria[0];
                    const idQualidade = x.idQualidadeSangria;
                    const listaGeralDeArvoresPorSeringueiro = res.listaGeralDeArvoresPorSeringueiro;
                    const listUrlArquivo = res.listUrlArquivo;

                    await carregaArrayImagesAndVideos(listUrlArquivo);

                    const jsonSangria = {
                        avaliacaoFinalizada: x.avaliacaoFinalizada == true ? true : false,
                        descMes: x.descMes,
                        dtPeriodoDisponivel: x.dtPeriodoDisponivel,
                        idPessoa: x.idPessoa,
                        idQualidadeSangria: idQualidade,
                        idSafra: x.idSafra,
                        idUa: x.idUa,
                        numMes: x.numMes,
                        idTarefa: x.idTarefa,
                        idQuantidadeTarefas: x.idQuantidadeTarefas,
                        idCasca: x.idCasca,
                        idSentido: x.idSentido,
                        idAvaliacao: x.idAvaliacao,
                        observacao: x.observacao,
                        pontos: x.pontos,
                        listArvore: listaGeralDeArvoresPorSeringueiro.length > 0 ? listaGeralDeArvoresPorSeringueiro : [],
                    }
                    await _carregaParametros(jsonSangria, dadosSeringueiro);

                    let totalDePontosP = 0;
                    const arr = jsonSangria.listArvore;
                    if (arr.length > 0) {
                        totalDePontosP = await calculaTotalDePontosArvoreSemDivisao(arr);
                    }
                    totalDePontosP = totalDePontosP.toFixed(2);
                    totalDePontosP = Number(totalDePontosP);

                    const pontosSangria = Number(jsonSangria.pontos);
                    let notaSeringueiroP = pontosSangria - totalDePontosP;
                    notaSeringueiroP = notaSeringueiroP.toFixed(2);
                    notaSeringueiroP = Number(notaSeringueiroP);

                    const constSoParaCalculo = parseFloat(notaSeringueiroP.toFixed(0));

                    if (constSoParaCalculo >= 95) {
                        setCorNota('red');
                        setAvaliacaoSangria('Boa');
                    } else if (constSoParaCalculo <= 94 && constSoParaCalculo >= 90) {
                        setCorNota('orange');
                        setAvaliacaoSangria('Média');
                    } else {
                        setCorNota('red');
                        setAvaliacaoSangria('Ruim');
                    }

                    const ss = parseFloat(notaSeringueiroP.toFixed(2));
                    setNotaSeringueiro(ss);
                    const xx = parseFloat(totalDePontosP.toFixed(2));
                    setTotalPontos(xx);
                }
            }

        } catch (error) {
            console.warn('Error na classe QualidadeSangria metodo carregarInformacoesDaTela ', error);
        }
    }

    const nextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === listUrlImages.length - 1 ? 0 : prevIndex + 1
        );
    };
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? listUrlImages.length - 1 : prevIndex - 1
        );
    };
    const handleImageClickCarrossel = (index) => {
        setCurrentIndex(index);
        onOpen();
    };

    async function carregaArrayImagesAndVideos(array) {

        if (array != null && array.length > 0) {

            const listTirandoDeletados = array.filter(x => x.statusExclusao != true);
            const arrayImg = [];
            const arrayVdo = [];
            let countImg = 0;
            let countVdo = 0;

            for (let i = 0; i < listTirandoDeletados.length; i++) {
                const item = listTirandoDeletados[i];

                if (item.idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_IMAGEM) {
                    const json = {
                        idUrldArquivo: item.idUrldArquivo,
                        urlStorage: item.urlStorage,
                    }
                    arrayImg.push(json);
                    countImg = countImg + 1;
                }
                if (item.idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_VIDEO) {
                    const json = {
                        idUrldArquivo: item.idUrldArquivo,
                        urlStorage: item.urlStorage,
                    }
                    arrayVdo.push(json);
                    countVdo = countVdo + 1;
                }
            }
            setQtdImages(countImg);
            setQtdVideos(countVdo);
            setListUrlImages(arrayImg);
            setListUrlVideos(arrayVdo);
        }
    }

    const _carregaParametros = async (res, dadosSeringueiro) => {
        const idQualidade = res.idQualidadeSangria;

        setIdQualidadeSangriaP(idQualidade);
        setObservacao(res.observacao != null ? res.observacao : '');
        setDescMesP(res.descMes);
        setAvaliacaoFinalizadaP(res.avaliacaoFinalizada);
        setDtPeriodoDisponivelP(res.dtPeriodoDisponivel);
        const idTa = res.idTarefa != null && res.idTarefa != '' ? `${res.idTarefa}` : '';
        const idFr = res.idQuantidadeTarefas != null && res.idQuantidadeTarefas != '' ? `${res.idQuantidadeTarefas}` : '';
        const idCa = res.idCasca != null && res.idCasca != '' ? `${res.idCasca}` : '';
        const idSe = res.idSentido != null && res.idSentido != '' ? `${res.idSentido}` : '';
        const idAv = res.idAvaliacao != null && res.idAvaliacao != '' ? `${res.idAvaliacao}` : '';
        setTarefaSelecionado(idTa);
        setFrequenciaSelecionado(idFr);
        setCascaSelecionado(idCa);
        setSentidoSelecionado(idSe);
        setAvaliacaoSelecionado(idAv);
        setArrayArvores([]);

        const zz1 = res.observacao != null && res.observacao != '' ? res.observacao.length : 0;
        const ttCaracter = 2000 - zz1;
        setMaxCharacterObservacao(ttCaracter);

        const arrayArvoresParaTela = await carregarArvoresParaUmaSangria(res, dadosSeringueiro);
        setArrayArvores(arrayArvoresParaTela);
    }

    const irParaTelaModal = async (item) => {
        if (avaliacaoFinalizadaP == true) {
            setNumStatusResposta(ActionTypes.QUALIDADE_SANGRIA_FINALIZADA);
            showAlert();
        }
        if (item.editavel == false) {
            //setNumStatusResposta(ActionTypes.SELECIONE_UMA_ARVORE);
            //showAlert();
        } else {
            localStorage.setItem('@HE-objSeringueiroParaTelaAvaliacao', JSON.stringify(item));
            history.push("/admin/avaliacao-modal");
        }
    }

    const salvarQualidadeSangria = async (finalizada = true) => {
        try {
            setOpenModalDialog(false);

            let valid = false;
            if (avaliacaoFinalizadaP == true) {
                setNumStatusResposta(ActionTypes.QUALIDADE_SANGRIA_FINALIZADA);
                await showAlert();
                return;
            }
            const arrayArvoresChecada = arrayArvores.filter(x => x.arvoreChecada == true);

            if (arrayArvoresChecada.length == 0) {
                valid = true;
            }
            if (tarefaSelecionado == null) {
                setIsValidTarefa(true);
                valid = true;
            } else {
                setIsValidTarefa(false);
            }
            if (frequenciaSelecionado == null) {
                setIsValidFrequencia(true);
                valid = true;
            } else {
                setIsValidFrequencia(false);
            }
            if (cascaSelecionado == null) {
                setIsValidCasca(true);
                valid = true;
            } else {
                setIsValidCasca(false);
            }
            if (sentidoSelecionado == null) {
                setIsValidSentido(true);
                valid = true;
            } else {
                setIsValidSentido(false);
            }

            if (valid) {
                setNumStatusResposta(ActionTypes.CAMPOS_OBRIGATORIOS_QUALIDADE_SANGRIA);
                showAlert();
                return;
            }

            const jsonParam = {
                idQualidadeSangria: idQualidadeSangriaP,
                idUa: idUaP,
                idPessoa: idPessoaP,
                idSafra: idSafraP,
                dtPeriodoDisponivel: dtPeriodoDisponivelP,          // RECEBE VALOR NO BACK PARA INSERT
                descNes: descMesP,                                  // RECEBE VALOR NO BACK PARA INSERT
                observacao: observacao == '' ? null : observacao,
                avaliacaoFinalizada: finalizada,
                pontos: '100',
                numMes: numMesP,
                idTarefa: tarefaSelecionado,
                idQuantidadeTarefas: frequenciaSelecionado,
                idCasca: cascaSelecionado,
                idSentido: sentidoSelecionado,
                idAvaliacao: avaliacaoSelecionado,
            }

            const response = await Api.putRequestSemAccessToken(`api/qualidadeSangria/atualisarQualidadeSangria`, jsonParam);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setNumStatusResposta(response.numeroStatusResposta);

                await carregarInformacoesDaTela(mesSelecionado);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe QualidadeSangria metodo salvarQualidadeSangria ', error);
        }
    }

    const showAlert = async () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 9000);
    }

    const acessarTelaDetalhes = () => {
        const jsonParam = {
            idPessoa: idPessoaP,
            nome: nomeSeringueiro,
            dtPeriodoDisponivel: dtPeriodoDisponivelP,
        }

        if (avaliacaoFinalizadaP == true) {
            localStorage.setItem('@HE-dadosParaRelatorioQualidadeSangria', JSON.stringify(jsonParam));
            history.push("/admin/relatorioQualidade-sangria");
        }
    }

    const acessarGaleriaImagemAndFotos = () => {
        const json = {
            idPessoa: idPessoaP,
            nome: nomeSeringueiro,
            idQualidadeSangria: idQualidadeSangriaP,
            mesSelecionado: mesSelecionado,
        }
        localStorage.setItem('@HE-objUrlImagensAndVideosTelaGaleria', JSON.stringify(json));
        history.push("/admin/galeria-imagem-video");
    }

    const changeObservacao = (val) => {
        setObservacao(val);
        const xx = val.length;
        const num = 2000 - xx;
        setMaxCharacterObservacao(num);
    }

    const changeMes = (val) => {
        setMesSelecionado(val);
        localStorage.setItem('@HE-mesSelecionadoTelaQualidadeSangria', `${val}`);
        carregarInformacoesDaTela(val);
    }

    function renderMeses() {
        return (
            <Wrap px={'10px'} spacing='10px' justify='flex-start'>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Meses</Text>
                        <Select
                            value={mesSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => changeMes(e.target.value)}
                            w={'195px'}
                            h={'35px'}
                        >
                            {arrayMesInicio.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                {sangriaAtiva == false && (
                    <WrapItem flex={'1'}>
                        <Box w='100%' h={'100%'}>
                            <Flex h={'100%'} justify={'end'} align={'end'}>
                                <Badge colorScheme='red' variant='solid'>Safra finalizada</Badge>
                            </Flex>
                        </Box>
                    </WrapItem>
                )}
            </Wrap>
        )
    }

    function renderTitulo() {
        const avaliacaoFinaliz = avaliacaoFinalizadaP ? 'Qualidade Sangria Finalizada' : 'Qualidade Sangria não Finalizada';
        const bgColorAvaliacao = avaliacaoFinalizadaP ? 'red' : 'green';

        return (
            <Wrap px={'20px'} py={'10px'} spacing='20px' justify={'space-between'} align={'center'}>
                <WrapItem>
                    <Text color={textColor} fontSize={'15px'} fontWeight='light' align={'center'} lineHeight={'100%'}>{'Seringueiro:'}</Text>
                    <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{nomeSeringueiro}</Text>
                </WrapItem>
                <WrapItem>
                    <Text color={textColor} fontSize={'15px'} fontWeight='light' align={'center'}>{`Mensal - `}</Text>
                    <Text color={textColor} fontSize={'14px'} fontWeight='bold' align={'center'}>{`${descMesP} de ${anoCorrente}`}</Text>
                </WrapItem>
                <WrapItem>
                    <Text color={textColor} fontSize={'15px'} fontWeight='light' align={'center'}>{'Avaliação'}</Text>
                    <Text color={bgColorAvaliacao} borderRadius={'8px'} fontSize={'15px'} fontWeight='bold' align={'center'} ml={'10px'} >{avaliacaoFinaliz}</Text>
                </WrapItem>
            </Wrap>
        )
    }

    function renderInformacoes() {
        return (
            <Box pointerEvents={sangriaAtiva ? 'auto' : 'none'} opacity={sangriaAtiva ? '1' : '0.5'}>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='5px'>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Tarefas *</Text>
                            <Select
                                value={tarefaSelecionado}
                                isInvalid={isValidTarefa}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => setTarefaSelecionado(e.target.value)}
                            >
                                {TAREFAS.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Frequência *</Text>
                            <Select
                                value={frequenciaSelecionado}
                                isInvalid={isValidFrequencia}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => setFrequenciaSelecionado(e.target.value)}
                            >
                                {FREQUENCIA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Casca *</Text>
                            <Select
                                value={cascaSelecionado}
                                isInvalid={isValidCasca}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => setCascaSelecionado(e.target.value)}
                            >
                                {CASCA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box mt={{ base: 0, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Sentido *</Text>
                            <Select
                                value={sentidoSelecionado}
                                isInvalid={isValidSentido}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => setSentidoSelecionado(e.target.value)}
                            >
                                {SENTIDO.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                </SimpleGrid>
            </Box>
        )
    }

    function remderArvores() {
        return (
            <Box mt={'30px'}>
                <Flex justify='center' align='center'>
                    <Text color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{`Numeração das árvores`}</Text>
                    <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                        <TooltipIcon
                            label={
                                <Box px={'0px'} py={'5px'} >
                                    <Text align={'center'}>{'Árvores'}</Text>
                                    <Text align={'start'} mt={'5px'}>{'Lançamento:'}</Text>
                                    <Text align={'start'} mt={'0px'} ml={'20px'} lineHeight='100%'>{'As Árvores são pontuadas de forma sequencial, ou seja, só é possível pontuar uma árvore se a anterior estiver pontuada'}</Text>
                                    <Text align={'start'} mt={'10px'}>{'Pontos:'}</Text>
                                    <Text align={'start'} mt={'0px'} ml={'20px'} lineHeight='100%'>{'Soma dos pontos cadastrados'}</Text>
                                </Box>
                            }
                            height={'20px'} />
                    </Center>
                </Flex>
                <Text color={textColor} fontSize={'15px'} fontWeight='light' align={'center'} lineHeight={'100%'}>{`Clique na árvore para editar`}</Text>
                <Wrap mt={'10px'} spacing='20px' justify={'space-between'} align={'center'}>
                    {arrayArvores.map((item, index) => {
                        const editavel = item.editavel == false ? false : true;
                        let corBackground = 'white';
                        if (item.arvoreChecada == true) {
                            corBackground = 'green';
                        } else if (item.editavel == true) {
                            corBackground = 'orange.200';
                        }
                        const totalPontosP = Number(item.totalPontos);
                        return (
                            <WrapItem key={index} w={'75px'} justifyContent={'center'} alignItems={'center'}>
                                <Box w={'100%'}>
                                    <Box pointerEvents={editavel ? 'auto' : 'none'} opacity={editavel ? '1' : '0.5'} w={'100%'}>
                                        <Box cursor={'pointer'} onClick={() => { irParaTelaModal(item) }} bg={corBackground} borderWidth={'3px'} borderColor={'blackAlpha.600'} borderRadius={'10px'}>
                                            <Flex py={'5px'} justify='space-around' align='center'>
                                                <Center h={'30px'} w={'30px'} bg={'white'} borderRadius={'15px'} justifyContent={'center'} alignItems={'center'}>
                                                    <Image h={'25px'} w={'25px'} src={ArvoreIcon} alt="img-r1" />
                                                </Center>
                                                <Text color={item.arvoreChecada ? 'white' : 'black'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'}>{item.numeroArvore}</Text>
                                            </Flex>
                                            <Flex mb={'2px'} justify='end' align='end'>
                                                <Icon as={IoArrowRedoSharp} width='25px' height='25px' color={item.arvoreChecada ? 'white' : 'orange.800'} />
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Flex justify='center' align='center'>
                                        {item.arvoreChecada == true ?
                                            <Flex flexDirection={'column'} justify='center' align='center'>
                                                <Text color={totalPontosP > 0 ? 'gray.900' : 'gray.300'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Pontos'}</Text>
                                                <Text color={totalPontosP > 0 ? 'gray.900' : 'gray.300'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{totalPontosP}</Text>
                                            </Flex>
                                            :
                                            <Flex flexDirection={'column'} justify='center' align='center'>
                                                <Text color={'transparent'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Pontos'}</Text>
                                                <Text color={'transparent'} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'0'}</Text>
                                            </Flex>
                                        }
                                    </Flex>
                                </Box>
                            </WrapItem>
                        )
                    })}
                </Wrap>
            </Box>
        )
    }

    function renderCards() {
        let ttpont = `${totalPontos}`;
        ttpont = ttpont.replace('.', ',');
        let notSerin = `${notaSeringueiro}`;
        notSerin = notSerin.replace('.', ',');
        return (
            <Box mt={'30px'}>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                            <Flex align={'center'} justify={'center'}>
                                <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'}>{'Total de pontos'}</Text>
                                <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                    <TooltipIcon
                                        label={
                                            <Box px={'0px'} py={'5px'} >
                                                <Text align={'center'}>{'Total de Pontos'}</Text>
                                                <Text align={'start'} mt={'10px'} lineHeight='100%'>{'Soma da pontuação das árvores'}</Text>
                                            </Box>
                                        }
                                        height={'20px'} />
                                </Center>
                            </Flex>
                            <Text color={textColor} fontSize={'20px'} fontWeight='bold' align={'center'}>{ttpont}</Text>
                        </Flex>
                    </Card>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                            <Flex align={'center'} justify={'center'}>
                                <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'}>{'Nota do seringueiro'}</Text>
                                <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                    <TooltipIcon
                                        label={
                                            <Box px={'0px'} py={'5px'} >
                                                <Text align={'center'}>{'Nota do seringueiro'}</Text>
                                                <Text align={'start'} mt={'10px'} lineHeight='100%'>{'Pontuação do seringueiro menos a soma da pontuação das árvores'}</Text>
                                            </Box>
                                        }
                                        height={'20px'} />
                                </Center>
                            </Flex>
                            <Text color={textColor} fontSize={'20px'} fontWeight='bold' align={'center'}>{`${notSerin}%`}</Text>
                        </Flex>
                    </Card>
                    <Card py='10px' boxShadow={cardShadow} bg={bg}>
                        <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} align={'center'}>Avaliação</Text>
                            <Select
                                value={avaliacaoSelecionado}
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={'blackAlpha.300'}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => setAvaliacaoSelecionado(e.target.value)}
                            >
                                {AVALIACAO_SANGRIA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Flex>
                    </Card>
                </SimpleGrid>
            </Box>
        )
    }

    function remderObservacoes() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'}>{'Observação'}</Text>
                    <Textarea
                        value={observacao}
                        onChange={(e) => { changeObservacao(e.target.value) }}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={'blackAlpha.300'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={2000}
                    />
                </Flex>
                <Text mt={'5px'} color={'gray.500'} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterObservacao}`}</Text>
            </Box>
        )
    }

    function renderUploadFile() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex justify='space-between' align='start'>
                    <Flex flexDirection={'column'} w={'48%'} justify={'start'} align={'start'}>
                        <Text mb={'10px'} color={textColor} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{`imagens ${qtdImages}`}</Text>
                        {listUrlImages.length > 0 ?
                            <Box w={'100%'}>
                                <Flex w={'100%'} justify='start' align='start' bg={'blackAlpha.200'} borderRadius={'10px'}>
                                    <Box textAlign="center">
                                        <Wrap p={'5px'} spacing='15px' justify={'start'} align={'center'}>
                                            {listUrlImages.map((image, index) => (
                                                <WrapItem
                                                    key={index}
                                                    boxSize="50px"
                                                    flex="0 0 auto"
                                                    borderRadius={'10px'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    borderWidth={currentIndex === index ? "0px" : "0px"}
                                                    borderColor={'red'}
                                                >
                                                    <Box position={'relative'}>
                                                        <Image
                                                            src={image.urlStorage}
                                                            alt={`Thumbnail ${index + 1}`}
                                                            boxSize={'48px'}
                                                            objectFit="cover"
                                                            borderRadius={'8px'}
                                                        />
                                                    </Box>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                        <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                            <ModalOverlay />
                                            <ModalContent maxW="600px" position="relative">
                                                <ModalCloseButton bg={'whiteAlpha.800'} borderWidth={'1px'} borderColor={'blackAlpha.800'} />
                                                <IconButton
                                                    aria-label="Previous Image"
                                                    icon={<ChevronLeftIcon />}
                                                    position="absolute"
                                                    left="0"
                                                    top="50%"
                                                    transform="translateY(-50%)"
                                                    onClick={prevImage}
                                                    zIndex={1}
                                                />
                                                <ModalBody p={0}>
                                                    <Image
                                                        src={listUrlImages[currentIndex].urlStorage}
                                                        alt={`Image ${currentIndex + 1}`}
                                                        h={'400px'}
                                                    />
                                                </ModalBody>
                                                <IconButton
                                                    aria-label="Next Image"
                                                    icon={<ChevronRightIcon />}
                                                    position="absolute"
                                                    right="0"
                                                    top="50%"
                                                    transform="translateY(-50%)"
                                                    onClick={nextImage}
                                                    zIndex={1}
                                                />
                                            </ModalContent>
                                        </Modal>
                                    </Box>
                                </Flex>
                            </Box>
                            :
                            <Flex pl={'10px'} minH={'60px'} w={'100%'} overflowX="auto" justify='center' align='center' bg={'blackAlpha.200'} borderRadius={'10px'}>
                                <Text color={'gray.400'} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Sem Imagens'}</Text>
                            </Flex>
                        }
                    </Flex>
                    <Flex flexDirection={'column'} w={'48%'} justify={'start'} align={'start'}>
                        <Text mb={'10px'} color={textColor} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{`Vídeos ${qtdVideos}`}</Text>
                        {listUrlVideos.length > 0 ?
                            <Box w={'100%'}>
                                <Flex w={'100%'} justify='start' align='start' bg={'blackAlpha.200'} borderRadius={'10px'}>
                                    <Box textAlign="center">
                                        <Wrap p={'5px'} spacing='15px' justify={'start'} align={'center'}>
                                            {listUrlVideos.map((item, index) => (
                                                <WrapItem
                                                    key={index}
                                                    boxSize="50px"
                                                    flex="0 0 auto"
                                                    borderRadius={'10px'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    onClick={() => { }}
                                                >
                                                    <Box>
                                                        <video
                                                            src={item.urlStorage}
                                                            controls={false}
                                                            width={'50'}
                                                        />
                                                    </Box>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </Box>
                                </Flex>
                            </Box>
                            :
                            <Flex pl={'10px'} minH={'60px'} w={'100%'} overflowX="auto" justify='center' align='center' bg={'blackAlpha.200'} borderRadius={'10px'}>
                                <Text color={'gray.400'} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Sem Vídeos'}</Text>
                            </Flex>
                        }
                    </Flex>
                </Flex>
                <Flex px={'10px'} mt={'10px'} justify='end' align='center'>
                    <Button variant="miniBrand" onClick={acessarGaleriaImagemAndFotos}>Acessar Galeria</Button>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Flex justify='center' align='center'>
                    <Button isDisabled={!avaliacaoFinalizadaP} variant="brand" onClick={acessarTelaDetalhes}>Ver detalhes</Button>
                    <Button isDisabled={!sangriaAtiva} variant="brand" onClick={() => { salvarQualidadeSangria(false) }} ml={'10px'}>Salvar Rascunho</Button>
                </Flex>
                <Button isDisabled={!sangriaAtiva} variant="brand" onClick={() => { setOpenModalDialog(true) }}>Salvar e Finalizar</Button>
            </Wrap>
        )
    }

    function renderVoltar() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {isVisibleAlert && renderAlert()}
            {renderMeses()}
            {renderTitulo()}
            <Card px={'10px'} py={'10px'} boxShadow={cardShadowObs}>
                <Box py={'20px'}>
                    {renderInformacoes()}
                    {remderArvores()}
                    {renderCards()}
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px' mt={'30px'}>
                        {remderObservacoes()}
                        {renderUploadFile()}
                    </SimpleGrid>
                </Box>
                {renderButton()}
            </Card>
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { salvarQualidadeSangria(true) }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={'Salvar e finalizar qualidade da sangria?'} />}
            {renderVoltar()}
        </Box>
    );
}
