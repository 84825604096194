import {
    Flex, Text, useColorModeValue, Button, Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { RiArrowRightUpLine } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';

const ListaSeringueiro = () => {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listaPessoa, setListaPessoa] = useState([]);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [idUsuarioP, setIdUsuarioP] = useState('');
    const [idUaP, setIdUaP] = useState('');
    const [idSafraP, setIdSafraP] = useState('');
    const [dtFimP, setDtFimP] = useState('');
    const [dtInicioP, setDtInicioP] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';

    useEffect(() => {
        async function loaderScreen() {

            const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
            setIdUsuarioP(idUsuarioLogado);

            const nomeUa = localStorage.getItem('@HE-nomeUa');
            setNomeFazenda(nomeUa);

            const dtInicio = localStorage.getItem('@HE-dtInicioSafraAsync');
            const dtFim = localStorage.getItem('@HE-dtFimSafraAsync');
            setDtInicioP(dtInicio);
            setDtFimP(dtFim);

            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            setIdUaP(idUa);
            setIdSafraP(idSafra);

            const idTipoUsuarioLogado = localStorage.getItem('@HE-idTipoUsuarioLogado');
            if (idTipoUsuarioLogado == '5') {
                const objPe = await pesquisarPessoaPorIdUsuario(idUsuarioLogado);
                const idPessoaU = objPe != null ? objPe.idPessoa : 0;
                await pesquisarPessoas(idUa, idSafra, idPessoaU);
                setTelaCarregada(true);
            } else {
                await pesquisarPessoas(idUa, idSafra, 0);
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const pesquisarPessoaPorIdUsuario = async (idUsuario = 0) => {
        let result = null;
        try {
            const url = `api/pessoa/pesquisaPessoaPorIdUsuario/${idUsuario}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                result = res[0];
            }
        } catch (error) {
            result = null;
        }
        return result;
    }

    const pesquisarPessoas = async (idUa = 0, idSafra = 0, idPessoa = 0) => {
        const url = `api/pessoa/listaPessoaParaContratacaoEmUmaUaAndSafra?idUa=${idUa}&idSafra=${idSafra}&idPessoa=${idPessoa}`;
        const response = await Api.getRequest(url);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

            const res = await response.respostaRequisicao.data;
            const arrayPessoa = res.listPessoaParaContratacao;

            setListaPessoa(arrayPessoa);
            setTelaCarregada(true);
        }
    }

    const acessarExtrato = (item) => {
        const jsonParam = {
            idUa: idUaP,
            idUsuario: idUsuarioP,
            idSafra: idSafraP,
            nomeFazenda: nomeFazenda,
            dtInicio: dtInicioP,
            dtFim: dtFimP,
            idPessoa: item.idPessoa,
        }
        localStorage.setItem('@HE-datosParaExtratoSeringueiro', JSON.stringify(jsonParam));
        history.push("/admin/historico-seringueiro");
    }

    if (!telaCarregada) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Text mb={'10px'} color={textColor} fontSize='18px' fontWeight={'bold'} align={'center'}>Lista Seringueiros</Text>
            <Box mx={'10px'}>
                <TableContainer borderRadius={'10px'} borderWidth={'1px'}>
                    <Table size="sm" variant='striped' colorScheme='blackAlpha'>
                        <Thead px={'30px'} >
                            <Tr h={'30px'} bgGradient={gradientHeader}>
                                <Th w={'50%'} color={textColorHeader}>Nome</Th>
                                <Th w={'40%'} color={textColorHeader}>Tipo</Th>
                                <Th w={'10%'} ></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listaPessoa.map((item, index) => {
                                const deletado = item.deletado != null ? item.deletado : false;
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Box>
                                                <Flex justify={'start'} align='center'>
                                                    <Text color={textColor} fontSize='14px'>{item.nome}</Text>
                                                    {deletado && <Text px={'5px'} py={'2px'} ml={'10px'} borderRadius={'8px'} bg={'red.600'} color={'white'} fontSize='14px'>{'Desativado'}</Text>}
                                                </Flex>
                                            </Box>
                                        </Td>
                                        <Td>{'Seringueiro'}</Td>
                                        <Td >
                                            <Flex align='center'>
                                                <Button
                                                    size='sm'
                                                    color={'blue.500'}
                                                    rightIcon={<RiArrowRightUpLine size={'25px'} />}
                                                    variant="solid"
                                                    key={index}
                                                    onClick={() => { acessarExtrato(item) }}
                                                >
                                                    Extrato
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Card>
    );
};

export default ListaSeringueiro;
