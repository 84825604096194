
export const CHUVA_NOS_DIAS = [
    {
        data: "2021-11-13",
        diasSemana: "Quinta-feitra"
    },
    {
        data: "2021-11-18",
        diasSemana: "Sexta-feitra"
    },
    {
        data: "2021-11-22",
        diasSemana: "Segunda-feitra"
    },
    {
        data: "2021-12-01",
        diasSemana: "Sábado"
    },
    {
        data: "2021-12-15",
        diasSemana: "Quinta-feitra"
    },
    {
        data: "2021-12-23",
        diasSemana: "Quarta-feitra"
    },
    {
        data: "2022-01-02",
        diasSemana: "Quinta-feitra"
    },
    {
        data: "2022-01-16",
        diasSemana: "Domingo"
    },
    {
        data: "2022-01-24",
        diasSemana: "Terça-feitra"
    }
]

export const DATA_CHECK = [
    {
        name: ["Horizon UI PRO", false],
        quantity: 2458,
        date: "12 Jan 2021",
        progress: 17.5
    },
    {
        name: ["Horizon UI Free", true],
        quantity: 1485,
        date: "21 Feb 2021",
        progress: 10.8
    },
    {
        name: ["Weekly Update", true],
        quantity: 1024,
        date: "13 Mar 2021",
        progress: 21.3
    },
    {
        name: ["Venus 3D Asset", true],
        quantity: 858,
        date: "24 Jan 2021",
        progress: 31.5
    },
    {
        name: ["Marketplace", false],
        quantity: 258,
        date: "24 Oct 2022",
        progress: 12.2
    }
]

export const DATA_COMPLEX = [
    {
        name: "Horizon UI PRO",
        status: "Approved",
        date: "18 Apr 2022",
        progress: 75.5
    },
    {
        name: "Horizon UI Free",
        status: "Disable",
        date: "18 Apr 2022",
        progress: 25.5
    },
    {
        name: "Marketplace",
        status: "Error",
        date: "20 May 2021",
        progress: 90
    },
    {
        name: "Weekly Updates",
        status: "Approved",
        date: "12 Jul 2021",
        progress: 50.5
    }
]

export const SANGRIAS = [
    {
        seringueiro: "Claudecir e Luciene",
        diasUteis: 75,
        chuvaFeriado: 10,
        possiveis: 20,
        faltas: 1,
        realiazadas: 50,
        devendo: 3
    },
    {
        seringueiro: "Daniel",
        diasUteis: 35,
        chuvaFeriado: 10,
        possiveis: 70,
        faltas: 4,
        realiazadas: 30,
        devendo: 2
    },
    {
        seringueiro: "Enilson",
        diasUteis: 25,
        chuvaFeriado: 10,
        possiveis: 40,
        faltas: 7,
        realiazadas: 20,
        devendo: 9
    },
    {
        seringueiro: "J.Augusto",
        diasUteis: 74,
        chuvaFeriado: 10,
        possiveis: 30,
        faltas: 2,
        realiazadas: 40,
        devendo: 6
    },
    {
        seringueiro: "José Eduardo e Evania",
        diasUteis: 47,
        chuvaFeriado: 10,
        possiveis: 50,
        faltas: 2,
        realiazadas: 36,
        devendo: 6
    },
    {
        seringueiro: "Jailton e Patricia",
        diasUteis: 96,
        chuvaFeriado: 10,
        possiveis: 18,
        faltas: 2,
        realiazadas: 29,
        devendo: 6
    },
    {
        seringueiro: "Felipe e Michele",
        diasUteis: 38,
        chuvaFeriado: 10,
        possiveis: 23,
        faltas: 2,
        realiazadas: 42,
        devendo: 6
    },
    {
        seringueiro: "Benedito e Ana Paula",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 09",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 10",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 11",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 12",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 13",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    }
]

export const SANGRIA_POR_ANO = [
    {
        ano: "2022",
        tarefasSangraveis: 75,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 20,
        eficiencia: 1,
        chuvas: 50,
        tarefasSemJustificativas: 3
    },
    {
        ano: "2022",
        tarefasSangraveis: 35,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 70,
        eficiencia: 4,
        chuvas: 30,
        tarefasSemJustificativas: 2
    },
    {
        ano: "2022",
        tarefasSangraveis: 25,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 40,
        eficiencia: 7,
        chuvas: 20,
        tarefasSemJustificativas: 9
    },
    {
        ano: "2022",
        tarefasSangraveis: 74,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 30,
        eficiencia: 2,
        chuvas: 40,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 47,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 50,
        eficiencia: 2,
        chuvas: 36,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 96,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 18,
        eficiencia: 2,
        chuvas: 29,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 38,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 23,
        eficiencia: 2,
        chuvas: 42,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 99,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 26,
        eficiencia: 2,
        chuvas: 38,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 99,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 26,
        eficiencia: 2,
        chuvas: 38,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 99,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 26,
        eficiencia: 2,
        chuvas: 38,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 99,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 26,
        eficiencia: 2,
        chuvas: 38,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 99,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 26,
        eficiencia: 2,
        chuvas: 38,
        tarefasSemJustificativas: 6
    },
    {
        ano: "2022",
        tarefasSangraveis: 99,
        tarefasSangradas: 10,
        tarefasNaoRecuperadas: 26,
        eficiencia: 2,
        chuvas: 38,
        tarefasSemJustificativas: 6
    }
]


export const ACOMPANHAMENTO_SANGRIAS_DIARIAS = [
    {
        seringueiro: "Claudecir e Luciene",
        diasUteis: 75,
        chuvaFeriado: 10,
        possiveis: 20,
        faltas: 1,
        realiazadas: 50,
        devendo: 3
    },
    {
        seringueiro: "Daniel",
        diasUteis: 35,
        chuvaFeriado: 10,
        possiveis: 70,
        faltas: 4,
        realiazadas: 30,
        devendo: 2
    },
    {
        seringueiro: "Enilson",
        diasUteis: 25,
        chuvaFeriado: 10,
        possiveis: 40,
        faltas: 7,
        realiazadas: 20,
        devendo: 9
    },
    {
        seringueiro: "J.Augusto",
        diasUteis: 74,
        chuvaFeriado: 10,
        possiveis: 30,
        faltas: 2,
        realiazadas: 40,
        devendo: 6
    },
    {
        seringueiro: "José Eduardo e Evania",
        diasUteis: 47,
        chuvaFeriado: 10,
        possiveis: 50,
        faltas: 2,
        realiazadas: 36,
        devendo: 6
    },
    {
        seringueiro: "Jailton e Patricia",
        diasUteis: 96,
        chuvaFeriado: 10,
        possiveis: 18,
        faltas: 2,
        realiazadas: 29,
        devendo: 6
    },
    {
        seringueiro: "Felipe e Michele",
        diasUteis: 38,
        chuvaFeriado: 10,
        possiveis: 23,
        faltas: 2,
        realiazadas: 42,
        devendo: 6
    },
    {
        seringueiro: "Benedito e Ana Paula",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 09",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 10",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 11",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 12",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    },
    {
        seringueiro: "Fulano 13",
        diasUteis: 99,
        chuvaFeriado: 10,
        possiveis: 26,
        faltas: 2,
        realiazadas: 38,
        devendo: 6
    }
]


export const ARRAY_MESES_SAFRA_SANGRIA = [
    {
        idUa: 22,
        idSafra: 20,
        idPessoa: null,
        nomePessoa: null,
        totalQtdTarefasSangraveis: "0",
        totalQtdTarefasSangradas: "0",
        totalQtdTarefasNaoRecuperadas: "0",
        totalTarefasNaoSangradasPorChuva: "0",
        totalPercentPossiveisRealizadas: "0",
        totalPercentIndiceficiencia: "0",
        totalDiasNaoSangradosPorChuva: "0",
        totalTotalFaltaslicencas: "0",
        totalPercentDeAusenciasEmRelacaoAoTotal: "0",
        totaltotalAtrasadas: "0",
        listaMesesDaSafra: [
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "SETEMBRO",
                realizadas: null,
                devendo: null,
                dtInicio: "2022-09-01",
                dtFim: "2022-09-30"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "OUTUBRO",
                realizadas: null,
                devendo: null,
                dtInicio: "2022-10-01",
                dtFim: "2022-10-31"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "NOVEMBRO",
                realizadas: null,
                devendo: null,
                dtInicio: "2022-11-01",
                dtFim: "2022-11-30"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "DEZEMBRO",
                realizadas: null,
                devendo: null,
                dtInicio: "2022-12-01",
                dtFim: "2022-12-31"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "JANEIRO",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-01-01",
                dtFim: "2023-01-31"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "FEVEREIRO",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-02-01",
                dtFim: "2023-02-28"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "MARCO",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-03-01",
                dtFim: "2023-03-31"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "ABRIL",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-04-01",
                dtFim: "2023-04-30"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "MAIO",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-05-01",
                dtFim: "2023-05-31"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "JUNHO",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-06-01",
                dtFim: "2023-06-30"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "JULHO",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-07-01",
                dtFim: "2023-07-31"
            },
            {
                qtdTarefasSangraveis: "0",
                qtdTarefasSangradas: "0",
                qtdTarefasNaoRecuperadas: "0",
                percentualIndiceEficiencia: "0",
                percentualSangriasPossiveisRealizadas: "0",
                diasNaoSangradosPorChuva: "0",
                faltas: "0",
                tarefasPendentes: "0",
                diasPossiveisPeriodo: "0",
                atrasadas: "0",
                chuvaFeriado: "0",
                faltasPorMotivoDeChuva: "0",
                percentualDeAusenciasEmRelacaoAoTotal: "0",
                mes: "AGOSTO",
                realizadas: null,
                devendo: null,
                dtInicio: "2023-08-01",
                dtFim: "2023-08-31"
            }
        ]
    }
]


export const MESES_SANGRIA = [
    {numeroMes: 9,  status: true, dataInicio: "2022-09-01", dataFim: "2022-09-30", mes: "SETEMBRO"},
    {numeroMes: 10, status: true, dataInicio: "2022-10-01", dataFim: "2022-10-31", mes: "OUTUBRO"},
    {numeroMes: 11, status: true, dataInicio: "2022-11-01", dataFim: "2022-11-30", mes: "NOVEMBRO"},
    {numeroMes: 12, status: true, dataInicio: "2022-12-01", dataFim: "2022-12-31", mes: "DEZEMBRO"},
    {numeroMes: 1,  status: true, dataInicio: "2023-01-01", dataFim: "2023-01-31", mes: "JANEIRO"},
    {numeroMes: 2,  status: true, dataInicio: "2023-02-01", dataFim: "2023-02-29", mes: "FEVEREIRO"},
    {numeroMes: 3,  status: true, dataInicio: "2023-03-01", dataFim: "2023-03-31", mes: "MARCO"},
    {numeroMes: 4,  status: true, dataInicio: "2023-04-01", dataFim: "2023-04-30", mes: "ABRIL"},
    {numeroMes: 5,  status: true, dataInicio: "2023-05-01", dataFim: "2023-05-31", mes: "MAIO"},
    {numeroMes: 6,  status: true, dataInicio: "2023-06-01", dataFim: "2023-06-30", mes: "JUNHO"},
    {numeroMes: 7,  status: true, dataInicio: "2023-07-01", dataFim: "2023-07-31", mes: "JULHO"},
    {numeroMes: 8,  status: true, dataInicio: "2023-08-01", dataFim: "2023-08-31", mes: "AGOSTO"},
]

export const ARRAY_MES_E_DATA = [
    { dtMes: '2022-09-01', mes: 'SETEMBRO', status: true },
    { dtMes: '2022-10-01', mes: 'OUTUBRO', status: true },
    { dtMes: '2022-11-01', mes: 'NOVEMBRO', status: true },
    { dtMes: '2022-12-01', mes: 'DEZEMBRO', status: true },
    { dtMes: '2023-01-01', mes: 'JANEIRO', status: true },
    { dtMes: '2023-02-01', mes: 'FEVEREIRO', status: true },
    { dtMes: '2023-03-01', mes: 'MARCO', status: true },
    { dtMes: '2023-04-01', mes: 'ABRIL', status: true },
    { dtMes: '2023-05-01', mes: 'MAIO', status: true },
    { dtMes: '2023-06-01', mes: 'JUNHO', status: true },
    { dtMes: '2023-07-01', mes: 'JULHO', status: true },
    { dtMes: '2023-08-01', mes: 'AGOSTO', status: true },
]

export const ARRAY_MESES_SAFRA_PRODUCAO = [
    {
        idUa: 22,
        idSafra: 20,
        idPessoa: null,
        nomePessoa: null,
        totalArvoresSangradasString: "0",
        totalDistribuicaoString: "100",
        totalPrevistoString: "0",
        totalRealizadoString: "0",
        totalPercentualString: "0",
        coaguloPrevisto: "0",
        coaguloRealizado: "0",
        objetivoAlcancado: "0",
        saldoAcumulado: "0",
        listMeses: [
            {
                mes: "SETEMBRO",
                distribuicao: "0",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "OUTUBRO",
                distribuicao: "3,8",
                arvoresSangradasString: "0",
                previstoString: "",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "NOVEMBRO",
                distribuicao: "5,8",
                arvoresSangradasString: "0",
                previstoString: "",
                realizadoString: "",
                percentualString: ""
            },
            {
                mes: "DEZEMBRO",
                distribuicao: "5,8",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "JANEIRO",
                distribuicao: "8,3",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "FEVEREIRO",
                distribuicao: "10",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "MARCO",
                distribuicao: "11,7",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "ABRIL",
                distribuicao: "14,6",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "MAIO",
                distribuicao: "14,6",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "JUNHO",
                distribuicao: "13,4",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "JULHO",
                distribuicao: "10,9",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "AGOSTO",
                distribuicao: "1",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            }
        ]
    }
]
