import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { WarningTwoIcon } from '@chakra-ui/icons';

const TooltipSeringueiro = ({ label = '', height = '23px', color = 'orange.600' }) => {

    const GRADIENTE = 'linear-gradient(to bottom, #1a1a1a, #333333, #595959)';

    return (
        <Tooltip label={label} borderRadius={'10px'} size="sm" bgGradient={GRADIENTE}>
            <WarningTwoIcon w={height} h={height} color={color} />
        </Tooltip>
    );
};

export default TooltipSeringueiro;
