import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Icon,
    Input,
    Checkbox,
    Wrap,
    WrapItem,
    Select,
    Center,
    TableContainer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { RiCheckboxBlankCircleFill, RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../../constants/ActionTypes';
import TooltipIcon from 'components/tooltip/TooltipIcon';

const qtdPage = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
];

export default function LogProgramacao() {

    const [listaLogProgramacao, setListaLogProgramacao] = useState([]);
    const [checkSucesso, setCheckSucesso] = useState(false);
    const [checkErro, setCheckErro] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());
    const [uaSelecionada, setUaSelecionada] = useState('');
    const [listaUa, setListaUa] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const inputBg = useColorModeValue("white", "navy.700");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const shadowBlack = ('0px 5px 10px 2px rgba(0, 0, 0, 0.30)');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dataAtual = moment().format('YYYY-MM-DD');
            const DataAtualMenosUm = moment(dataAtual).subtract(3, 'days').format('YYYY-MM-DD');

            setDataInicio(DataAtualMenosUm);
            setDataFim(dataAtual);

            await pesquisar(DataAtualMenosUm, dataAtual);
        }
        loaderScreen();
    }, [])

    const pesquisar = async (dataInicioP, dataFimP) => {
        try {
            setListaLogProgramacao([]);
            setCurrentPage(1);

            const idUaP = uaSelecionada != null && uaSelecionada != '' ? uaSelecionada : '0';
            const url = `api/programacao/listaLogsDoProcessoDeProgramacao?idUa=${idUaP}&comSucesso=${checkSucesso}&comErro=${checkErro}&dtInicio=${dataInicioP}&dtFim=${dataFimP}`
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setListaLogProgramacao(res.listaLogProgramacaoList);

                const listUaP = res.listaUa;

                if (listUaP.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            listUaP.map(function (item) {
                                const obj = {
                                    label: item.nomeFazenda,
                                    value: `${item.idUa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListaUa(arrayPess);
                }
            }
        } catch (error) {
            console.log('');
        }
    };

    const totalPages = Math.ceil(listaLogProgramacao.length / itemsPerPage);
    const paginatedData = listaLogProgramacao.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo(0, 0);
    };

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    function renderFiltro() {
        return (
            <Box w={'100%'} mb={'20px'} >
                <Box px={'25px'}>
                    <Flex justify='start' mb='5px' align='center'>
                        <TooltipIcon
                            label={
                                <Box py={'5px'}>
                                    <Text align={'center'}>{'Listar as safras que processaram'}</Text>
                                    <Text align={'center'}>{'com sucesso'}</Text>
                                </Box>
                            }
                            height={'20px'} />
                        <Checkbox mx={'10px'} size='lg' colorScheme='green' isChecked={checkSucesso} onChange={() => { setCheckSucesso(!checkSucesso) }} />
                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' >Processado</Text>
                    </Flex>
                    <Flex justify='start' mb='20px' align='center'>
                        <TooltipIcon
                            label={
                                <Box py={'5px'}>
                                    <Text lineHeight='100%' align={'center'}>{'Listar as não processadas'}</Text>
                                    <Text lineHeight='100%' align={'center'} mb={'15px'}>{'Isso pode ocorrer por vários motivos:'}</Text>
                                    <Text lineHeight='100%'>{'- A safra está inativa'}</Text>
                                    <Text lineHeight='100%'>{'- A data de início da sangria está depois da data atual, ainda não iniciou'}</Text>
                                    <Text lineHeight='100%'>{'- Safra com período de vigência finalizado'}</Text>
                                    <Text lineHeight='100%' mb={'15px'}>{'- Data de início da sangria não foi cadastrada'}</Text>
                                    <Text lineHeight='100%'>{'clique na Propriedade para mais detalhes'}</Text>
                                </Box>
                            }
                            height={'20px'} />
                        <Checkbox mx={'10px'} size='lg' colorScheme='red' isChecked={checkErro} onChange={() => { setCheckErro(!checkErro) }} />
                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>Não Processado</Text>
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderDatas() {
        return (
            <Wrap spacing='10px' mb={'5px'} justify='flex-start' p={'1'} mx={'10px'}>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'}>Início</Text>
                        <Input
                            value={dataInicio}
                            variant='filled'
                            onChange={(e) => setDataInicio(e.target.value)}
                            placeholder=""
                            bg={inputBg}
                            color={inputText}
                            borderColor={textColor}
                            borderRadius="10px"
                            size="md"
                            type="date"
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            onKeyDown={(e) => { e.preventDefault() }}
                        />
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' >
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'}>Fim</Text>
                        <Input
                            value={dataFim}
                            variant='filled'
                            onChange={(e) => setDataFim(e.target.value)}
                            placeholder=""
                            bg={inputBg}
                            color={inputText}
                            borderColor={textColor}
                            borderRadius="10px"
                            size="md"
                            type="date"
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            onKeyDown={(e) => { e.preventDefault() }}
                        />
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' >
                        <Flex px='25px' justify='space-between' align='center'>
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>Propriedades</Text>
                        </Flex>
                        <Select
                            value={uaSelecionada}
                            errorBorderColor='crimson'
                            placeholder="Todos"
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={(e) => setUaSelecionada(e.target.value)}
                            w={'400px'}
                            h={'35px'}
                            ml={'15px'}
                        >
                            {listaUa.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' h={'100%'}>
                        <Center alignItems={'flex-end'} h={'100%'}>
                            <Button variant="brand" onClick={() => { pesquisar(dataInicio, dataFim) }}>Pesquisar</Button>
                        </Center>
                    </Box>
                </WrapItem>
            </Wrap>
        )
    }

    function renderTable() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        return (
            <Flex px={'10px'} direction="column" align="center" >
                <TableContainer w={'100%'}>
                    <Table whiteSpace={'break-spaces'} size="sm">
                        <Thead>
                            <Tr>
                                <Th w={'100%'} color={'white'} bg={'white'}>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {paginatedData.map((item, index) => {
                                const qtdSucesso = item.qtdSucesso != null ? item.qtdSucesso : 0;
                                const qtdDias = item.listaLogProgramacaoItem.length != null ? item.listaLogProgramacaoItem.length : 0;
                                const p1 = Math.round((qtdSucesso / qtdDias) * 100);
                                const percent = Number.isNaN(p1) ? '0' : Number(p1).toFixed(0);
                                return (
                                    <Tr key={index} >
                                        <Td>
                                            <Accordion allowToggle mb={'5px'}>
                                                <AccordionItem
                                                    borderWidth={'0px'}
                                                    boxShadow={shadowBlack}
                                                    borderRadius={'10px'} >
                                                    <h2>
                                                        <AccordionButton _focus={{ boxShadow: 'none' }}>
                                                            <Box flex='1' textAlign='left' >
                                                                <Flex justify='space-between' mb='5px' align='center'>
                                                                    <Box w={'75%'} >
                                                                        <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight='100%'>{item.nomeFazenda}</Text>
                                                                    </Box>
                                                                    <Box w={'20%'}>
                                                                        <Flex justify='center' align='center'>
                                                                            <Box borderRadius={'8px'} borderWidth={'0px'} borderColor={'green.500'} bg={'gray.200'}>
                                                                                <Text mx={'10px'} my={'4px'} color={'green.900'} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{`${percent}%`}</Text>
                                                                            </Box>
                                                                        </Flex>
                                                                    </Box>
                                                                </Flex>
                                                            </Box>
                                                            <AccordionIcon w={'25px'} h={'25px'} />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={4}>
                                                        <Box>
                                                            {item.listaLogProgramacaoItem.map((rr, ww) => {
                                                                let textoStatus = rr.programacaoGerada ? 'Sucesso' : 'Não Gerarda';
                                                                let corStatusButton = rr.programacaoGerada ? 'green' : 'orange';
                                                                const dtCadastro = rr.dtCadastro != null ? moment(rr.dtCadastro).format('DD/MM/YYYY') : '';
                                                                return (
                                                                    <Box key={ww} mb={'20px'}>
                                                                        <Flex justify='start' mb='5px' align='center'>
                                                                            <Box w={'100px'} px={'10px'} py={'3px'} borderWidth={'1px'} borderRadius={'8px'} bg={'gray.200'}>
                                                                                <Text color={textColor} fontSize='14px' fontWeight={'bold'}>{dtCadastro}</Text>
                                                                            </Box>
                                                                            <Box>
                                                                                <Text ml={'15px'} color={corStatusButton} fontSize='16px' fontWeight={'bold'}>{textoStatus}</Text>
                                                                            </Box>
                                                                        </Flex>
                                                                        <Box>
                                                                            {rr.listTipoLog.map((x, y) => {
                                                                                return (
                                                                                    <Box key={y}>
                                                                                        <Flex justify='start' mb='5px' align='center' >
                                                                                            <Icon as={RiCheckboxBlankCircleFill} color={corStatusButton} w={'15px'} h={'15px'} mr={'10px'} />
                                                                                            <Text color={textColor} fontSize='15px' fontWeight={'bold'}>{x.descricao}</Text>
                                                                                        </Flex>
                                                                                    </Box>
                                                                                )
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                    <Flex justify='center' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                        <Box ml={'10px'}>
                            <Select
                                value={itemsPerPage}
                                placeholder=""
                                variant="filled"
                                borderRadius="10px"
                                borderColor={'gray.200'}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}    
                                size="sm"
                                h={'35px'}
                                onChange={changePage}>
                                {qtdPage.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaLogProgramacao.length}`}</Text>
                        <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                        <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Detalhamento dos processos diário de programação</Text>
                {renderFiltro()}
                {renderDatas()}
                {renderTable()}
            </Box>
        </Card>
    );
};
