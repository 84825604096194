import React, { useState, useEffect, useRef } from "react";
import {
    Flex,
    Box,
    Text,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
    SimpleGrid,
    useColorModeValue,
    Button,
    Wrap,
    WrapItem,
    Input,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import ImageModal from 'components/gallery/ImageModal';
import VideoModal from 'components/gallery/VideoModal';
import VideoModalUpload from 'components/gallery/VideoModalUpload';
import ModalDialog from 'components/modal/ModalDialog';
import { IoAddCircleSharp } from "react-icons/io5";
import { UPLOAD_FILE_AND_URL_VERSAO_FINAL } from '../../../../util/SalvarImagem';
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';

export default function GalleryImageAndVideo() {

    const history = useHistory();
    const inputRef = useRef(null);
    const videoRef = useRef(null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [numMesP, setNumMesP] = useState(0);
    const [nomeSeringueiro, setnomeSeringueiro] = useState('');
    const [idQualidadeSangriaP, setIdQualidadeSangriaP] = useState(null);
    const [idPessoaP, setIdPessoaP] = useState('');
    const [idSafraP, setIdSafraP] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalDialog, setOpenModalDialog] = useState(false);
    // IMAGEM
    const [showImageModal, setShowImageModal] = useState(false);
    const [fileImageModal, setFileImageModal] = useState(null);
    const [arquivoImagem, setArquivoImagem] = useState(null);
    const [listUrlImages, setListUrlImages] = useState([]);
    const [qtdImages, setQtdImages] = useState(0);
    // VIDEO
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showVideoModalUpload, setShowVideoModalUpload] = useState(false);
    const [listUrlVideos, setListUrlVideos] = useState([]);
    const [videoSrc, setVideoSrc] = useState(null);
    const [arquivoVideo, setArquivoVideo] = useState(null);
    const [messageVideo, setMessageVideo] = useState('');
    const [qtdVideos, setQtdVideos] = useState(0);

    const [isValidMaxSizeFile, setIsValidMaxSizeFile] = useState(false);
    const [isTamanhoMaximoVideo, setIsTamanhoMaximoVideo] = useState(false);

    const [messageArryUpload, setMessageArryUpload] = useState('');
    const [numeroDaUltimaImagemRegistrada, setNumeroDaUltimaImagemRegistrada] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [idUrldArquivoTemporario, setIdUrldArquivoTemporario] = useState(0);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            await carregarInformacoesDaTela();
            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            setListUrlImages([]);
            setListUrlVideos([]);
        }
    }, []);

    async function carregarInformacoesDaTela() {
        try {
            const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
            setSangriaAtiva(sangriaAti == 'true' ? true : false);

            const Param2 = localStorage.getItem('@HE-objUrlImagensAndVideosTelaGaleria');
            const dadosSeringueiro = JSON.parse(Param2);

            const idPessoa = dadosSeringueiro.idPessoa;
            const idQualidade = dadosSeringueiro.idQualidadeSangria;
            const dataSelecionadaNaTelaDeQualidade = dadosSeringueiro.mesSelecionado;

            setnomeSeringueiro(dadosSeringueiro.nome);
            setIdPessoaP(idPessoa);
            setIdQualidadeSangriaP(idQualidade);


            const ano = moment().format('YYYY');
            setAnoCorrente(ano);
            const numMes = moment().endOf('month').format("MM");
            setNumMesP(numMes);

            const primeiroDiaDoMesCorrente = moment(dataSelecionadaNaTelaDeQualidade).startOf('month').format('YYYY-MM-DD');
            const UltimoDiaDoMesCorrente = moment(dataSelecionadaNaTelaDeQualidade).endOf('month').format("YYYY-MM-DD");
            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            setIdSafraP(idSafra);

            const url = `api/urlArquivo/listaUrlParaUmaQualidadeSangria?idQualidadeSangria=${idQualidade}&idPessoa=${idPessoa}&dtInicio=${primeiroDiaDoMesCorrente}&dtFim=${UltimoDiaDoMesCorrente}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                const listUrlImagens = res.listUrlImagens;
                const listUrlVideos = res.listUrlVideos;
                const numeroDaUltimaImagemRegistrada = res.numeroDaUltimaImagemRegistrada;

                setListUrlImages(listUrlImagens);
                setListUrlVideos(listUrlVideos);
                setNumeroDaUltimaImagemRegistrada(numeroDaUltimaImagemRegistrada);
                setQtdImages(listUrlImagens.length);
                setQtdVideos(listUrlVideos.length);

            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            console.warn('Error na classe QualidadeSangria metodo carregarInformacoesDaTela ', error);
        }
    }

    // VIDEOS
    const changeCarregarVideo = async (event) => {
        const file = event.target.files[0];
        setIsTamanhoMaximoVideo(false);

        if (file) {
            const fileSizeInMB = await file.size / (1024 * 1024);
            if (fileSizeInMB < 20) {

                const url = URL.createObjectURL(file);
                setArquivoVideo(file);
                setVideoSrc(url);
                setShowVideoModalUpload(true);
            } else {
                setIsTamanhoMaximoVideo(true);
                setMessageVideo('Vídeo maior que 20 MB');
            }
        }
    };
    async function salvarVideoUpload() {
        setShowVideoModalUpload(false);

        const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
        let count = numeroDaUltimaImagemRegistrada + 1;

        const jsonObj = {
            idUsuario: idUsuarioLogado,
            idPessoa: idPessoaP,
            idQualidadeSangria: idQualidadeSangriaP,
            idTipoAnexo: ActionTypes.ID_TIPO_ANEXO_VIDEO,
            numeroImage: count,
            idSafra: idSafraP,
            img: arquivoVideo,
        }

        const response = await UPLOAD_FILE_AND_URL_VERSAO_FINAL(jsonObj);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

            setArquivoVideo(null);
            await carregarInformacoesDaTela();

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }
    const closeModalVideoUpload = () => {
        setShowVideoModalUpload(false);
        setVideoSrc(null);
    }
    const closeModalVideo = () => {
        setShowVideoModal(false);
        setVideoSrc(null);
    }
    const handleVideoClick = () => {
        videoRef.current.click();
    };
    const abrirIVideoModal = (item) => {
        setShowVideoModal(true);
        setVideoSrc(item.urlStorage);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setIsValidMaxSizeFile(false);

        if (file) {
            const fileSizeInMB = await file.size / (1024 * 1024); // CONVERT FILE SIZE TO MB
            if (fileSizeInMB < 1) {                               // VERIFY FILE SIZE IS LESS THAN 20 MB

                const imageUrl = URL.createObjectURL(file);
                setArquivoImagem(file);
                setFileImageModal(imageUrl);
                setShowImageModal(true);

            } else {
                setIsValidMaxSizeFile(true);
                setMessageArryUpload('Imagem maior que 10 MB');
            }
        } else {

        }
    }

    async function salvarImagens() {
        setShowImageModal(false);

        const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
        let count = numeroDaUltimaImagemRegistrada + 1;

        const jsonObj = {
            idUsuario: idUsuarioLogado,
            idPessoa: idPessoaP,
            idQualidadeSangria: idQualidadeSangriaP,
            idTipoAnexo: ActionTypes.ID_TIPO_ANEXO_IMAGEM,
            numeroImage: count,
            idSafra: idSafraP,
            img: arquivoImagem,
        }
        const response = await UPLOAD_FILE_AND_URL_VERSAO_FINAL(jsonObj);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

            setFileImageModal(null);
            setArquivoImagem(null);
            await carregarInformacoesDaTela();

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const closeModalImagem = () => {
        setShowImageModal(false);
        setFileImageModal(null);
        setArquivoImagem(null);
    }
    const handleClick = () => {
        inputRef.current.click();
    };
    const nextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === listUrlImages.length - 1 ? 0 : prevIndex + 1
        );
    };
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? listUrlImages.length - 1 : prevIndex - 1
        );
    };
    const handleImageClickCarrossel = (index) => {
        setCurrentIndex(index);
        onOpen();
    };
    const organizarExclusaoDeArquivo = (item) => {
        setIdUrldArquivoTemporario(item.idUrldArquivo);
        setOpenModalDialog(true);
    }

    const inserirFlagDeletado = async () => {

        setOpenModalDialog(false);
        const json = {
            idUrldArquivo: idUrldArquivoTemporario,
        }
        const url = `api/urlArquivo/gravarFlagDeArquivoDeletado`;
        const response = await Api.putRequest(url, json);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const arrayFil = listUrlImages.filter(x => x.idUrldArquivo != idUrldArquivoTemporario);
            setListUrlImages(arrayFil);

            const arrayVid = listUrlVideos.filter(x => x.idUrldArquivo != idUrldArquivoTemporario);
            setListUrlVideos(arrayVid);

        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 9000);
    }

    function renderTitulo() {
        return (
            <Box>
                <Flex justify='start' align='center'>
                    <Text color={textColor} fontSize={'18px'} fontWeight='light' align={'center'} lineHeight={'100%'}>{'Seringueiro:'}</Text>
                    <Text color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{nomeSeringueiro}</Text>
                </Flex>
                <Flex justify='start' align='center'>
                    <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'}>{`Mensal - `}</Text>
                    <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'}>{`${numMesP} de ${anoCorrente}`}</Text>
                </Flex>
            </Box>
        )
    }

    function renderUploadFile() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex px={'10px'} pb={'15px'} justify='space-between' align='center'>
                    <Box>
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{`imagens ${qtdImages}`}</Text>
                    </Box>
                    <Box>
                        <Input type="file" ref={inputRef} display="none" onChange={handleFileChange} accept="image/*" />
                        <Button isDisabled={!sangriaAtiva} variant="miniBrand" rightIcon={<IoAddCircleSharp fontSize={'20px'} />} onClick={handleClick}>Adicionar Imagem</Button>
                        <Text color={'gray.500'} fontSize='13px' fontWeight='700' ml={'10px'} lineHeight={'100%'} align={'center'}>{'Max 10 MB'}</Text>
                    </Box>
                </Flex>
                {listUrlImages.length > 0 ?
                    <Flex pl={'0px'} w={'100%'} justify='start' align='start' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Box textAlign="center">
                            <Wrap p={'5px'} spacing='15px' justify={'start'} align={'center'}>
                                {listUrlImages.map((image, index) => (
                                    <WrapItem
                                        key={index}
                                        boxSize="100px"
                                        flex="0 0 auto"
                                        borderRadius={'10px'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        borderWidth={currentIndex === index ? "0px" : "0px"}
                                        borderColor={'red'}
                                        cursor={'pointer'}
                                        onClick={() => handleImageClickCarrossel(index)}
                                    >
                                        <Box position={'relative'}>
                                            <Image
                                                src={image.urlStorage}
                                                alt={`Thumbnail ${index + 1}`}
                                                boxSize={'98px'}
                                                objectFit="cover"
                                                borderRadius={'8px'}
                                            />
                                            <Box position={'absolute'} right={'10px'} top={'-1px'}>
                                                <IconButton
                                                    isRound={true}
                                                    aria-label="Previous Image"
                                                    icon={<DeleteIcon />}
                                                    transform="translateY(-50%)"
                                                    bg={'whiteAlpha.900'}
                                                    color={'black'}
                                                    fontSize={'15px'}
                                                    size='xs'
                                                    borderWidth={'1px'}
                                                    borderColor={'black'}
                                                    onClick={() => { organizarExclusaoDeArquivo(image) }}
                                                />
                                            </Box>
                                        </Box>
                                    </WrapItem>
                                ))}
                            </Wrap>
                            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                <ModalOverlay />
                                <ModalContent maxW="600px" position="relative" bg={'whiteAlpha.800'}>
                                    <ModalCloseButton borderWidth={'1px'} borderColor={'blackAlpha.700'} bg={'whiteAlpha.700'} />
                                    <IconButton
                                        aria-label="Previous Image"
                                        icon={<ChevronLeftIcon />}
                                        position="absolute"
                                        left="0"
                                        top="50%"
                                        transform="translateY(-50%)"
                                        onClick={prevImage}
                                        zIndex={1}
                                    />
                                    <ModalBody p={0}>
                                        <Box px={'10px'} pb={'10px'}>
                                            <Text my={'10px'} color={'black'} fontSize={'18px'} fontWeight={'bold'} align={'center'}>{'Imagem'}</Text>
                                            <Flex flexDirection={'column'} justify={'center'} align={'center'} bg={'gray.100'} borderWidth={'1px'} borderColor={'blackAlpha.400'} borderRadius={'10px'}>
                                                <Box px={'10px'} py={'10px'} maxH={'500px'}>
                                                    <Image
                                                        src={listUrlImages[currentIndex].urlStorage}
                                                        alt={`Image ${currentIndex + 1}`}
                                                        w="100%"
                                                        h={'400px'}
                                                        objectFit="contain"
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </ModalBody>
                                    <IconButton
                                        aria-label="Next Image"
                                        icon={<ChevronRightIcon />}
                                        position="absolute"
                                        right="0"
                                        top="50%"
                                        transform="translateY(-50%)"
                                        onClick={nextImage}
                                        zIndex={1}
                                    />
                                </ModalContent>
                            </Modal>
                        </Box>
                    </Flex>
                    :
                    <Flex pl={'10px'} minH={'100px'} w={'100%'} overflowX="auto" justify='center' align='center' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Text color={'gray.400'} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Sem Imagens'}</Text>
                    </Flex>
                }
                {isValidMaxSizeFile &&
                    <Text color={'red'} fontSize={'14px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{messageArryUpload}</Text>
                }
            </Box>
        )
    }

    function renderVideos() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex px={'10px'} pb={'15px'} justify='space-between' align='center'>
                    <Box>
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{`Videos ${qtdVideos}`}</Text>
                    </Box>
                    <Box>
                        <Input type="file" ref={videoRef} display="none" onChange={changeCarregarVideo} accept="video/*" />
                        <Button isDisabled={!sangriaAtiva} variant="miniBrand" rightIcon={<IoAddCircleSharp fontSize={'20px'} />} onClick={handleVideoClick}>Adicionar Video</Button>
                        <Text color={'gray.500'} fontSize='13px' fontWeight='700' ml={'10px'} lineHeight={'100%'} align={'center'}>{'Max 20 MB'}</Text>
                    </Box>
                </Flex>

                {listUrlVideos.length > 0 ?
                    <Flex pl={'0px'} w={'100%'} justify='start' align='start' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Box textAlign="center">
                            <Wrap p={'5px'} spacing='15px' justify={'start'} align={'center'}>
                                {listUrlVideos.map((item, index) => (
                                    <WrapItem
                                        key={index}
                                        boxSize="100px"
                                        flex="0 0 auto"
                                        borderRadius={'10px'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        cursor={'pointer'}
                                        onClick={() => { abrirIVideoModal(item) }}
                                    >
                                        <Box position={'relative'}>
                                            <Box>
                                                <video
                                                    src={item.urlStorage}
                                                    controls={false}
                                                    width={'100'}
                                                />
                                            </Box>
                                            <Box position={'absolute'} right={'10px'} top={'-1px'}>
                                                <IconButton
                                                    isRound={true}
                                                    aria-label="Previous Image"
                                                    icon={<DeleteIcon />}
                                                    transform="translateY(-50%)"
                                                    bg={'whiteAlpha.900'}
                                                    color={'black'}
                                                    fontSize={'15px'}
                                                    size='xs'
                                                    borderWidth={'1px'}
                                                    borderColor={'black'}
                                                    onClick={() => { organizarExclusaoDeArquivo(item) }}
                                                />
                                            </Box>
                                        </Box>
                                    </WrapItem>
                                ))}
                            </Wrap>
                        </Box>
                    </Flex>
                    :
                    <Flex pl={'10px'} minH={'100px'} w={'100%'} overflowX="auto" justify='center' align='center' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Text color={'gray.400'} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Sem Vídeos'}</Text>
                    </Flex>
                }
                {isTamanhoMaximoVideo &&
                    <Text color={'red'} fontSize={'14px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{messageVideo}</Text>
                }
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'10px'} py={'10px'} >
                {isVisibleAlert && renderAlert()}
                {renderTitulo()}
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                    {renderUploadFile()}
                    {renderVideos()}
                </SimpleGrid>
            </Card>
            {renderButton()}
            {showVideoModal && <VideoModal isOpen={showVideoModal} onPressCancel={() => { closeModalVideo() }} videoSrc={videoSrc} />}
            {showVideoModalUpload && <VideoModalUpload isOpen={showVideoModalUpload} onPressOk={() => { salvarVideoUpload() }} onPressCancel={() => { closeModalVideoUpload() }} videoSrc={videoSrc} />}
            {showImageModal && <ImageModal isOpen={showImageModal} onPressOk={() => { salvarImagens() }} onPressCancel={() => { closeModalImagem() }} image={fileImageModal} />}
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { inserirFlagDeletado() }} onPressCancel={() => { setOpenModalDialog(false) }} titulo={'Excluir'} descricao={'Tem certeza que deseja excluir?'} />}
        </Box>
    );
}
