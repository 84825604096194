
export async function MASCARAR_VALOR_SAZONALIDADE(valor = '0'){
    let xx = valor;
    xx = xx.replace(/\D/g, "");

    if (xx.length > 0) {
        const tyy = Number(xx);
        if (tyy == 0) {
            xx = '00';
        } else if (tyy > 0 && tyy < 9) {
            xx = `0${tyy}`;
        } else {
            xx = `${tyy}`;
        }
    } else {
        xx = '00'
    }
    xx = xx.replace(/(\d+)(\d{1})$/, "$1,$2");
    return xx;
}

 export async function SOMA_VALORES_SAZONALIDADE(JANEIRO = '0', FEVEREIRO = '0', MARCO = '0', ABRIL = '0', MAIO = '0', JUNHO = '0', JULHO = '0', AGOSTO = '0', SETEMBRO = '0', OUTUBRO = '0', NOVEMBRO = '0', DEZEMBRO = '0') {

    let jan = JANEIRO != '' ? JANEIRO.replace(/\D/g, "") : '0';
    let fev = FEVEREIRO != '' ? FEVEREIRO.replace(/\D/g, "") : '0';
    let mar = MARCO != '' ? MARCO.replace(/\D/g, "") : '0';
    let abr = ABRIL != '' ? ABRIL.replace(/\D/g, "") : '0';
    let mai = MAIO != '' ? MAIO.replace(/\D/g, "") : '0';
    let jun = JUNHO != '' ? JUNHO.replace(/\D/g, "") : '0';
    let jul = JULHO != '' ? JULHO.replace(/\D/g, "") : '0';
    let ago = AGOSTO != '' ? AGOSTO.replace(/\D/g, "") : '0';
    let set = SETEMBRO != '' ? SETEMBRO.replace(/\D/g, "") : '0';
    let out = OUTUBRO != '' ? OUTUBRO.replace(/\D/g, "") : '0';
    let nov = NOVEMBRO != '' ? NOVEMBRO.replace(/\D/g, "") : '0';
    let dez = DEZEMBRO != '' ? DEZEMBRO.replace(/\D/g, "") : '0';

    jan = Number(jan);
    fev = Number(fev);
    mar = Number(mar);
    abr = Number(abr);
    mai = Number(mai);
    jun = Number(jun);
    jul = Number(jul);
    ago = Number(ago);
    set = Number(set);
    out = Number(out);
    nov = Number(nov);
    dez = Number(dez);

    let tt = 0;
    tt = jan + fev + mar + abr + mai + jun + jul + ago + set + out + nov + dez;
    tt = tt / 10;

    const result = tt.toFixed(1);
    return result;
}




export async function CRIAR_JSON_SAZONALIDADE(arrayInputsMeses = [], listSazonalidade = [], idUaP = '0', idSafraP = '0') {
    let result = null;
    try {
        let valBanco_DOIS = '0';
        let arrayParametro = [];
        arrayInputsMeses.map(item => {
            const val1 = item.valor.replace(',', '.');
            const val2 = parseFloat(val1);
            const val3 = val2 / 100;
            const val4 = `${val3}`;

            if (val4 == '0') {
                valBanco_DOIS = '0';

            } else if (val4.length == 3) {
                valBanco_DOIS = val3.toFixed(2);

            } else {
                valBanco_DOIS = val3.toFixed(3);
            }

            const array01 = listSazonalidade.filter(x => x.numMes == item.numMes)
            const idSazonalidadeP = array01.length > 0 ? array01[0].idSazonalidade : 0;
            const obj = {
                idSazonalidade: idSazonalidadeP,
                idUa: idUaP,
                idSafra: idSafraP,
                nomeMes: item.nomeMes,
                numMes: item.numMes,
                valor: valBanco_DOIS,
            }
            arrayParametro.push(obj);
        })
        const json = {
            listSazonalidade: arrayParametro
        }
        result = json;

    } catch (error) {
        console.log('Error na classe CalculoSazonalidade metodo CRIAR_JSON_SAZONALIDADE', error);
    }
    return result;
}