import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Flex,
    Button,
    Text,
} from "@chakra-ui/react";

function ModalConfirmacao({ isOpen, onPress, titulo = 'Ops!', descricao = 'Operação realizada com Sucesso!' }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bg={'white'}>
            <ModalHeader>
                <Text color={'black'} fontSize={'25px'} fontWeight={'bold'} lineHeight={'100%'} align={'start'}>{titulo}</Text>
                </ModalHeader>
                <ModalBody>
                    <Flex flexDirection={'column'} justify='space-around' align='center'>
                        <Text color={'blackAlpha.700'} fontSize='16px' fontWeight={'bold'}>{descricao}</Text>
                        <Button mt={'20px'} variant="brand" onClick={onPress}>Ok</Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalConfirmacao;
