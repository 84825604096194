import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import AdminReport from "layouts/report";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { UtilContextProvider } from 'contexts/UtilContext';

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <ThemeEditorProvider>
                <UtilContextProvider>
                    <HashRouter>
                        <Switch>
                            <Route path={`/auth`} component={AuthLayout} />
                            <Route path={`/admin`} component={AdminLayout} />
                            <Route path={`/report`} component={AdminReport} />
                            <Redirect from='/' to='/auth' />
                        </Switch>
                    </HashRouter>
                </UtilContextProvider>
            </ThemeEditorProvider>
        </React.StrictMode>
    </ChakraProvider>,
    document.getElementById("root")
);
