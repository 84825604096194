import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Text,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Box,
    ChakraProvider,
    Wrap,
    WrapItem,
    Input,
    Button,
    Center,
    TableContainer,
    Image,
    Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import ReactApexChart from "react-apexcharts";
import LoaderBlack from 'components/loading/LoaderBlack';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import { RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../../util/CalculoRelatorioSangriaAndProducao';
import { MdPerson, MdDoubleArrow } from "react-icons/md";

export default function AcumuladoSafraTable(props) {

    const history = useHistory();
    const { iconExcel, iconPdf } = props;
    const pdfRef = useRef();
    const [loading, setLoading] = useState(false);
    const [screenFullLoad, setScreenFullLoad] = useState(false);
    const [listAcumuladoSafra, setListAcumuladoSafra] = useState([]);
    const [formInputsMeses, setFormInputsMeses] = useState([]);
    const [totalArvoresAcumuladoSafra, setTotalArvoresAcumuladoSafra] = useState('0');
    const [totalPrevistoAcumuladoSafra, setTotalPrevistoAcumuladoSafra] = useState('0');
    const [totalRealizadoAcumuladoSafra, setTotalRealizadoAcumuladoSafra] = useState('0');
    const [totalPercentAcumuladoSafra, setTotalPercentAcumuladoSafra] = useState('0');

    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());
    const [maxDate, setMaxDate] = useState('');
    const [minDate, setMinDate] = useState('');

    const [idUaP, setIdUaP] = useState(null);
    const [idSafraP, setIdSafraP] = useState(null);
    const [arrayMesesAndData, setArrayMesesAndData] = useState([]);

    const [listGraficoRealizado, setListGraficoRealizado] = useState([]);
    const [listGraficoPrevisto, setListGraficoPrevisto] = useState([]);
    const [listGraficoEficiencia, setListGraficoEficiencia] = useState([]);

    const [listGraficoNomeSeringueiro, setListGraficoNomeSeringueiro] = useState([]);
    const [nomeUa, setNomeUa] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');
    const [marginText, setMarginText] = useState('0px');

    const [larguraPrimeiraColuna, setLarguraPrimeiraColuna] = useState('70px');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [listDesMesAnoAndTotais, setListDesMesAnoAndTotais] = useState([]);
    const [nomeRelatorio, setNomeRelatorio] = useState('Acumulado-safra');

    const [totalGeralPrevisto, setTotalGeralPrevisto] = useState('0');
    const [totalGeralRealizado, setTotalGeralRealizado] = useState('0');
    const [totalGeralPercentual, setTotalGeralPercentual] = useState('0');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const inputBg = useColorModeValue("white", "navy.700");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientNomeSeringueiro = 'linear-gradient(orange.700, orange.600, #cc6600)';
    const gradientNomeSeringueiroDeletado = 'linear-gradient(gray.700, gray.600, gray.500)';
    const gradientOrange = 'linear-gradient(orange.500, orange.200, orange.100)';
    const gradientTransparent = 'linear-gradient(transparent, transparent, transparent)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
            const nmUa = localStorage.getItem('@HE-nomeUa');
            const nmSafra = localStorage.getItem('@HE-nomeSafra');
            setNomeUa(nmUa);
            setNomeSafra(nmSafra);

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');
            setMaxDate(fim);
            setMinDate(ini);
            setDataInicio(ini);
            setDataFim(fim);

            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            setIdUaP(idUa);
            setIdSafraP(idSafra);

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Acumulado-safra-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);

            setNomeMesCorrente(`${nmMes}/${ano}`);
            setNomeMunicipio(nomeMunicipioP);
            setIdUf(idUfP);

            const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
            setArrayMesesAndData(ARRAY_MES);

            const url = `safraPessoa/listaSafraPessoaComProducao?idUa=${idUa}&idSafra=${idSafra}&dtInicio=${ini}&dtFim=${fim}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta == ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                const lancamentoProducaoPessoa = res.lancamentoProducaoPessoa;
                setListDesMesAnoAndTotais(lancamentoProducaoPessoa.listDesMesAnoAndTotais);

                await carregaDadosMeses(lancamentoProducaoPessoa);
                await carregaDadosSeringueiro(res);
                await carregarDados(res);
            }
            setScreenFullLoad(true);
        }
        loaderScreen();
    }, [])

    const carregaDadosMeses = async (res) => {

        setTotalGeralPrevisto(res.totalGeralPrevisto);
        setTotalGeralRealizado(res.totalGeralRealizado);
        setTotalGeralPercentual(res.totalGeralPercentual);

        const dadosSeringueiro = res.listObjProducaoPessoa;

        let ss = null;
        await new Promise(resolve => {
            resolve(
                ss = dadosSeringueiro.map(val => {
                    return {
                        key: `${val.idPessoa}`,
                        listMeses: val.listMeses,
                        idPessoa: `${val.idPessoa}`,
                        nome: val.nome,
                        deletado: val.deletado,
                        idSafraPessoa: val.idSafraPessoa,
                        totalPrevisto: val.totalPrevisto != 0 && val.totalPrevisto != null ? `${val.totalPrevisto}` : '0',
                        totalRealizado: val.totalRealizado != 0 && val.totalRealizado != null ? `${val.totalRealizado}` : '0',
                        totalPercentual: val.totalPercentual != 0 && val.totalPercentual != null ? `${val.totalPercentual}` : '0',
                    }
                })
            )
        });
        setFormInputsMeses(ss);

        let qtdCar = 0;     // ESSE LANCE AQUI E DA HORA KKK... PEGO O MAIOR NOME PARA AJUSTAR A LARGURA DA PRIMEIRA COLUNA DA TABELA
        ss.map(x => x.nome.length > qtdCar ? qtdCar = x.nome.length : qtdCar = qtdCar);
        if (qtdCar > 10) {
            let xx = 0;
            xx = qtdCar < 20 ? qtdCar * 7 : 140;
            setLarguraPrimeiraColuna(`${xx}px`);
        }
    }

    const carregaDadosSeringueiro = async (arrayPram) => {
        try {
            let arrqyRealizadas = [];
            let arrqyProvisto = [];
            let arrqyEficiencia = [];
            let arrqyNomeSeringueiros = [];

            const res = arrayPram.dadosProducao.acumuladoSafraDto.listItemAcumuladoSafra;
            var array = null;
            await new Promise(resolve => {
                resolve(
                    array = res.map(function (item) {

                        let qtdRealizado = item.realizado != '' ? item.realizado : 0;
                        let qtdPrevisto = item.previsto != '' ? item.previsto : 0;
                        let nomeP = item.nome;
                        let qtdEficiencia = item.percentRealizado != '' ? item.percentRealizado : 0;

                        qtdEficiencia = Number(qtdEficiencia);
                        qtdEficiencia = qtdEficiencia.toFixed(0);
                        arrqyRealizadas.push(Number(qtdRealizado));
                        arrqyProvisto.push(Number(qtdPrevisto));
                        arrqyEficiencia.push(Number(qtdEficiencia));
                        arrqyNomeSeringueiros.push(nomeP);

                        return {
                            nome: item.nome,
                        };
                    })
                )
            });
            let pp1 = null;
            let pp2 = null;
            let pp3 = null;

            let arr1 = arrqyRealizadas.map(x => x);
            let arr2 = arrqyProvisto.map(x => x);
            let arr3 = arrqyEficiencia.map(x => x);

            arr1.sort(function (a, b) { return b - a });
            arr2.sort(function (a, b) { return b - a });
            arr3.sort(function (a, b) { return b - a });

            if (arr1[0] == 0) {
                pp1 = arrqyRealizadas.map(x => '0%');
            } else {
                pp1 = arrqyRealizadas;
            }
            if (arr2[0] == 0) {
                pp2 = arrqyProvisto.map(x => '0%');
            } else {
                pp2 = arrqyProvisto;
            }
            if (arr3[0] == 0) {
                pp3 = [];
            } else {
                pp3 = arrqyEficiencia;
            }

            setListGraficoRealizado(pp1);
            setListGraficoPrevisto(pp2);
            setListGraficoEficiencia(pp3);
            setListGraficoNomeSeringueiro(arrqyNomeSeringueiros);

        } catch (error) {
            console.log('Erro na classe AcumuladoSafraGrafico metodo carregaDadosSeringueiro', error);
        }
    }

    const carregarDados = async (res) => {
        try {
            const acumuladoSafra = res.dadosProducao.acumuladoSafraDto;

            setTotalArvoresAcumuladoSafra(acumuladoSafra.totalArvores);
            setTotalPrevistoAcumuladoSafra(acumuladoSafra.totalPrevisto);
            setTotalRealizadoAcumuladoSafra(acumuladoSafra.totalRealizado);
            setTotalPercentAcumuladoSafra(acumuladoSafra.totalPercentual);

            const listAcumulado = acumuladoSafra.listItemAcumuladoSafra;
            setListAcumuladoSafra(listAcumulado);
        } catch (error) {
            console.log('Erro na classe AcumuladoSafraTable metodo carregarDados', error);
        }
    }

    const pesquisarComDatas = async () => {
        setLoading(true);

        setTimeout(async () => {
            await pesquisarComDatas22();
            setLoading(false);
        }, 1500);
    }

    const pesquisarComDatas22 = async () => {
        try {
            setLoading(true);
            const url = `safraPessoa/listaSafraPessoaComProducao?idUa=${idUaP}&idSafra=${idSafraP}&dtInicio=${dataInicio}&dtFim=${dataFim}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta == ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                const lancamentoProducaoPessoa = res.lancamentoProducaoPessoa;
                setListDesMesAnoAndTotais(lancamentoProducaoPessoa.listDesMesAnoAndTotais);

                await carregaDadosMeses(lancamentoProducaoPessoa);
                await carregaDadosSeringueiro(res);
                await carregarDados(res);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe AcumuladoSafraTable metodo pesquisarComDatas', error);
        }
    }

    const previewPdf = () => {
        const json = {
            listAcumuladoSafra: listAcumuladoSafra,
            formInputsMeses: formInputsMeses,
            nomeMesCorrente: nomeMesCorrente,
            nomeUa: nomeUa,
            nomeMunicipio: nomeMunicipio,
            idUf: idUf,
            totalGeralPrevisto: totalGeralPrevisto,
            totalGeralRealizado: totalGeralRealizado,
            totalGeralPercentual: totalGeralPercentual,
            series: series,
            options: options,
            totalArvoresAcumuladoSafra: totalArvoresAcumuladoSafra,
            totalPrevistoAcumuladoSafra: totalPrevistoAcumuladoSafra,
            totalRealizadoAcumuladoSafra: totalRealizadoAcumuladoSafra,
            totalPercentAcumuladoSafra: totalPercentAcumuladoSafra,
        }
        localStorage.setItem('@HE-dadosAcumuladoSafraParaGerarPdf', JSON.stringify(json));
        history.push("/admin/gerarpdf-acumulado-safra");
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await criarRelatorioExcel();
            setLoading(false);
        }, 1500);
    }

    const criarRelatorioExcel = async () => {
        const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(dataInicio, dataFim);
        setArrayMesesAndData(ARRAY_MES);

        const ua = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const nomeUserLogado = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');

        const json = {
            idUa: ua,
            idSafra: idSafra,
            dtInicio: dataInicio,
            dtFim: dataFim,
            nomeUsuario: nomeUserLogado,
            arrayMesDataFilter: arrayMesesAndData
        }
        const url = `safraPessoa/downloadRelatorioExcelAcumuladoSafra`;
        await Api.postRequestDownloadExcel(url, json, nomeRelatorio);
    }

    const gerarRelatorioExcelProducaoMensal = async () => {
        setLoading(true);

        setTimeout(async () => {
            await gerarExcelProducaoMensal();
            setLoading(false);
        }, 1500);
    }

    const gerarExcelProducaoMensal = async () => {

        const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
        const fim = localStorage.getItem('@HE-dtFimSafraAsync');
        const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
        const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');
        const ua = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const nomeUserLogado = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');

        const json = {
            idUa: ua,
            idSafra: idSafra,
            dtInicio: dtInicioSafra,
            dtFim: dtFimSafra,
            nomeUsuario: nomeUserLogado,
        }
        const url = `safraPessoa/downloadRelatorioExcelProducaoMensal`;
        await Api.postRequestDownloadExcel(url, json, nomeRelatorio);
    }

    const series = [
        {
            name: 'Previsto',
            type: 'column',
            data: listGraficoPrevisto,
        },
        {
            name: 'Realizado',
            type: 'column',
            data: listGraficoRealizado,
        },
        {
            name: '% Realizado',
            type: 'line',
            data: listGraficoEficiencia,
        }
    ]

    const options = {
        chart: {
            height: 350,
            type: 'line',
            foreColor: 'black',
            dropShadow: {
                enabled: false,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF",
            },
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false, // Desativa o zoom via scroll do mouse
            },
        },
        colors: ['#00004d', '#0099ff', '#ff0000'],
        stroke: {
            width: [1, 1, 0],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                enabled: false,
                dataLabels: {
                    enabled: false,
                    position: "center",         // top center bottom
                    orientation: 'vertical',    // vertical ou horizontal   // POSSICAO DO TEXTO NA COLUNA
                },
            }
        },
        title: {
            text: ''
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2],
            textAnchor: 'middle',       // start middle end
            formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                let result = value;
                if(seriesIndex == 0){
                    result = `${value}`;
                } else if(seriesIndex == 1){
                    result = `${value}`;
                } else if(seriesIndex == 2){
                    result = `${value}%`;
                }
                return result
              }
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001'],
        xaxis: {
            type: 'category',
            categories: listGraficoNomeSeringueiro,
            position: 'bottom',
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                trim: true,    // ESSE CARA COLOCA TRES PONTOS: ... QUANDO O TEXTO NAO COUBER NA LINHA
                offsetX: 0,
                offsetY: 0,
                style: {
                    colors: "#000000",
                    fontSize: "11px",
                    fontWeight: "500",
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        legend: {
            show: true,
            floating: false,
            fontSize: '15px',
            fontFamily: 'Helvetica, Arial',
            horizontalAlign: 'center',
            fontWeight: 100,
            offsetX: 0,
            offsetY: 10,
            height: 25,
        },
        yaxis: [
            {
                show: false,
            },
            {
                show: false,
                seriesName: 'Realizado',
            },
            {
                show: true,
                seriesName: '% Realizado',
            },
        ],
        fill: {
            type: "gradient",
            colors: ['#00004d', '#0099ff', '#ff0000'],
        },
    }

    function renderDatasPesquisa() {
        return (
            <Wrap spacing='10px' mb={'5px'} justify='flex-start' px={'1'} mx={'10px'}>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='14px' fontWeight='500' lineHeight='100%' ml={'10px'}>Início</Text>
                        <Input
                            value={dataInicio}
                            variant='filled'
                            onChange={(e) => setDataInicio(e.target.value)}
                            placeholder=""
                            bg={inputBg}
                            color={inputText}
                            borderColor={textColor}
                            borderRadius="10px"
                            size="md"
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            type="date"
                            onKeyDown={(e) => { e.preventDefault() }}
                            max={maxDate}
                            min={minDate}
                        />
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='14px' fontWeight='500' lineHeight='100%' ml={'10px'}>Fim</Text>
                        <Input
                            value={dataFim}
                            variant='filled'
                            onChange={(e) => setDataFim(e.target.value)}
                            placeholder=""
                            bg={inputBg}
                            color={inputText}
                            borderColor={textColor}
                            borderRadius="10px"
                            size="md"
                            h={'35px'}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            type="date"
                            onKeyDown={(e) => { e.preventDefault() }}
                            max={maxDate}
                            min={minDate}
                        />
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' h={'100%'}>
                        <Center alignItems={'flex-end'} h={'100%'}>
                            <Button variant="brand" onClick={pesquisarComDatas}>Pesquisar</Button>
                        </Center>
                    </Box>
                </WrapItem>
            </Wrap>
        )
    }

    function renderTableSafra() {
        return (
            <TableContainer>
                <Table size='sm' variant='striped' colorScheme='blackAlpha' >
                    <Thead px={'0px'} bg={'blackAlpha.500'}>
                        <Tr h={'35px'} bgGradient={gradientHeader}>
                            <Th>
                                <Box textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='13px' align={'center'}>Seringueiro</Text>
                                </Box>
                            </Th>
                            <Th >
                                <Box textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='13px' align={'center'}>Clone</Text>
                                </Box>
                            </Th>
                            <Th >
                                <Box textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='13px' align={'center'} >Árvores</Text>
                                </Box>
                            </Th>
                            <Th >
                                <Box textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='13px' align={'center'} >Painel</Text>
                                </Box>
                            </Th>
                            <Th >
                                <Box textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='13px' align={'center'} >Previsto</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='13px' align={'center'} >Realizado</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='13px' align={'center'} >% Realizado</Text>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {listAcumuladoSafra.map((item, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>
                                        <Text mb={marginText} color={textColor} fontSize='13px' fontWeight='700' lineHeight={'100%'} align={'start'}>{item.nome}</Text>
                                    </Td>
                                    <Td>
                                        <Text mb={marginText} color={textColor} fontSize='13px' fontWeight='700' lineHeight={'100%'} align={'center'}>{item.nomeClone}</Text>
                                    </Td>
                                    <Td>
                                        <Text mb={marginText} color={textColor} fontSize='13px' fontWeight='700' lineHeight={'100%'} align={'center'}>{item.quantidadeArvores}</Text>
                                    </Td>
                                    <Td>
                                        <Text mb={marginText} color={textColor} fontSize='13px' fontWeight='700' lineHeight={'100%'} align={'center'}>{item.nomePainel}</Text>
                                    </Td>
                                    <Td>
                                        <Text mb={marginText} color={textColor} fontSize='13px' fontWeight='700' lineHeight={'100%'} align={'center'}>{item.previsto}</Text>
                                    </Td>
                                    <Td>
                                        <Text mb={marginText} color={textColor} fontSize='13px' fontWeight='700' lineHeight={'100%'} align={'center'}>{item.realizado}</Text>
                                    </Td>
                                    <Td>
                                        <Text mb={marginText} color={textColor} fontSize='13px' fontWeight='700' lineHeight={'100%'} align={'center'}>{`${item.percentRealizado}%`}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                        <Tr bgGradient={gradientHeader}>
                            <Td>
                                <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' align={'center'}>{''}</Text>
                            </Td>
                            <Td>
                                <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' align={'center'}>{''}</Text>
                            </Td>
                            <Td>
                                <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' align={'center'}>{totalArvoresAcumuladoSafra}</Text>
                            </Td>
                            <Td>
                                <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' align={'center'}>{''}</Text>
                            </Td>
                            <Td>
                                <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' align={'center'}>{totalPrevistoAcumuladoSafra}</Text>
                            </Td>
                            <Td>
                                <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' align={'center'}>{totalRealizadoAcumuladoSafra}</Text>
                            </Td>
                            <Td>
                                <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' align={'center'}>{`${totalPercentAcumuladoSafra}%`}</Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderProducaoMensal() {
        let corLinha = false;
        const larg = '100%';
        const largCo = '150px';
        const largTr = 0;
        const altura = '35px';
        const corBorder = 'blackAlpha.600';
        return (
            <Box>
                <ChakraProvider>
                    <Box padding={2} position={'relative'}>
                        <Box overflowY="hidden" whiteSpace={'nowrap'} overflowX={'auto'} maxWidth={'100%'} >
                            <Table size="sm" variant="unstyled" >
                                <Thead px={'0px'} bg={'blackAlpha.500'} >
                                    <Tr bgGradient={gradientHeader}>
                                        <Th sx={{ paddingX: largTr }}>
                                            <Text color={textColorHeader} fontSize='14px' fontWeight='700' textTransform={'none'} align={'center'}>{''}</Text>
                                        </Th>
                                        {listDesMesAnoAndTotais.map((item, index) => {
                                            return (
                                                <Th key={index} sx={{ paddingX: largTr }}>
                                                    <Box w={largCo}>
                                                        <Text color={textColorHeader} fontSize='13px' fontWeight='bold' textTransform={'none'} align={'center'}>{item.descMesAno}</Text>
                                                        <Flex justify='space-around' align='center' >
                                                            <Text color={textColorHeader} fontSize='11px' fontWeight='700' textTransform={'none'}>Prev</Text>
                                                            <Text color={textColorHeader} fontSize='11px' fontWeight='700' textTransform={'none'}>Real</Text>
                                                            <Text color={textColorHeader} fontSize='11px' fontWeight='700' textTransform={'none'}>%</Text>
                                                        </Flex>
                                                    </Box>
                                                </Th>
                                            )
                                        })}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {formInputsMeses.map((input, key) => {
                                        corLinha = !corLinha;
                                        const bgLinha = corLinha ? 'blackAlpha.200' : 'white';
                                        return (
                                            <Tr key={key + 1} h={altura} bg={bgLinha} borderBottomWidth={'0px'} borderColor={'blackAlpha.400'}>
                                                <Td sx={{ paddingX: largTr }}>
                                                    <Box w={larguraPrimeiraColuna}>
                                                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight={'100%'}>{''}</Text>
                                                    </Box>
                                                </Td>
                                                {input.listMeses.map((item, ww) => {
                                                    const valorParcial = item.valorParcial != null ? true : false;
                                                    const valorAnual = item.valorAnual != null ? true : false;
                                                    const gradientez = valorParcial || valorAnual ? gradientOrange : gradientTransparent;
                                                    const deletado = item.deletado;
                                                    let valRealizado = item.valorRealizado != '0' ? item.valorRealizado : '';
                                                    return (
                                                        <Td key={ww} sx={{ paddingX: largTr }} bgGradient={gradientez} borderLeftWidth={'1px'} borderColor={corBorder}>
                                                            <Flex justify='space-around' align='center'>
                                                                <Box w={larg}>
                                                                    <Text color={textColor} fontSize='12px' fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.valorPrevisto}`}</Text>
                                                                </Box>
                                                                <Box w={larg} display={'flex'} justifyContent={'center'}>
                                                                    <Text color={'black'} fontSize='12px' fontWeight='bold' align={'center'} lineHeight={'100%'}>{`${item.valorRealizado}`}</Text>
                                                                </Box>
                                                                <Box w={larg} display={'flex'} justifyContent={'center'}>
                                                                    <Text color={'black'} fontSize='12px' fontWeight='bold' align={'center'} lineHeight={'100%'}>{`${item.percent}%`}</Text>
                                                                </Box>
                                                            </Flex>
                                                        </Td>
                                                    )
                                                })}
                                            </Tr>
                                        )
                                    })}
                                    <Tr bgGradient={gradientHeader}>
                                        <Td sx={{ paddingX: largTr }}>
                                            <Text color={textColorHeader} fontSize='12px' fontWeight='700' align={'center'}>Total</Text>
                                        </Td>
                                        {listDesMesAnoAndTotais.map((item, index) => {
                                            return (
                                                <Td key={index} sx={{ paddingX: largTr }}>
                                                    <Flex justify='center' align='center'>
                                                        <Box w={larg}>
                                                            <Text color={textColorHeader} fontSize='12px' fontWeight='700' align={'center'}>{item.totalGeralPrevistoMes}</Text>
                                                        </Box>
                                                        <Box w={larg}>
                                                            <Text color={textColorHeader} fontSize='12px' fontWeight='700' align={'center'}>{item.totalGeralRealizadoMes}</Text>
                                                        </Box>
                                                        <Box w={larg}>
                                                            <Text color={textColorHeader} fontSize='12px' fontWeight='700' align={'center'}>{`${item.totalGeralPercent}%`}</Text>
                                                        </Box>
                                                    </Flex>
                                                </Td>
                                            )
                                        })}
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                        <Box pos="absolute" left={2} top={2} zIndex={2}>
                            <Box h={'39px'} px='0px' ml={'0px'} bgGradient={gradientHeader}>
                                <Center h={'100%'}>
                                    <Icon as={MdPerson} width='20px' height='20px' color='white' />
                                </Center>
                            </Box>
                            {formInputsMeses.map((item, index) => {
                                const deletado = item.deletado;
                                let gbGradiente = gradientNomeSeringueiro;
                                if (deletado) {
                                    gbGradiente = gradientNomeSeringueiroDeletado;
                                }
                                let nomePs = item.nome;
                                nomePs = nomePs.toLowerCase();
                                return (
                                    <Flex
                                        key={index}
                                        h={altura}
                                        flexDirection={'column'}
                                        align='start'
                                        bgGradient={gbGradiente}
                                    >
                                        <Box h={'37px'} w={larguraPrimeiraColuna} px='2px' >
                                            <Center h={'100%'} justifyContent={'start'} ml={'5px'}>
                                                <Text textTransform="capitalize" color={'white'} fontSize='13px' fontWeight='bold' lineHeight={'100%'} noOfLines={2}>{nomePs}</Text>
                                            </Center>
                                        </Box>
                                    </Flex>
                                )
                            })}
                        </Box>
                    </Box>
                </ChakraProvider>
            </Box>
        )
    }

    if (!screenFullLoad) {
        return (
            <Flex px='1px' mt={'10px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' py='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px='2px' justify='space-between' align='center' >
                <Box>
                    {renderDatasPesquisa()}
                </Box>
                <Box>
                    <Flex px='2px' justify='flex-end' align='center' >
                        <Image
                            mr={'20px'}
                            boxSize='35px'
                            objectFit='contain'
                            src={iconExcel}
                            alt='Dan Abramov'
                            cursor={'pointer'}
                            onClick={() => { gerarRelatorioExcel() }} />
                        <Image
                            mr={'10px'}
                            boxSize='35px'
                            objectFit='contain'
                            src={iconPdf}
                            alt='Dan Abramov'
                            cursor={'pointer'}
                            onClick={downloadPdf} />
                    </Flex>
                </Box>
            </Flex>
            <Box ref={pdfRef} w='100%' mt={'10px'} mx={'5px'}>
                <Box position={'relative'}>
                    <Flex flexDirection={'column'} justify='center' align='center' mb={'0px'}>
                        <Flex justify='center' align='end'>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'} lineHeight={'100%'}>{`Acumulado Safra`}</Text>
                            <Text color={'orange.600'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{nomeMesCorrente}</Text>
                        </Flex>
                        <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'5px'}>{nomeUa}</Text>
                        <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                    </Flex>
                    <Box position={'absolute'} top={'1px'} left={'10px'}>
                        <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                    </Box>
                </Box>
                <Flex px='2px' justify='start' align='center'>
                    <Text color={textColor} fontSize='15px' fontWeight='normal' align={'center'}>{'Safra: '}</Text>
                    <Text color={textColor} fontSize='15px' fontWeight='bold' align={'center'} ml={'15px'} >{nomeSafra}</Text>
                </Flex>
                <Box w='100%' mt={'10px'} mx={'5px'}>
                    {renderTableSafra()}
                </Box>
                <Box h='380px' mt='30px' bg={'white'} borderWidth={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.400'}>
                    <ReactApexChart options={options} series={series} type="line" height={350} width={'100%'} />
                </Box>
            </Box>
            <Box position={'relative'}>
                <Text mt={'15px'} mb={'10px'} color={textColor} fontSize='20px' fontWeight='700' align={'center'}>Produção Mensal</Text>
                <Box mb={'20px'} mx={'5px'}>
                    {renderProducaoMensal()}
                </Box>
                <Box position={'absolute'} top={'15px'} right={'10px'}>
                    <Flex px={'15px'} justify='flex-end' align='center'>
                        <Image mr={'20px'} boxSize='35px' objectFit='contain' src={iconExcel} alt='Dan Abramov' cursor={'pointer'} onClick={() => { gerarRelatorioExcelProducaoMensal() }} />
                        <Flex p='0px' justify='flex-end' mb='0px' align='center' borderWidth={'2px'} borderRadius={'10px'} >
                            <Image mr={'5px'} boxSize='35px' objectFit='contain' src={iconPdf} alt='Dan Abramov' cursor={'pointer'} onClick={previewPdf} />
                            <Icon as={MdDoubleArrow} width='20px' height='20px' color='inherit' />
                        </Flex>
                    </Flex>
                </Box>
            </Box>
            <LoaderBlack isOpen={loading} />
        </Card>
    );
};
