import React, { createContext, useCallback, useEffect, useState } from 'react';
export const UtilContext = createContext({});

export const UtilContextProvider = ({ children }) => {

    const [valueProgressBar, setValueProgressBar] = useState(0);
    const [atualisarNotificacao, setAtualisarNotificacao] = useState(false);
    const [isAuthenticator, setIsAuthenticator] = useState(false);

    const informevalorProgressBar = (val) => {
        setValueProgressBar((value) => value = val);
    };

    const isAtualisarNotificacao = (val) => {
        setAtualisarNotificacao((value) => value = val);
    };
    
    const isUsuarioAutenticado = (val) => {
        setIsAuthenticator((value) => value = val);
    };

    return (
        <UtilContext.Provider
            value={{
                informevalorProgressBar,
                valueProgressBar,
                atualisarNotificacao,
                isAtualisarNotificacao,
                isUsuarioAutenticado,
                isAuthenticator,
            }}
        >
            {children}
        </UtilContext.Provider>
    );
}
