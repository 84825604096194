import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Select,
    TableContainer,
    WrapItem,
    Wrap,
    Center,
    Grid,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import LoaderBlack from 'components/loading/LoaderBlack';
import MessageSreen from 'components/message/MessageSreen';
import { ARRAY_MESES } from 'model/MockData';
import TooltipSeringueiro from 'components/tooltip/TooltipSeringueiro';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { jsPDF } from "jspdf";
import iconPdf from "assets/img/layout/pdf_icon_download.png";
import html2canvas from 'html2canvas';
import { CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO, RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../../util/CalculoRelatorioSangriaAndProducao';

export default function AtividadesDeSangria() {

    const pdfRef = useRef();
    const [loading, setLoading] = useState(false);
    const [saldoAcumulado, setSaldoAcumulado] = useState(0);
    const [porcentagemRealizada, setPorcentagemRealizada] = useState(0);
    const [producaoCoagulosPorArvorePrevista, setProducaoCoagulosPorArvorePrevista] = useState(0);
    const [producaoCoagulosPorArvoreRealizada, setProducaoCoagulosPorArvoreRealizada] = useState(0);
    const [arrayMeses, setArrayMeses] = useState([]);
    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [mesInicioSelecionado, setMesInicioSelecionado] = useState('');

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [totalPercentual, setTotalPercentual] = useState(0);
    const [extratoProducao, setExtratoProducao] = useState([]);
    const [listAtividadeSangriaItem, setListAtividadeSangriaItem] = useState([]);

    const [percentualSangriasPossiveisRealizadas, setPercentualSangriasPossiveisRealizadas] = useState('0');
    const [percentualIndiceEficiencia, setPercentualIndiceEficiencia] = useState('0');
    const [chuvaFeriado, setChuvaFeriado] = useState('0');
    const [diasPossiveisPeriodo, setDiasPossiveisPeriodo] = useState('0');
    const [realizadas, setRealizadas] = useState('0');
    const [faltas, setFaltas] = useState('0');
    const [realizadasMenosTotalDeDias, setRealizadasMenosTotalDeDias] = useState('0');

    const [totalConsumo, setTotalConsumo] = useState('0');
    const [totalProfundidade, setTotalProfundidade] = useState('0');
    const [totalFerimento, setTotalFerimento] = useState('0');
    const [totalAspecto, setTotalAspecto] = useState('0');
    const [totalPontos, setTotalPontos] = useState('0');
    const [avaliacaoSangria, setAvaliacaoSangria] = useState('');
    const [corNota, setCorNota] = useState('');
    const [notaSeringueiro, setNotaSeringueiro] = useState('');
    const [listTarefa, setListTarefa] = useState([]);
    const [totalSangriaPorTarefa, setTotalSangriaPorTarefa] = useState(null);
    const [listFrequenciaMedia, setListFrequenciaMedia] = useState([]);
    const [diasUteisNoMes, setDiasUteisNoMes] = useState('0');

    const [nomeUa, setNomeUa] = useState('');
    const [marginText, setMarginText] = useState('0px');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [nomeRelatorio, setNomeRelatorio] = useState('Atividades-sangria');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('--');

    const textColorHeader = useColorModeValue("white", "white");
    const colorFooterAndHeader = useColorModeValue("rgba(204, 90, 0, 0.9)", "black");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientSubTotal = 'linear-gradient(#ff8000, #ff9933, #ffcc99)';
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientGreen = 'linear-gradient(green.300, green.400, green.500)';
    const gradientYellow = 'linear-gradient(yellow.300, yellow.400, yellow.500)';
    const gradientred = 'linear-gradient(red.300, red.400, red.500)';
    const gradientWhite = 'linear-gradient(gray.300, white, gray.300)';
    const gradientBlack = 'linear-gradient(gray.600, gray.700, gray.900)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
            const nmUa = localStorage.getItem('@HE-nomeUa');
            setIdUf(idUfP);
            setNomeMunicipio(nomeMunicipioP);
            setNomeUa(nmUa);

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Atividades-sangria-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);

            setArrayMesInicio(ARRAY_MESES);

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');
            const initMes = moment().startOf('month').format('YYYY-MM-DD');
            const mesAndAno = moment(initMes).format('MM/YYYY');

            setMesInicioSelecionado(initMes);
            setNomeMesCorrente(mesAndAno);

            const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
            setArrayMeses(ARRAY_MES);

            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            const url = `safraPessoa/listaPessoaParaUmaUaAndSafra?idUa=${idUa}&idSafra=${idSafra}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const listSeringueiros = response.respostaRequisicao.data;

                if (listSeringueiros.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            listSeringueiros.map(function (item) {
                                const obj = {
                                    label: item.nome,
                                    value: `${item.idPessoa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                }
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const pesquisarComDatas = async () => {
        try {
            const fil = listSeringueiro.filter(x => x.value == pessoaSelecionado);
            const nomeSe = fil.length > 0 ? fil[0].label : '';
            setNomeSeringueiro(nomeSe);

            setNomeMesCorrente(moment(mesInicioSelecionado).format('MM/YYYY'));
            const primeiroDiaDoMes = moment(mesInicioSelecionado).startOf('month');
            const UltimoDiaDoMes = moment(mesInicioSelecionado).endOf('month');

            let startDate = moment(primeiroDiaDoMes);
            let endDate = moment(UltimoDiaDoMes);
            let date = [];

            for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'month')) {
                date.push(m.format('YYYY-MM-DD'));
            }

            setLoading(true);

            let arrayPess = [];
            await new Promise(resolve => {
                resolve(
                    arrayMeses.map((item, index) => {
                        let statusP = false;
                        if (index > 0) {
                            const arrFilter = date.filter(x => x == item.dtMes);
                            statusP = arrFilter.length > 0 ? true : false;
                        }
                        const obj = {
                            dtMes: item.dtMes,
                            mes: item.mes,
                            status: statusP,
                        }
                        arrayPess.push(obj);
                    })
                )
            });

            const dtI = moment(primeiroDiaDoMes).format('YYYY-MM-DD');
            const dtF = moment(UltimoDiaDoMes).format('YYYY-MM-DD');
            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: idUa,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                idPessoa: pessoaSelecionado,
                arrayMesDataFilter: arrayMeses,
            }

            const response = await Api.postRequest(`atividadeSangria/relatorioAtividadeSangria`, json);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                const listAtividadeSangriaItemP = res.listAtividadeSangriaItem;
                const listTarefaP = res.listTarefa;

                setListAtividadeSangriaItem(listAtividadeSangriaItemP);
                setListTarefa(listTarefaP);
                setTotalSangriaPorTarefa(res.totalSangriaPorTarefa);
                setListFrequenciaMedia(res.listFrequenciaMedia);

                await carregaDadosSeringueiro(res, false);
                setLoading(false);
            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Erro na classe AtividadesDeSangria metodo pesquisar', error);
        }
    }

    const calculaMedia = (val) => {
        let corN = 'black';
        if (val == 1) {
            corN = 'gray.500';
        } else if (val == 2) {
            corN = 'green.500';
        } else if (val == 3) {
            corN = 'orange.600';
        } else if (val == 4) {
            corN = 'red';
        }
        setCorNota(corN);
    }

    const carregaDadosSeringueiro = async (array, seringueiroSelecionadoP = false, nomePess = 'Todos') => {

        const indicador = array.indicadorPorSeringueiro;

        const percentualSangriasPossiveisRealizadasP = indicador.percentualSangriasPossiveisRealizadas;
        const percentualIndiceEficienciaP = indicador.percentualIndiceEficiencia;
        const chuvaFeriadoP = indicador.chuvaFeriado;
        const diasPossiveisPeriodoP = indicador.diasPossiveisPeriodo;
        const realizadasP = indicador.realizadas;
        const faltasP = indicador.faltas;
        const realizadasMenosTotalDeDiasP = indicador.realizadasMenosTotalDeDias;

        const totalConsumoP = array.totalConsumo != null ? Number(array.totalConsumo) : 0;
        const totalProfundidadeP = array.totalProfundidade != null ? Number(array.totalProfundidade) : 0;
        const totalFerimentoP = array.totalFerimento != null ? Number(array.totalFerimento) : 0;
        const totalAspectoP = array.totalAspecto != null ? Number(array.totalAspecto) : 0;
        const totalPontosP = array.totalPontos != null ? Number(array.totalPontos) : 0;
        const notaSeringueiroP = array.notaSeringueiro != null ? Number(array.notaSeringueiro) : 0;
        const idAvaliacaoP = array.idAvaliacao != null ? Number(array.idAvaliacao) : 1;
        const diasUteisNoMesP = array.diasUteisNoMes != null ? array.diasUteisNoMes : 0;

        setAvaliacaoSangria(array.avaliacaoDeSangria);
        calculaMedia(idAvaliacaoP);
        setTotalConsumo(totalConsumoP.toFixed(1).replace('.', ','));
        setTotalProfundidade(totalProfundidadeP.toFixed(1).replace('.', ','));
        setTotalFerimento(totalFerimentoP.toFixed(1).replace('.', ','));
        setTotalAspecto(totalAspectoP.toFixed(1).replace('.', ','));
        setTotalPontos(totalPontosP.toFixed(1).replace('.', ','));
        setDiasUteisNoMes(diasUteisNoMesP);

        setPercentualSangriasPossiveisRealizadas(percentualSangriasPossiveisRealizadasP);
        setPercentualIndiceEficiencia(percentualIndiceEficienciaP);
        setChuvaFeriado(chuvaFeriadoP);
        setDiasPossiveisPeriodo(diasPossiveisPeriodoP);
        setRealizadas(realizadasP);
        setFaltas(faltasP);
        setRealizadasMenosTotalDeDias(realizadasMenosTotalDeDiasP);

        let notaX = '';
        if (notaSeringueiroP >= 100) {
            notaX = notaSeringueiroP.toFixed(0);
        } else {
            const xw = notaSeringueiroP.toFixed(1);
            notaX = xw.replace('.', ',');
        }
        setNotaSeringueiro(notaX);

        const arraySeringueirosComMeses = array.responseProducao.listRelatorioAcompanhamentoProducao[0]
        const res = await CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionadoP);

        setTotalRealizado(res.totalRealizado);
        setTotalPrevisto(res.totalPrevisto);
        setExtratoProducao(res.arrayProducaoAndGrafico);
        setTotalPercentual(res.totalPercentual);
        setSaldoAcumulado(res.saldoAcumulado);

        setPorcentagemRealizada(res.porcentagemRealizado);
        setProducaoCoagulosPorArvorePrevista(res.producaoCoagulosPorArvorePrevista);
        setProducaoCoagulosPorArvoreRealizada(res.producaoCoagulosPorArvoreRealizada);

        const jsonProducao = {
            totalRealizado: res.totalRealizado,
            totalPrevisto: res.totalPrevisto,
            totalArvoresSangradas: res.totalArvoresSangradas,
            totalDistribuicao: res.totalDistribuicao,
            extratoProducao: res.arrayProducaoAndGrafico,
            totalPercentual: res.totalPercentual,
            nomePessoa: nomePess
        }
        localStorage.setItem('HE@-dadosRelatorioAcompanhamentoProducao', JSON.stringify(jsonProducao));
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('10px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    function renderMeses() {
        return (
            <Box position={'relative'} borderRadius={'10px'} py={'10px'}>
                <Wrap px={'10px'} spacing='10px' justify='start'>
                    <WrapItem>
                        <Box w='100%'>
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>mês</Text>
                            <Select
                                value={mesInicioSelecionado}
                                errorBorderColor='crimson'
                                placeholder=""
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={e => setMesInicioSelecionado(e.target.value)}
                                w={'200px'}
                                h={'35px'}
                            >
                                {arrayMesInicio.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='100%'>
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Seringueiros</Text>
                            <Select
                                value={pessoaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={(e) => setPessoaSelecionado(e.target.value)}
                                h={'35px'}
                                w={'350px'}
                            >
                                {listSeringueiro.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='100%' h={'100%'}>
                            <Center alignItems={'flex-end'} h={'100%'}>
                                <Button isDisabled={pessoaSelecionado != '' ? false : true} variant="brand" onClick={pesquisarComDatas}>Pesquisar</Button>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Center py={'6px'} h={'100%'} justifyContent={'center'} alignItems={'end'}>
                            <TooltipIcon
                                label={
                                    <Box px={'0px'} py={'5px'} >
                                        <Text align={'center'}>{'Pesquisar'}</Text>
                                        <Text align={'center'} mt={'5px'}>{'Selecione o mês, um seringueiro e clique em pesquisar'}</Text>
                                    </Box>
                                }
                                height={'23px'} />
                        </Center>
                    </WrapItem>
                </Wrap>
                <Box position={'absolute'} top={'20px'} right={'15px'}>
                    <Image boxSize='35px' objectFit='contain' src={iconPdf} alt='Dan Abramov' cursor={'pointer'} onClick={downloadPdf} />
                </Box>
            </Box>
        )
    }

    function renderDadosRelatorio() {
        return (
            <Box>
                <Flex px={'5px'} justify='start' align='center'>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'}>{'Seringueiro:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{nomeSeringueiro}</Text>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'} ml={'30px'}>{'Mês/Ano:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{nomeMesCorrente}</Text>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'} ml={'30px'}>{'Dias Úteis/Mês:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{diasUteisNoMes}</Text>
                </Flex>
            </Box>
        )
    }

    function renderAtividadesSangria() {
        const sizeF = '13px';
        const largTr = 0;
        const altura = 1;
        const qtdTarefas = listTarefa.length;
        return (
            <Box mt={'0px'} borderRadius={'10px'} px={'5px'} py={'5px'}>
                <TableContainer borderTopRadius={'8px'} borderBottomRadius={'8px'}>
                    <Table size='sm' variant='unstyled' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'30px'} bgGradient={gradientHeader}>
                                <Th w={'120px'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Dia'}</Text>
                                </Th>
                                <Th w={'120px'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Program'}</Text>
                                </Th>
                                {listTarefa.map((item, index) => {
                                    return (
                                        <Th key={index} sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{item.letraTarefa}</Text>
                                        </Th>
                                    )
                                })}
                                <Th w={'180px'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Frequência Realizada'}</Text>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listAtividadeSangriaItem.map((item, index) => {
                                const isCheckd = item.diaUtil == true ? true : false;
                                const dataEstaEntreInicioAndFimDaProgramacao = item.dataEstaEntreInicioAndFimDaProgramacao == true ? true : false;
                                const idStatusTarefa = item.idStatusTarefa;
                                const tarefaNaoRelizada = item.tarefaNaoRelizada != null ? item.tarefaNaoRelizada : false;
                                const borderColorP = 'blackAlpha.700';
                                let corQtdDiasTarefaAtrasada = 'transparent';

                                let val01 = '';
                                let bgDiaUtil = 'transparent';
                                if (isCheckd) {
                                    val01 = item.letraTarefa;
                                    bgDiaUtil = 'white';
                                } else {
                                    val01 = item.descDiaSemana;
                                    bgDiaUtil = 'gray.500';
                                }

                                let descQtdDiasTarefaAtrasada = item.descQtdDiasTarefaAtrasada.trim();

                                if (item.idQtdDiasTarefaAtrasada == 1) {
                                    corQtdDiasTarefaAtrasada = gradientGreen;
                                } else if (item.idQtdDiasTarefaAtrasada == 2) {
                                    corQtdDiasTarefaAtrasada = gradientYellow;
                                } else if (item.idQtdDiasTarefaAtrasada == 3) {
                                    corQtdDiasTarefaAtrasada = gradientred;
                                } else if (item.idQtdDiasTarefaAtrasada == 0 && descQtdDiasTarefaAtrasada == 'D0' ) {
                                    corQtdDiasTarefaAtrasada = gradientWhite;
                                    descQtdDiasTarefaAtrasada = 'Primeira Tarefa';
                                }

                                if (idStatusTarefa == 4 || tarefaNaoRelizada) {
                                    return (
                                        <Tr key={index} bg={bgDiaUtil} borderWidth={'1px'} borderColor={borderColorP}>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.descDate}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{val01}</Text>
                                            </Td>
                                            <Td colSpan={qtdTarefas} sx={{ paddingX: largTr, paddingY: altura }} bg={'gray.200'} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} pl={'10px'}>{item.descMotivo}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} bgGradient={corQtdDiasTarefaAtrasada}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{descQtdDiasTarefaAtrasada}</Text>
                                            </Td>
                                        </Tr>
                                    )
                                } else if (item.idQtdDiasTarefaAtrasada == 0 && item.idMotivo == null && item.descQtdDiasTarefaAtrasada.trim() != 'D0') {

                                    let colorTr = isCheckd ? 'white' : 'gray.500';
                                    let colorTd = isCheckd ? 'gray.200' : 'gray.500';
                                    let labelText = isCheckd ? 'Sem Atividades' : '';

                                    if(!dataEstaEntreInicioAndFimDaProgramacao){
                                        colorTr = 'white';
                                        colorTd = 'white';
                                        labelText = '';
                                        val01 = '';
                                    }

                                    return (
                                        <Tr key={index} bg={colorTr} borderWidth={'1px'} borderColor={borderColorP}>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.descDate}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{val01}</Text>
                                            </Td>
                                            <Td colSpan={qtdTarefas} sx={{ paddingX: largTr, paddingY: altura }} bg={colorTd} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} pl={'10px'}>{labelText}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} bgGradient={corQtdDiasTarefaAtrasada}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{descQtdDiasTarefaAtrasada}</Text>
                                            </Td>
                                        </Tr>
                                    )
                                } else {
                                    return (
                                        <Tr key={index} bg={bgDiaUtil} borderWidth={'1px'} borderColor={borderColorP}>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.descDate}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{val01}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaA}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaB}</Text>
                                            </Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaC}</Text>
                                            </Td>
                                            {qtdTarefas > 3 &&
                                                <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                    <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaD}</Text>
                                                </Td>
                                            }
                                            {qtdTarefas > 4 &&
                                                <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                    <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaE}</Text>
                                                </Td>
                                            }
                                            {qtdTarefas > 5 &&
                                                <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                    <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaF}</Text>
                                                </Td>
                                            }
                                            {qtdTarefas > 6 &&
                                                <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                    <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaG}</Text>
                                                </Td>
                                            }
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} bgGradient={corQtdDiasTarefaAtrasada}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{descQtdDiasTarefaAtrasada}</Text>
                                            </Td>
                                        </Tr>
                                    )
                                }
                            })}
                            {totalSangriaPorTarefa != null &&
                                <Tr h={'30px'} bgGradient={gradientBlack} borderWidth={'1px'} borderColor={'white'}>
                                    <Td colSpan={2} sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Sangrias Realizadas'}</Text>
                                    </Td>
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaA}</Text>
                                    </Td>
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaB}</Text>
                                    </Td>
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaC}</Text>
                                    </Td>
                                    {qtdTarefas > 3 &&
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                            <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaD}</Text>
                                        </Td>
                                    }
                                    {qtdTarefas > 4 &&
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                            <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaE}</Text>
                                        </Td>
                                    }
                                    {qtdTarefas > 5 &&
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                            <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaF}</Text>
                                        </Td>
                                    }
                                    {qtdTarefas > 6 &&
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                            <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaG}</Text>
                                        </Td>
                                    }
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{''}</Text>
                                    </Td>
                                </Tr>
                            }
                            {listFrequenciaMedia.length > 0 &&
                                <Tr bgGradient={gradientBlack} borderWidth={'1px'} borderColor={'white'}>
                                    <Td colSpan={2} sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Flex justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Frequência Média'}</Text>
                                            <Center ml={'10px'} h={'100%'} justifyContent={'center'} alignItems={'end'}>
                                                <TooltipIcon
                                                    label={
                                                        <Box px={'0px'} py={'5px'} >
                                                            <Text align={'center'}>{'Frequência Média'}</Text>
                                                            <Text align={'start'} mt={'5px'} ml={'10px'} lineHeight={'100%'}>{'É calculada com a divisão dos dias uteis pelas tarefas realizadas.'}</Text>
                                                        </Box>
                                                    }
                                                    height={'15px'} color={'white'} />
                                            </Center>
                                        </Flex>
                                    </Td>
                                    {listFrequenciaMedia.map((item, index) => {
                                        let corQtdDiasTarefaAtrasada = 'transparent';
                                        let textColor = 'black';

                                        if (item.idQtdDiasTarefaAtrasada == 1) {
                                            corQtdDiasTarefaAtrasada = gradientGreen;
                                        } else if (item.idQtdDiasTarefaAtrasada == 2) {
                                            corQtdDiasTarefaAtrasada = gradientYellow;
                                        } else if (item.idQtdDiasTarefaAtrasada == 3) {
                                            corQtdDiasTarefaAtrasada = gradientred;
                                        }else{
                                            textColor = 'transparent';
                                        }
                                        return (
                                            <Td key={index} sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'} bgGradient={corQtdDiasTarefaAtrasada}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.descQtdDiasTarefaAtrasada}</Text>
                                            </Td>
                                        )
                                    })}
                                    <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{''}</Text>
                                    </Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderIndicadores() {
        const largTr = 1;
        const ftSiz = '14px';
        return (
            <Box px={'5px'} py={'5px'} borderRadius={'10px'}>
                <Box mt={{ base: 4, md: 0 }}>
                    <TableContainer w={'100%'} overflowY={'auto'} borderRadius={'10px'} borderWidth={'0px'} borderColor={'blackAlpha.700'}>
                        <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                            <Thead>
                                <Tr bgGradient={gradientHeader}>
                                    <Th colSpan={2} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Box textTransform={'none'} px={'10px'}>
                                            <Text mb={marginText} color={'white'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{'Indicador de Sangria'}</Text>
                                        </Box>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Tarefas possíveis de sangria'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{diasPossiveisPeriodo}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Total de tarefas realizadas'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={'green.500'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{realizadas}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Possíveis realizadas'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{`${percentualSangriasPossiveisRealizadas}%`}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Atrasadas no período'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={'red.600'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{realizadasMenosTotalDeDias}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Tarefas Afetadas por Chuva'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{chuvaFeriado}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Tarefas Afetadas por Faltas'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={'red.600'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{faltas}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Índice de eficiência'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{`${percentualIndiceEficiencia}%`}</Text>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    function renderQualidade() {
        const largTr = 1;
        const ftSiz = '14px';
        return (
            <Box px={'5px'} py={'5px'} mt={'10px'} borderRadius={'10px'}>
                <Box mt={{ base: 4, md: 0 }}>
                    <TableContainer w={'100%'} overflowY={'auto'} borderRadius={'10px'} borderWidth={'0px'} borderColor={'blackAlpha.700'}>
                        <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                            <Thead>
                                <Tr bgGradient={gradientHeader}>
                                    <Th colSpan={2} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Box textTransform={'none'} px={'10px'}>
                                            <Text mb={marginText} color={'white'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{'Qualidade de Sangria'}</Text>
                                        </Box>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Consumo'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalConsumo}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Ferimento'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalProfundidade}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Profundidade'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalFerimento}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Aspectos'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalAspecto}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Total de Pontos'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalPontos}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Nota dos seringueiro'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={corNota} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{`${notaSeringueiro}%`}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Avaliação da sangria'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={corNota} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{avaliacaoSangria}</Text>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    function renderAcompanhamentoDaProducao() {
        const sizeF = '13px';
        const sizeSubTotal = '15px';
        const espacoW = 0;
        const largTr = 1;
        let corLinha = false;
        return (
            <Box px={'5px'} py={'5px'} mt={'5px'} borderRadius={'10px'}>
                <TableContainer borderRadius={'10px'} borderWidth={'1px'}>
                    <Table size='sm' variant='unstyled' >
                        <Thead bg={colorFooterAndHeader}>
                            <Tr bgGradient={gradientHeader} h={'30px'}>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'start'} mb={marginText}>{'Mês'}</Text>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Center>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Previsto'}</Text>
                                        </Center>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Center>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Realizado'}</Text>
                                        </Center>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Center>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'%'}</Text>
                                        </Center>
                                    </Box>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {extratoProducao.map((item, key) => {
                                corLinha = !corLinha;
                                const mesdesc = item.mes.substring(0, 3);
                                const ttParcial = item.totalAteMesAnterior;
                                const colorBack = pessoaSelecionado == '' ? 'transparent' : 'transparent';
                                return (
                                    <Tr key={key + 1} alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.2)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                        <Td w={'10%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>

                                            <Box position={'relative'} w={'100%'}>
                                                <Flex justify='space-between' align='center' >
                                                    <Text mb={marginText} ml={'10px'} color={textColor} fontSize={sizeF} fontWeight='700' lineHeight={'100%'}>{mesdesc}</Text>
                                                    {item.listSeringueiroDeletado.length > 0 &&
                                                        <Box position={'absolute'} top={'-5px'} left={'45px'}>
                                                            <TooltipSeringueiro
                                                                label={
                                                                    <Box py={'5px'} >
                                                                        {item.listSeringueiroDeletado.map((x, y) => {
                                                                            const dtDesativado = x.dtDeletado != null ? moment(x.dtDeletado).format('DD/MM/YYYY') : '';
                                                                            return (
                                                                                <Box key={y}>
                                                                                    <Text align={'center'}>{`Seringueiro ${x.nomeSeringueiroDeletado} desativado, data: ${dtDesativado}`}</Text>
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                }
                                                                height={'22px'} />
                                                        </Box>
                                                    }
                                                </Flex>
                                            </Box>
                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={'30px'} justify='start' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} ml={'10px'} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' align={'start'}>{'Total Parcial'}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td w={'20%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.previsto}</Text>

                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{ttParcial.totalPrevistoAteMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td w={'20%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.realizado}</Text>

                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{ttParcial.totalRealizadoAteMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td w={'10%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.percentual}%`}</Text>

                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{`${ttParcial.totalPercentualAteMesAnterior}%`}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                    </Tr>
                                )
                            })}
                            <Tr bgGradient={gradientHeader}>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} fontWeight='700' mb={marginText}>{'Total ano'}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalPrevisto}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalRealizado}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${totalPercentual}%`}</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderCoagulos() {
        const ftSiz = '13px';
        const heightP = '65px';
        return (
            <Box px={'10px'} pb={'10px'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.700'}>
                <Text mb={marginText} py={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Valores Anuais (kg)'}</Text>
                <Wrap spacing='10px' justify={'space-between'} align={'center'}>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Prevista'}</Text>
                            <Text mb={marginText} color={'orange.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{totalPrevisto}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Realizada'}</Text>
                            <Text mb={marginText} color={'green.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{totalRealizado}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Saldo Acumulado'}</Text>
                            <Text mb={marginText} color={'red.600'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{saldoAcumulado}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Porcentagem Realizado'}</Text>
                            <Text mb={marginText} color={'green.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{`${porcentagemRealizada}%`}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Por Árvore Prevista'}</Text>
                            <Text mb={marginText} color={'orange.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{producaoCoagulosPorArvorePrevista}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Por Árvore Realizada'}</Text>
                            <Text mb={marginText} color={'green.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{producaoCoagulosPorArvoreRealizada}</Text>
                        </Flex>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' py='0px'>
            <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
                {renderAlert()}
                {renderMeses()}
                <Box pb={'20px'} ref={pdfRef}>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center' mb={'10px'}>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'} lineHeight={'100%'}>{'Atividades de Sangria'}</Text>
                            <Text mt={'3px'} color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                            <Text mt={'3px'} color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                        </Box>
                    </Box>
                    {renderDadosRelatorio()}
                    <Grid mb='5px' gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }} gap={{ base: "10px", xl: "10px" }} display={{ base: "block", xl: "grid" }}>
                        <Flex flexDirection='column' gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
                            {listAtividadeSangriaItem.length > 0 && renderAtividadesSangria()}
                        </Flex>
                        <Flex flexDirection='column' gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
                            {listAtividadeSangriaItem.length > 0 && renderIndicadores()}
                            {listAtividadeSangriaItem.length > 0 && renderQualidade()}
                            {listAtividadeSangriaItem.length > 0 && renderAcompanhamentoDaProducao()}
                        </Flex>
                    </Grid>
                    {renderCoagulos()}
                </Box>
            </Box>
            {loading && <LoaderBlack isOpen={loading} />}
        </Card>
    );
};
