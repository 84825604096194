import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Icon,
    Input,
    Checkbox,
    Wrap,
    Select,
    RadioGroup,
    Radio,
    Stack,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { RiEdit2Fill, RiAddCircleLine, RiArrowRightSLine, RiArrowLeftSLine, RiSearchLine, RiAccountCircleFill } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import iconExcel from "assets/img/layout/excel_icon_download.png";
import LoaderBlack from 'components/loading/LoaderBlack';

const qtdPage = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
];

export default function ListaTodosSeringueiros() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listaProdutos, setListaProdutos] = useState([]);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [checkEmProgramacao, setCheckEmProgramacao] = useState(false);
    const [checkDisponivel, setCheckDisponivel] = useState(false);
    const [checkListaTodos, setCheckListaTodos] = useState(true);
    const [checkListaTodosAdmin, setCheckListaTodosAdmin] = useState(false);
    const [isDisabledRadio, setIsDisabledRadio] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [objPermissoes, setObjPermissoes] = useState({});
    const [radioValue, setRadioValue] = useState('1');
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColorHeader = useColorModeValue("white", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
            const isAtiva = sangriaAtivaP == 'false' ? true : false;
            setSangriaAtiva(isAtiva);

            const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
            const obj = JSON.parse(val);
            setObjPermissoes(obj);

            await pesquisar();
        }
        loaderScreen();
    }, [])

    const pesquisar = async () => {
        try {
            setListaProdutos([]);
            setCurrentPage(1);

            const idSafra = localStorage.getItem('@HE-idSafra');
            const url = `api/pessoa/listaTodosSegingueirosFerificandoSeExisteUmaSafraAssociada?idSafra=${idSafra}&nome=${impuNomeSeringueiro}&emProgramacao=${checkEmProgramacao}&disponivel=${checkDisponivel}&listaTodos=${checkListaTodos}&listaTodosAdmin=${checkListaTodosAdmin}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setListaProdutos(res);
                setTelaCarregada(true);
            }
            setTelaCarregada(true);
        } catch (error) {
            setTelaCarregada(true);
            console.log('');
        }
    };

    const totalPages = Math.ceil(listaProdutos.length / itemsPerPage);
    const paginatedData = listaProdutos.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const novorSeringueiro = () => {
        history.push("/admin/form-seringueiro-cadastro");
    }

    const editarSeringueiro = (item) => {
        localStorage.setItem('@HE-datosParaEdicaoSeringueiroCadastro', JSON.stringify(item));
        history.push("/admin/form-seringueiro-cadastro");
    }

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    const changeCheckListaTodos = () => {
        const check = !checkListaTodosAdmin;
        setCheckListaTodosAdmin(check);
        if (check) {
            setIsDisabledRadio(true);

            setRadioValue('1');
            setCheckListaTodos(true);
            setCheckEmProgramacao(false);
            setCheckDisponivel(false);
        } else {
            setIsDisabledRadio(false);
        }
    }

    const changeRadio = (val) => {
        setRadioValue(val);

        if (val == '1') {
            setCheckListaTodos(true);
            setCheckEmProgramacao(false);
            setCheckDisponivel(false);
        } else if (val == '2') {
            setCheckListaTodos(false);
            setCheckEmProgramacao(true);
            setCheckDisponivel(false);
        } else {
            setCheckListaTodos(false);
            setCheckEmProgramacao(false);
            setCheckDisponivel(true);
        }
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await gerarExcel();
            setLoading(false);
        }, 1000);
    }

    const gerarExcel = async () => {
        const idSafra = localStorage.getItem('@HE-idSafra');
        const json = {
            nome: impuNomeSeringueiro,
            emProgramacao: checkEmProgramacao,
            disponivel: checkDisponivel,
            listaTodos: checkListaTodos,
            listaTodosAdmin: checkListaTodosAdmin,
            idSafra: idSafra,
        }
        const url = `api/pessoa/downloadRelatorioExcelSeringueiros`;
        await Api.postRequestDownloadExcel(url, json, 'Lista-Seringueiros');
    }

    function renderListaTodosPerfilAdmin() {
        return (
            <Box w={'100%'} mb={'0px'} >
                <Box px={'20px'} mb={'20px'}>
                    <Flex justify='space-between' mb='5px' align='center'>

                        <Flex justify='start' mb='5px' align='center'>
                            <Checkbox mr={'15px'} borderWidth={'1px'} borderColor={'blackAlpha.500'} size='lg' colorScheme='green' isChecked={checkListaTodosAdmin} onChange={() => { changeCheckListaTodos() }} />
                            <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' >{'Listar Todos os Seringueiros (ADMIN)'}</Text>
                        </Flex>
                        <Flex justify='end' mb='5px' align='center'>
                            <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={iconExcel} alt='Dan Abramov' onClick={() => { gerarRelatorioExcel() }} />
                        </Flex>
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderRadioButton() {
        return (
            <Box px={'20px'} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between" >
                <RadioGroup onChange={changeRadio} value={radioValue} defaultValue='3' isDisabled={isDisabledRadio}>
                    <Stack spacing={5} direction='row'>
                        <Radio
                            size='lg'
                            name='1'
                            value='1'
                            borderWidth={'2px'}
                            borderColor={'blue.300'}
                            _checked={{ bg: 'blue.300' }}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' >Todos</Text>
                        </Radio>
                        <Radio
                            size='lg'
                            name='1'
                            value='2'
                            borderWidth={'2px'}
                            borderColor={'green.300'}
                            _checked={{ bg: 'green.300' }}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>Com Programação</Text>
                        </Radio>
                        <Radio
                            size='lg'
                            name='1'
                            value='3'
                            borderWidth={'2px'}
                            borderColor={'orange.300'}
                            _checked={{ bg: 'orange.300' }}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>Disponível</Text>
                        </Radio>
                    </Stack>
                </RadioGroup>
            </Box>
        )
    }

    function renderFiltro() {
        return (
            <Box w={'100%'} mb={'20px'} >
                <Wrap spacing='30px' mx={'20px'} mt={'15px'} justify='space-between'>
                    <Box w={'50%'}>
                        <Input
                            value={impuNomeSeringueiro}
                            onChange={(e) => setImpuNomeSeringueiro(e.target.value)}
                            errorBorderColor='crimson'
                            variant="filled"
                            placeholder="Pesquisar por nome"
                            _placeholder={{ opacity: 0.5, color: 'inherit' }}
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                        />
                    </Box>
                    <Button rightIcon={<RiSearchLine />} variant="brand" onClick={() => { pesquisar() }}>pesquisar</Button>
                </Wrap>
            </Box>
        )
    }

    function renderSeringueiros() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        return (
            <Flex px={'10px'} direction="column" align="center">
                <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'}>
                    <Table variant="striped" colorScheme='blackAlpha' size="sm">
                        <Thead>
                            <Tr h={'35px'} bgGradient={gradientHeader}>
                                <Th w={'90%'} color={textColorHeader}>Nome</Th>
                                <Th w={'10%'} color={textColorHeader}>Editar</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {paginatedData.map((item, index) => {
                                let colorIndicator = item.associadoEmSafraVigente == 'YES' ? 'green.500' : 'orange.500';
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Flex justify='start' mb='5px' align='center'>
                                                <Icon as={RiAccountCircleFill} color={colorIndicator} w={'25px'} h={'25px'} mr={'10px'} />
                                                <Text color={textColor} fontSize='15px' fontWeight={'normal'} lineHeight='100%'>{item.nome}</Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex align='center'>
                                                <Button
                                                    disabled={sangriaAtiva}
                                                    size="sm"
                                                    color={'blue.500'}
                                                    rightIcon={<RiEdit2Fill />}
                                                    variant="solid"
                                                    key={index}
                                                    onClick={() => { editarSeringueiro(item) }}
                                                >
                                                    Editar
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                    <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                        <Flex justify='center' align='center'>
                            <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                            <Box ml={'10px'}>
                                <Select
                                    value={itemsPerPage}
                                    placeholder=""
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={'gray.200'}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    textColor={textColor}
                                    size="sm"
                                    h={'35px'}
                                    onChange={changePage}>
                                    {qtdPage.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </Flex>
                        <Flex justify='center' align='center'>
                            <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaProdutos.length}`}</Text>
                            <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                        </Flex>
                    </Flex>
                </TableContainer>
            </Flex>
        )
    }

    function renderButton() {
        return (
            <Flex px='25px' justify='end' mt='20px' align='center'>
                <Button
                    //disabled={sangriaAtiva}
                    rightIcon={<RiAddCircleLine />}
                    variant="brand"
                    onClick={() => { novorSeringueiro() }}
                >
                    Novo
                </Button>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Lista dos seringueiros cadastrados</Text>
                {objPermissoes.admin && renderListaTodosPerfilAdmin()}
                {renderRadioButton()}
                {renderFiltro()}
                {renderSeringueiros()}
                {renderButton()}
            </Box>
            <LoaderBlack isOpen={loading} />
        </Card>
    );
};
