import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Text, Progress } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function MiniStatistics(props) {

    const { textColorValue, progresss, startContent, endContent, name, growth, value } = props;
    const textColor = useColorModeValue("black", "white");
    const bg = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.4)', 'unset');

    let colorResultValue = textColorValue != null || textColorValue != '' ? textColorValue : textColor;

    return (
        <Card
            py='10px'
            align='center'
            justify={'start'}
            boxShadow={cardShadow}
            bg={bg}>

            <Flex
                my='auto'
                h='100%'
                align={{ base: "center", xl: "start" }}
                justify={{ base: "center", xl: "center" }}>

                <Stat my='auto' ms={startContent ? "18px" : "0px"}>
                    <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "sm", }}>{name}</StatLabel>
                    <StatNumber color={colorResultValue} fontSize={{ base: "xl", }}>{value}</StatNumber>
                    {growth ? (
                        <Flex align='center' justify={'center'}>
                            <Text color={textColor} fontSize='xs' fontWeight='700' me='5px'>
                                {growth}
                            </Text>
                            <Text color='secondaryGray.600' fontSize='xs' fontWeight='400'>
                                desde o último mês
                            </Text>
                        </Flex>
                    ) : null}
                </Stat>
                <Flex ms='auto' w='max-content'>
                    {endContent}
                </Flex>
            </Flex>
            {/* {progresss} */}
        </Card>
    );
}
