import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Stat,
    StatLabel,
    Image,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import * as ActionTypes from '../../../constants/ActionTypes';
import Arvore03 from 'assets/img/dashboards/arvore_03.png';
import Folha from 'assets/img/dashboards/folha_200.png';

const CALENDARIO = `${ActionTypes.LINK_GOOGLE_STORAGE}/calendario.png`;
const INDICADOR_SANGRIA = `${ActionTypes.LINK_GOOGLE_STORAGE}/indicador_sangria.png`;
const QUALIDADE_SANGRIA = `${ActionTypes.LINK_GOOGLE_STORAGE}/qualidade_sangria.png`;
const RELATORIO_EMITIDO = `${ActionTypes.LINK_GOOGLE_STORAGE}/relatorio_emitidos.png`;

export default function Relatorios() {

    const history = useHistory();
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const bg = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');
    const sizeImageCard = '70px';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
            const isAtiva = sangriaAtivaP == 'false' ? true : false;
            setSangriaAtiva(isAtiva);

            //REMOVE_CACHE_RELATORIO_PERSONALIZADO();
            //REMOVE_CACHE_RELATORIO_ESPORADICO();
        }
        loaderScreen();
    }, []);

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Opções de Relatório</Text>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 6, "2xl": 6 }} gap='20px' mb='20px' mt={'15px'}>
                    <Box 
                        pointerEvents={sangriaAtiva ? 'none' : 'auto'}
                        py='10px' 
                        align='center' 
                        justify={'start'} 
                        borderRadius={'20px'} 
                        boxShadow={cardShadow} 
                        bg={bg}
                        opacity={sangriaAtiva ? '0.5' : '1'}>
                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                <StatLabel lineHeight='100%' color={textColor} fontSize={'16px'} fontWeight={'bold'}>{'Status Diário'}</StatLabel>
                                <Flex align='end' mt={'10px'} justify={'center'}>
                                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/status-diario") }}>
                                        <Image h={sizeImageCard} w={sizeImageCard} src={CALENDARIO} />
                                    </Button>
                                </Flex>
                            </Stat>
                        </Flex>
                    </Box>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                <StatLabel lineHeight='100%' color={textColor} fontSize={'16px'} fontWeight={'bold'}>{'Indicador de Sangria'}</StatLabel>
                                <Flex align='end' mt={'10px'} justify={'center'}>
                                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/indicador-sangria") }}>
                                        <Image h={sizeImageCard} w={sizeImageCard} src={INDICADOR_SANGRIA} />
                                    </Button>
                                </Flex>
                            </Stat>
                        </Flex>
                    </Card>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                <StatLabel lineHeight='100%' color={textColor} fontSize={'16px'} fontWeight={'bold'}>{'Qualidade Sangria'}</StatLabel>
                                <Flex align='end' mt={'10px'} justify={'center'}>
                                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/lista-qualidade-sangria") }}>
                                        <Image h={sizeImageCard} w={sizeImageCard} src={QUALIDADE_SANGRIA} />
                                    </Button>
                                </Flex>
                            </Stat>
                        </Flex>
                    </Card>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                <StatLabel lineHeight='100%' color={textColor} fontSize={'16px'} fontWeight={'bold'}>{'Relatórios Emitidos'}</StatLabel>
                                <Flex align='end' mt={'10px'} justify={'center'}>
                                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/relatorio-emitido-personalizado-esporadido") }}>
                                        <Image h={sizeImageCard} w={sizeImageCard} src={RELATORIO_EMITIDO} />
                                    </Button>
                                </Flex>
                            </Stat>
                        </Flex>
                    </Card>
                    <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                <StatLabel lineHeight='100%' color={textColor} fontSize={'16px'} fontWeight={'bold'}>{'Produção por Sangria'}</StatLabel>
                                <Flex align='end' mt={'10px'} justify={'center'}>
                                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/producao-por-sangria") }}>
                                        <Image h={sizeImageCard} w={sizeImageCard} src={Arvore03} />
                                    </Button>
                                </Flex>
                            </Stat>
                        </Flex>
                    </Card>
                    {/* <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                <StatLabel lineHeight='100%' color={textColor} fontSize={'16px'} fontWeight={'bold'}>{'Atividades de Sangria'}</StatLabel>
                                <Flex align='end' mt={'10px'} justify={'center'}>
                                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/relatorio-atividades-sangria") }}>
                                        <Image h={sizeImageCard} w={sizeImageCard} src={Folha} />
                                    </Button>
                                </Flex>
                            </Stat>
                        </Flex>
                    </Card> */}
                </SimpleGrid>
            </Card>
        </Box>
    );
}
