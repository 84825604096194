import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Input,
    Wrap,
    Icon,
    Select,
    Center,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { RiAccountCircleFill, RiSearchLine, RiArrowRightSLine, RiArrowLeftSLine, RiArrowRightUpLine } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';

const qtdPage = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
];

export default function ListaSeringueirosDisponiveis() {

    const history = useHistory();
    const [listaProdutos, setListaProdutos] = useState([]);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const colorFooterAndHeader = useColorModeValue("orange.600", "black");
    const textColorHeader = useColorModeValue("white", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");

    useEffect(() => {
        async function loaderScreen() {
            pesquisar();
        }
        loaderScreen();
    }, [])

    const pesquisar = async () => {
        try {
            setListaProdutos([]);
            setCurrentPage(1);

            const response = await Api.getRequest(`api/pessoa/listaPessoasDisponiveis?nome=${impuNomeSeringueiro}`);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setListaProdutos(res);
            }
        } catch (error) {
            console.log('');
        }
    };

    const totalPages = Math.ceil(listaProdutos.length / itemsPerPage);
    const paginatedData = listaProdutos.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const adicionarSeringuerio = (item) => {
        localStorage.setItem('@HE-retornoTelaSeringueiroDisponivel', JSON.stringify(item));
        history.goBack();
    }

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    function renderInfo() {
        return (
            <Box position={'relative'} mx={'20px'} borderRadius={'20px'} mb={'20px'} bgGradient={ActionTypes.LINEAR_GRADIENT_ORANGE}>
                <Flex px={'10px'} py={'20px'} justify={'center'} align="center">
                    <Box w={'75%'}>
                        <Text color={'gray.500'} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Os seringueiros listados estão disponíveis, ou seja, não estão associados a nenhuma safra vigente ou programação.'}</Text>
                        <Text color={'gray.400'} fontSize='16px' fontWeight={'light'} align={'center'} lineHeight='100%'>{'Caso não encontre um nome específico na listagem, você pode cadastra-lo'}</Text>
                    </Box>
                </Flex>
                <Box position={'absolute'} top={'10px'} right={'10px'}>
                    <Button variant="brand" size="sm" onClick={() => { history.push("/admin/lista-todos-seringueiros") }}>Novo</Button>
                </Box>
            </Box>
        )
    }

    function renderFiltro() {
        return (
            <Box w={'100%'} mb={'20px'}>
                <Wrap spacing='30px' mx={'20px'} mt={'1px'} justify='start'>
                    <Box w={'50%'}>
                        <Input
                            value={impuNomeSeringueiro}
                            onChange={(e) => setImpuNomeSeringueiro(e.target.value)}
                            errorBorderColor='crimson'
                            variant="filled"
                            placeholder="Pesquisar por nome"
                            _placeholder={{ opacity: 0.5, color: 'inherit' }}
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                        />
                    </Box>
                    <Center justifyContent={'center'}>
                        <Button size="sm" rightIcon={<RiSearchLine />} variant="brand" onClick={() => { pesquisar() }}>pesquisar</Button>
                    </Center>
                </Wrap>
            </Box>
        )
    }

    function renderSeringueiros() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        return (
            <Flex px={'10px'} direction="column" align="center">
                <Table variant="striped" colorScheme='blackAlpha' size="sm">
                    <Thead>
                        <Tr h={'35px'}>
                            <Th w={'90%'} color={textColorHeader} bg={colorFooterAndHeader} borderTopLeftRadius={'10px'}>Nome</Th>
                            <Th w={'10%'} color={textColorHeader} bg={colorFooterAndHeader} borderTopRightRadius={'10px'}>Editar</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {paginatedData.map((item, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>
                                        <Flex justify='start' mb='5px' align='center'>
                                            <Icon as={RiAccountCircleFill} color={'#e67300'} w={'25px'} h={'25px'} mr={'10px'} />
                                            <Text color={textColor} fontSize='15px' fontWeight={'normal'} lineHeight='100%'>{item.nome}</Text>
                                        </Flex>
                                    </Td>
                                    <Td>
                                        <Flex align='center'>
                                            <Button
                                                size="sm"
                                                color={'blue.500'}
                                                rightIcon={<RiArrowRightUpLine />}
                                                variant="solid"
                                                key={index}
                                                onClick={() => { adicionarSeringuerio(item) }}
                                            >
                                                Adicionar
                                            </Button>
                                        </Flex>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
                <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                    <Flex justify='center' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                        <Box ml={'10px'}>
                            <Select
                                value={itemsPerPage}
                                placeholder=""
                                variant="filled"
                                borderRadius="10px"
                                borderColor={'gray.200'}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                textColor={textColor}
                                size="sm"
                                h={'35px'}
                                onChange={changePage}>
                                {qtdPage.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaProdutos.length}`}</Text>
                        <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                        <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    function renderButton() {
        return (
            <Flex px='25px' justify='start' mt='20px' align='center'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
                {renderInfo()}
                {renderFiltro()}
                {renderSeringueiros()}
                {renderButton()}
            </Box>
        </Card>
    );
};
