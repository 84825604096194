import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    Select,
    Icon,
    Stack,
    Box,
    WrapItem,
    Center,
    Wrap,
    Checkbox,
    Radio,
    RadioGroup,
    Switch,
    Image,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    TableContainer,
} from "@chakra-ui/react";
import {
    ARRAY_TAREFA,
    FREQUENCIA,
    CLONES,
    IDADE_SANGRIA,
    PAINEL,
    ARRAY_DIAS_UTEIS_DA_SEMANA,
    NOMENCLATURA_PAINEL,
} from '../../../../model/MockData';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { mascaraTelefone, retiraMascaraMaterNumeros, mascaraCpf } from '../../../../util/Masck';
import { useHistory } from "react-router-dom";
import { RiAlertFill, RiAccountCircleFill } from "react-icons/ri";
import TooltipIcon from 'components/tooltip/TooltipIcon';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function AssociarSeringueiroSafra() {

    const history = useHistory();

    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [insertOuUpdateP, setInsertOuUpdateP] = useState('');
    const [idUsuarioP, setIdUsuarioP] = useState(null);
    const [imputCpf, setImputCpf] = useState('');
    const [imputTamanhoLote, setImputTamanhoLote] = useState('');
    const [idUaP, setIdUaP] = useState(null);
    const [idSafraP, setIdSafraP] = useState(null);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [isValidQtdArvore, setIsValidQtdArvore] = useState(false);
    const [isValidProducaoEsperada, setIsValidProducaoEsperada] = useState(false);

    const [MONDAY, setMONDAY] = useState(false);
    const [TUESDAY, setTUESDAY] = useState(false);
    const [WEDNESDAY, setWEDNESDAY] = useState(false);
    const [THURSDAY, setTHURSDAY] = useState(false);
    const [FRIDAY, setFRIDAY] = useState(false);
    const [SATURDAY, setSATURDAY] = useState(false);
    const [SUNDAY, setSUNDAY] = useState(false);
    const [isValidDiasSemana, setIsValidDiasSemana] = useState(false);
    const [radioSexoP, setRadioSexoP] = useState('MASCULINO');
    const [cloneSelecionado, setCloneSelecionado] = useState('');

    const [painelSelecionado, setPainelSelecionado] = useState('');
    const [idadeSangriaSelecionado, setIdadeSangriaSelecionado] = useState('');
    const [idSafraPessoaP, setIdSafraPessoaP] = useState(null);
    const [idPessoaP, setIdPessoaP] = useState(null);
    const [pessoaAtivaP, setPessoaAtivaP] = useState(true);
    const [valueImputTelefone, setValueImputTelefone] = useState('');
    const [valueImputCelular, setValueImputCelular] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [alternanciaSelecionado, setAlternanciaSelecionado] = useState('3');
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [desabilitaTodos, setDesabilitaTodos] = useState(false);

    const [tamanhoLoteSelects, setTamanhoLoteSelects] = useState([]);
    const [producaoLoteSelects, setProducaoLoteSelects] = useState([{ id: 0, value: '' }]);
    const [cloneSelects, setCloneSelects] = useState([]);
    const [idadeSangriaSelects, setIdadeSangriaSelects] = useState([]);
    const [tipoSangriaSelects, setTipoSangriaSelects] = useState([]);
    const [arrayTarefas, setArrayTarefas] = useState([]);
    const [arrayNomenclatura, setArrayNomenclatura] = useState([]);

    const [totalQuantidadeArvores, setTotalQuantidadeArvores] = useState('');
    const [totalProducaoEsperada, setTotalProducaoEsperada] = useState('');
    const [totalQuantidadeArvoresCopia, setTotalQuantidadeArvoresCopia] = useState('');
    const [totalProducaoEsperadaCopia, setTotalProducaoEsperadaCopia] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [idTipoCadastroTarefa, setIdTipoCadastroTarefa] = useState('2');
    const [isExisteProgramacao, setIsExisteProgramacao] = useState(false);
    const [arrayDadosGeralCompleto, setArrayDadosGeralCompleto] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            setIsVisibleAlert(false);

            const dados = localStorage.getItem('@HE-datosParaEdicaoSeringueiro');

            if (dados != null) {
                const dadosProdutor = JSON.parse(dados);
                const insertOuUpdate = dadosProdutor.insertOuUpdate;
                const idUser = dadosProdutor.idUsuario;
                const idUa = dadosProdutor.idUa;
                const idSafra = dadosProdutor.idSafra;

                setIdUsuarioP(idUser);
                setIdUaP(idUa);
                setIdSafraP(idSafra);
                setNomeFazenda(dadosProdutor.nomeFazenda);

                if (insertOuUpdate == 'update') {
                    setInsertOuUpdateP('update');

                    const idPessoa = dadosProdutor.idPessoa;
                    const idSafraPessoa = dadosProdutor.idSafraPessoa;
                    setIdSafraPessoaP(idSafraPessoa);
                    setIdPessoaP(idPessoa);

                    const url3 = `api/pessoa/pesquisaPessoaParaEdicao?idUa=${idUa}&idSafra=${idSafra}&idSafraPessoa=${idSafraPessoa}&idPessoa=${idPessoa}`;
                    const response = await Api.getRequest(url3);
                    if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                        const res = await response.respostaRequisicao.data;

                        const objPessoa = res.pessoaParaEdicao.pessoa;
                        const objSafra = res.pessoaParaEdicao.safra;
                        const arrayListaDiasUteis = res.pessoaParaEdicao.listaDiasUteis;
                        const idQuantidadeTarefas = res.pessoaParaEdicao.idQuantidadeTarefas;
                        const objSafraPessoa = res.pessoaParaEdicao.safraPessoa;
                        const isExistePrograma = res.pessoaParaEdicao.isExisteProgramacao;

                        const listAtividadesGeral = res.pessoaParaEdicao.listAtividadesGeralItem;
                        setArrayDadosGeralCompleto(listAtividadesGeral);
                        atualizaDadosGeralNaTela(`${idQuantidadeTarefas}`, listAtividadesGeral);      // CARREGA OS CAMPOS DINAMICOS
                        setIsExisteProgramacao(isExistePrograma);
                        setAlternanciaSelecionado(`${idQuantidadeTarefas}`);
                        setImpuNomeSeringueiro(objPessoa.nome != null && objPessoa.nome != '' ? objPessoa.nome : '');
                        setImputCpf(objPessoa.cpf != null && objPessoa.cpf != '' ? mascaraCpf(objPessoa.cpf) : '');
                        setValueImputTelefone(objPessoa.telefoneFixo != null && objPessoa.telefoneFixo != '' ? mascaraTelefone(objPessoa.telefoneFixo) : '');
                        setValueImputCelular(objPessoa.celular != null && objPessoa.celular != '' ? mascaraTelefone(objPessoa.celular) : '');
                        setRadioSexoP(objPessoa.sexo != null && objPessoa.sexo != '' ? objPessoa.sexo : 'MASCULINO');
                        setPessoaAtivaP(objPessoa.ativo != null && objPessoa.ativo != '' ? true : false);
                        const sangriaAtivaX = objSafra.sangriaAtiva != null && objSafra.sangriaAtiva != '' ? objSafra.sangriaAtiva : false;

                        setSangriaAtiva(sangriaAtivaX);

                        if (isExistePrograma == true && sangriaAtivaX == true) {
                            setDesabilitaTodos(true);
                        } else {
                            setDesabilitaTodos(false);
                        }

                        const tl = objSafraPessoa.tamanhoLote != null && objSafraPessoa.tamanhoLote != '' ? objSafraPessoa.tamanhoLote : '';
                        const idadeSangria = objSafraPessoa.idadeSangria != null && objSafraPessoa.idadeSangria != '' ? objSafraPessoa.idadeSangria : '';
                        const idPainel = objSafraPessoa.idPainel != null && objSafraPessoa.idPainel != '' ? objSafraPessoa.idPainel : '';
                        const idClone = objSafraPessoa.idClone != null && objSafraPessoa.idClone != '' ? objSafraPessoa.idClone : '';
                        const ps = objSafraPessoa.producaoEsperada != null && objSafraPessoa.producaoEsperada != '' ? objSafraPessoa.producaoEsperada : '';
                        const qtdAr = objSafraPessoa.quantidadeArvores != null && objSafraPessoa.quantidadeArvores != '' ? objSafraPessoa.quantidadeArvores : '';
                        const idTipoCadastroTare = objSafraPessoa.idTipoCadastroTarefa != null && objSafraPessoa.idTipoCadastroTarefa != '' ? `${objSafraPessoa.idTipoCadastroTarefa}` : '2';

                        setIdTipoCadastroTarefa(idTipoCadastroTare);
                        setTotalProducaoEsperada(`${ps}`);
                        setTotalProducaoEsperadaCopia(`${ps}`);
                        setTotalQuantidadeArvores(`${qtdAr}`);
                        setTotalQuantidadeArvoresCopia(`${qtdAr}`);
                        setImputTamanhoLote(`${tl}`);
                        setCloneSelecionado(`${idClone}`);
                        setPainelSelecionado(`${idPainel}`);
                        setIdadeSangriaSelecionado(`${idadeSangria}`);

                        arrayListaDiasUteis.map(x => {
                            const desc = x != null && x != '' ? x : '';
                            selecionarDiasSemana3(`${desc}`);
                        })
                        setTelaCarregada(true);
                    }
                } else {
                    setInsertOuUpdateP('insert');
                    const array = [];
                    setIdTipoCadastroTarefa('2');
                    atualizaDadosGeralNaTela('3', array);

                    const res5 = localStorage.getItem('@HE-retornoTelaSeringueiroDisponivel');
                    if (res5 != null) {
                        const objPessoa = JSON.parse(res5);

                        setIdPessoaP(objPessoa.idPessoa);
                        setImpuNomeSeringueiro(objPessoa.nome != null && objPessoa.nome != '' ? objPessoa.nome : '');
                        setImputCpf(objPessoa.cpf != null && objPessoa.cpf != '' ? mascaraCpf(objPessoa.cpf) : '');
                        setValueImputTelefone(objPessoa.telefoneFixo != null && objPessoa.telefoneFixo != '' ? mascaraTelefone(objPessoa.telefoneFixo) : '');
                        setValueImputCelular(objPessoa.celular != null && objPessoa.celular != '' ? mascaraTelefone(objPessoa.celular) : '');
                        setRadioSexoP(objPessoa.sexo != null && objPessoa.sexo != '' ? objPessoa.sexo : 'MASCULINO');

                        localStorage.removeItem('@HE-retornoTelaSeringueiroDisponivel');
                        setDesabilitaTodos(false);
                    } else {
                        setDesabilitaTodos(true);
                    }
                    ARRAY_DIAS_UTEIS_DA_SEMANA.map(x => {
                        const desc = x != null && x != '' ? x : '';
                        selecionarDiasSemana3(`${desc}`);
                    })
                    setTelaCarregada(true);
                }
            }
        }
        loaderScreen();
    }, [])

    const selecionarDiasSemana3 = async (val) => {
        switch (val) {
            case 'MONDAY':
                setMONDAY(true);
                break;
            case 'TUESDAY':
                setTUESDAY(true);
                break;
            case 'WEDNESDAY':
                setWEDNESDAY(true);
                break;
            case 'THURSDAY':
                setTHURSDAY(true);
                break;
            case 'FRIDAY':
                setFRIDAY(true);
                break;
            case 'SATURDAY':
                setSATURDAY(true);
                break;
            case 'SUNDAY':
                setSUNDAY(true);
                break;
            default:
                break;
        }
    }

    const selecionarDiasSemana = async (e) => {

        const val = e.target.value;

        switch (val) {
            case 'MONDAY':
                setMONDAY(!MONDAY);
                break;
            case 'TUESDAY':
                setTUESDAY(!TUESDAY);
                break;
            case 'WEDNESDAY':
                setWEDNESDAY(!WEDNESDAY);
                break;
            case 'THURSDAY':
                setTHURSDAY(!THURSDAY);
                break;
            case 'FRIDAY':
                setFRIDAY(!FRIDAY);
                break;
            case 'SATURDAY':
                setSATURDAY(!SATURDAY);
                break;
            case 'SUNDAY':
                setSUNDAY(!SUNDAY);
                break;
            default:
                break;
        }
    }

    const validarAndcadastrarPessoa = async () => {
        try {
            let valid = false;
            setIsVisibleAlert(false);
            const arrayP = await montarArrayItensDinamicos();

            const arrayDiasSemanaP = [
                { descDiaSemana: "", dayOfWeek: "MONDAY", status: MONDAY },
                { descDiaSemana: "", dayOfWeek: "TUESDAY", status: TUESDAY },
                { descDiaSemana: "", dayOfWeek: "WEDNESDAY", status: WEDNESDAY },
                { descDiaSemana: "", dayOfWeek: "THURSDAY", status: THURSDAY },
                { descDiaSemana: "", dayOfWeek: "FRIDAY", status: FRIDAY },
                { descDiaSemana: "", dayOfWeek: "SATURDAY", status: SATURDAY },
                { descDiaSemana: "", dayOfWeek: "SUNDAY", status: SUNDAY }
            ]
            const filtro = arrayDiasSemanaP.filter(x => x.status == true);
            const qtdProgramaca = filtro.length;

            if (qtdProgramaca < 4) {
                setIsValidDiasSemana(true);
                valid = true;
            } else {
                setIsValidDiasSemana(false);
            }
            if (totalQuantidadeArvores.trim().length < 1) {
                setIsValidQtdArvore(true);
                valid = true;
            } else {
                setIsValidQtdArvore(false);
            }
            if (totalProducaoEsperada.trim().length < 1) {
                setIsValidProducaoEsperada(true);
                valid = true;
            } else {
                setIsValidProducaoEsperada(false);
            }

            if (valid) {
                setNumStatusResposta(ActionTypes.CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS);
                showAlert();
                window.scrollTo(0, 0);
                return;
            }

            const cpfSemMascara = retiraMascaraMaterNumeros(imputCpf);
            const telefoneSemMascara = retiraMascaraMaterNumeros(valueImputTelefone);
            const celularSemMascara = valueImputCelular != '' ? retiraMascaraMaterNumeros(valueImputCelular) : null;

            const jsonPessoa = {
                idUsuario: idUsuarioP,
                idPessoa: idPessoaP,
                idUa: idUaP,
                idSafra: idSafraP,
                idSafraPessoa: idSafraPessoaP,
                nome: impuNomeSeringueiro,
                celular: `${celularSemMascara}`,
                ativo: pessoaAtivaP,
                urlImagemPerfil: null,
                dtCadastro: null,       // ESSE CARA O BACK INFORMA
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                idClone: cloneSelecionado,
                sexo: radioSexoP == 'MASCULINO' ? ActionTypes.SEXO_MASCULINO : ActionTypes.SEXO_FEMININO,
                cpf: cpfSemMascara,
                telefoneFixo: telefoneSemMascara,
                //quantidadeArvores: imputQtdArvore,
                quantidadeArvores: totalQuantidadeArvores,
                producaoEsperada: totalProducaoEsperada,
                //producaoEsperada: imputProducaoEsperada,
                tamanhoLote: imputTamanhoLote,
                arrayDiasSemana: arrayDiasSemanaP,
                qtdProgramacaoSemana: qtdProgramaca,
                qtdTarefas: alternanciaSelecionado,
                idPainel: painelSelecionado != '' ? painelSelecionado : null,
                idadeSangria: idadeSangriaSelecionado != '' ? idadeSangriaSelecionado : null,
                permitirRodarProgramacao: false,        // ESSE CARA O BACK INFORMA
                listAtividadesGeralItem: arrayP,
                idTipoCadastroTarefa: idTipoCadastroTarefa,
                dtCadastro: null,       // ESSE CARA O BACK INFORMA
            }

            /*
            ╔═══════════════════════════════════════════════════════════════════════════╗
                NO UPDATE VALIDO CPF E CELULAR FOI ALTERADO, SE SIM O BACK-END
                VERIFICA SE OS NOVOS CPF OU CELULAR JA EXISTE NA BASE
            ╚═══════════════════════════════════════════════════════════════════════════╝*/
            if (insertOuUpdateP == 'update') {
                const response = await Api.putRequest(`api/pessoa/alterarAssociacaoPessoaSafra`, jsonPessoa);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    window.scrollTo(0, 0);
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();

                } else {
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            } else {
                /*
                ╔═══════════════════════════════════════════════════════════════════════════╗
                    NO INSERT VALIDO CPF E CELULAR SE JA EXISTE
                ╚═══════════════════════════════════════════════════════════════════════════╝*/
                const response = await Api.postRequest(`api/pessoa/inserirPessoaEmUmaSafra`, jsonPessoa);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    setOpenModalConfimacao(true);
                } else {
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            }
            atualizaDadosGeralNaTela(alternanciaSelecionado, arrayP);
        } catch (error) {
            console.log('Error na classe AssociarSeringueiroSafra metodo validarAndcadastrarPessoa', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const montarArrayItensDinamicos = async () => {
        const num = Number(alternanciaSelecionado);
        const array = [];

        for (let i = 0; i < num; i++) {

            const idtividadesGeralPessoa = arrayDadosGeralCompleto[i] != undefined ? arrayDadosGeralCompleto[i].idtividadesGeralPessoa : null;
            const tamanhoLote = tamanhoLoteSelects[i].value != '' ? tamanhoLoteSelects[i].value : '0';
            const producaoLote = producaoLoteSelects[i].value != '' ? producaoLoteSelects[i].value : '0';
            const clone = cloneSelects[i].value;
            const tipoSangria = tipoSangriaSelects[i].value;
            const idadeSangria = idadeSangriaSelects[i].value;
            const idTipoNomenclaturaPainel = arrayNomenclatura[i].value;
            const idTarefa = ARRAY_TAREFA[i].value;

            const json = {
                idtividadesGeralPessoa: idtividadesGeralPessoa,
                idSafraPessoa: idSafraPessoaP,
                idSafra: idSafraP,
                idPessoa: idPessoaP,
                idTarefa: idTarefa,
                letraTarefa: '',        // ESSE CARA EU PEGO NO BACK
                tamanhoLote: tamanhoLote,
                producaoLote: producaoLote,
                idClone: clone,
                idPainel: tipoSangria,
                idadeSangria: idadeSangria,
                idTipoNomenclaturaPainel: idTipoNomenclaturaPainel,
            }
            array.push(json);
        }
        return array;
    }

    const changeAtivo = (e) => {
        const val = e.target.checked;
        setPessoaAtivaP(val);
    }

    const atualizaDadosGeralNaTela = async (val = '3', arrayP = []) => {
        try {
            const num = Number(val);
            const array = arrayP;

            let arrTamanhoLote = [];
            let arrProducaoLote = [];
            let arrClone = [];
            let arrTipoSangria = [];
            let arrIdadeSangria = [];
            let arrTarefa = [];
            let arrNomenclaturaPainel = [];

            if (num > 0) {
                for (let i = 0; i < num; i++) {

                    if (array[i] != undefined) {
                        const item = array[i];

                        const val1 = item.tamanhoLote != null && item.tamanhoLote != 0 ? `${item.tamanhoLote}` : '';
                        const jsonTamanhoLote = { id: i, value: val1 };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const val2 = item.producaoLote != null && item.producaoLote != 0 ? `${item.producaoLote}` : '';
                        const jsonProducaoLote = { id: i, value: val2 };
                        arrProducaoLote.push(jsonProducaoLote);

                        const val3 = item.idClone != null && item.idClone != 0 ? `${item.idClone}` : '2';
                        const jsonClone = { id: i, value: val3 };
                        arrClone.push(jsonClone);

                        const val4 = item.idadeSangria != null && item.idadeSangria != 0 ? `${item.idadeSangria}` : '1';
                        const jsonIdadeSangria = { id: i, value: val4 };
                        arrIdadeSangria.push(jsonIdadeSangria);

                        const val5 = item.idPainel != null && item.idPainel != 0 ? `${item.idPainel}` : '1';
                        const jsonTipoSangria = { id: i, value: val5 };
                        arrTipoSangria.push(jsonTipoSangria);

                        const val6 = item.idTipoNomenclaturaPainel != null && item.idTipoNomenclaturaPainel != 0 ? `${item.idTipoNomenclaturaPainel}` : '1';
                        const jsonNomenclaturaPainel = { id: i, value: val6 };
                        arrNomenclaturaPainel.push(jsonNomenclaturaPainel);

                        const jsonTarefa = {
                            id: i,
                            value: ARRAY_TAREFA[i].value,
                            label: ARRAY_TAREFA[i].label,
                        }
                        arrTarefa.push(jsonTarefa);

                    } else {
                        const jsonTamanhoLote = { id: i, value: '' };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const jsonProducaoLote = { id: i, value: '' };
                        arrProducaoLote.push(jsonProducaoLote);

                        const jsonClone = { id: i, value: '2' };
                        arrClone.push(jsonClone);

                        const jsonIdadeSangria = { id: i, value: '1' };
                        arrIdadeSangria.push(jsonIdadeSangria);

                        const jsonTipoSangria = { id: i, value: '1' };
                        arrTipoSangria.push(jsonTipoSangria);

                        const jsonNomenclaturaPainel = { id: i, value: '1' };
                        arrNomenclaturaPainel.push(jsonNomenclaturaPainel);

                        const jsonTarefa = {
                            id: i,
                            value: ARRAY_TAREFA[i].value,
                            label: ARRAY_TAREFA[i].label,
                        }
                        arrTarefa.push(jsonTarefa);
                    }
                }
            } else {
                for (let i = 0; i < 3; i++) {

                    const jsonTamanhoLote = { id: i, value: '' };
                    arrTamanhoLote.push(jsonTamanhoLote);

                    const jsonProducaoLote = { id: i, value: '' };
                    arrProducaoLote.push(jsonProducaoLote);

                    const jsonClone = { id: i, value: '2' };
                    arrClone.push(jsonClone);

                    const jsonIdadeSangria = { id: i, value: '1' };
                    arrIdadeSangria.push(jsonIdadeSangria);

                    const jsonTipoSangria = { id: i, value: '1' };
                    arrTipoSangria.push(jsonTipoSangria);

                    const jsonNomenclaturaPainel = { id: i, value: '1' };
                    arrNomenclaturaPainel.push(jsonNomenclaturaPainel);

                    const jsonTarefa = {
                        id: i,
                        value: ARRAY_TAREFA[i].value,
                        label: ARRAY_TAREFA[i].label,
                    }
                    arrTarefa.push(jsonTarefa);
                }
            }
            setTamanhoLoteSelects(arrTamanhoLote);
            setProducaoLoteSelects(arrProducaoLote);
            setCloneSelects(arrClone);
            setIdadeSangriaSelects(arrIdadeSangria);
            setTipoSangriaSelects(arrTipoSangria);
            setArrayTarefas(arrTarefa);
            setArrayNomenclatura(arrNomenclaturaPainel);

        } catch (error) {
            console.log('Erro na classe AssociarSeringueiroSafra metodo atualizaDadosGeralNaTela', error);
        }
    }

    const changeAlternancia = (val) => {
        setAlternanciaSelecionado(val);
        atualizaDadosGeralNaTela(val, arrayDadosGeralCompleto);
    }

    const changeSelectTamanhoLote = async (id, event) => {      // TAMANHO LOTE
        const { value } = event.target;
        const val = retiraMascaraMaterNumeros(value);
        let tt = 0;
        let temp = tamanhoLoteSelects.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    value: val,
                };
            }
            if (id != product.id) {
                tt = product.value != '' ? tt + Number(product.value) : tt + 0;
            }
            return product;
        });
        setTamanhoLoteSelects(temp);
        setTotalQuantidadeArvores(`${tt}`)
    }

    const changeSelectProducaoLote = async (id, event) => {          // PRODUCAO LOTE
        const { value } = event.target;
        const val = retiraMascaraMaterNumeros(value);
        let tt = 0;
        let temp = producaoLoteSelects.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    value: val,
                };
            }
            if (id != product.id) {
                tt = product.value != '' ? tt + Number(product.value) : tt + 0;
            }
            return product;
        });
        setProducaoLoteSelects(temp);
        setTotalProducaoEsperada(`${tt}`)
    }

    const changeSelectClone = (id, event) => {                  // CLONE
        const { value } = event.target;
        setCloneSelects(prevSelects =>
            prevSelects.map(select =>
                select.id === id ? { ...select, value: value } : select
            )
        );
    };
    const changeSelectIdadeSangria = (id, event) => {          // IDADE SANGRIA
        const { value } = event.target;
        setIdadeSangriaSelects(prevSelects =>
            prevSelects.map(select =>
                select.id === id ? { ...select, value: value } : select
            )
        );
    };
    const changeSelectTipoSangria = (id, event) => {          // TIPO SANGRIA
        const { value } = event.target;
        setTipoSangriaSelects(prevSelects =>
            prevSelects.map(select =>
                select.id === id ? { ...select, value: value } : select
            )
        );
    };
    const changeNomenclaturaPainel = (id, event) => {          // NOMENCLATURA PAINEL
        const { value } = event.target;
        setArrayNomenclatura(prevSelects =>
            prevSelects.map(select =>
                select.id === id ? { ...select, value: value } : select
            )
        );
    };

    const rarioOpcoes = (val) => {
        setIdTipoCadastroTarefa(val);

        if (val == '1') {
            const arrTamLote = tamanhoLoteSelects.map((item) => {
                return { ...item, value: '0' };
            });
            const arrProLote = producaoLoteSelects.map((item) => {
                return { ...item, value: '0' };
            });
            setTamanhoLoteSelects(arrTamLote);
            setProducaoLoteSelects(arrProLote);
        } else {
            atualizaDadosGeralNaTela(alternanciaSelecionado, arrayDadosGeralCompleto);
            setTotalProducaoEsperada(totalProducaoEsperadaCopia);
            setTotalQuantidadeArvores(totalQuantidadeArvoresCopia);
        }
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    function renderMensagem() {
        return (
            <Box mx={'20px'} borderRadius={'10px'} mb={'5px'} bgGradient={ActionTypes.LINEAR_GRADIENT_ORANGE}>
                <Flex h={'40px'} justify='center' align='center'>
                    <Icon as={RiAlertFill} color={'orange.500'} w={6} h={6} mr={'15px'} />
                    <Text color={'blackAlpha.700'} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'center'}>{'A programação para essa safra está em andamento, neste momento alguns dados não podem ser alterados.'}</Text>
                </Flex>
            </Box>
        )
    }

    function renderDadosSeringueiro() {
        const bgGradientP = idPessoaP != null ? ActionTypes.LINEAR_GRADIENT_GREEN : ActionTypes.LINEAR_GRADIENT_ORANGE;
        return (
            <Box mx={'20px'} py={'3px'} mt={'10px'} bgGradient={bgGradientP} borderRadius={'10px'}>
                <Flex px='10px' justify='space-between' align='center'>
                    <Box w={'55px'}>
                        <Icon as={RiAccountCircleFill} color={'orange.300'} w={'30px'} h={'30px'} />
                    </Box>
                    <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%' align={'center'}>Dados Seringueiro</Text>
                    <Box>
                        <Button
                            disabled={insertOuUpdateP == 'update' ? true : false}
                            _hover={{ boxShadow: "none" }}
                            h="50px"
                            bg="transparent"
                            onClick={() => { history.push("/admin/lista-seringueiros-disponiveis") }}>
                            <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                                <Image h={'30px'} w={'30px'} src={`${ActionTypes.LINK_GOOGLE_STORAGE}/usa.png`} alt="seta" />
                                <Text color={textColor} fontSize='13px' fontWeight='bold'>{'Add'}</Text>
                            </Flex>
                        </Button>
                    </Box>
                </Flex>
                {idPessoaP != null ?
                    <Wrap spacing='0px' mx={'10px'} mb={'15px'} justify='space-between'>
                        <WrapItem>
                            <Flex px='10px' justify='start' align='center'>
                                <Text ml={'1px'} color={textColor} fontSize='13px' fontWeight='700' lineHeight='100%'>Seringueiro</Text>
                                <Text ml={'15px'} color={textColor} fontSize='13px' fontWeight='100' lineHeight='100%'>{impuNomeSeringueiro}</Text>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex px='10px' justify='start' align='center' >
                                <Text ml={'1px'} color={textColor} fontSize='13px' fontWeight='700' lineHeight='100%'>CPF</Text>
                                <Text ml={'15px'} color={textColor} fontSize='13px' fontWeight='100' lineHeight='100%'>{imputCpf}</Text>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex px='10px' justify='start' align='center' >
                                <Text ml={'1px'} color={textColor} fontSize='13px' fontWeight='700' lineHeight='100%'>Celular</Text>
                                <Text ml={'15px'} color={textColor} fontSize='13px' fontWeight='100' lineHeight='100%'>{valueImputCelular}</Text>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex px='10px' justify='start' align='center' >
                                <Text ml={'1px'} color={textColor} fontSize='13px' fontWeight='700' lineHeight='100%'>Telefone Fixo</Text>
                                <Text ml={'15px'} color={textColor} fontSize='13px' fontWeight='100' lineHeight='100%'>{valueImputTelefone}</Text>
                            </Flex>
                        </WrapItem>
                    </Wrap>
                    :
                    <Box mb={'10px'}>
                        <Text color={'gray.400'} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'center'}>Adicione um Seringueiro</Text>
                        <Text color={'gray.400'} fontSize='13px' fontWeight='bold' lineHeight='100%' align={'center'}>Clique em Add para acessar a lista de seringueiros disponiveis</Text>
                    </Box>
                }
            </Box>
        )
    }

    function renderAlternancia() {
        return (
            <Box py={'10px'} px={'20px'}>
                <Flex justify={'space-between'} align={'center'}>
                    <Flex justify={'center'} align={'center'}>
                        <Box>
                            <Text color={desabilitaTodos ? 'gray.400' : textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>Frequência *</Text>
                            <Select
                                value={alternanciaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'30px'}
                                w={'160px'}
                                disabled={desabilitaTodos}
                                onChange={e => changeAlternancia(e.target.value)}
                            >
                                {FREQUENCIA.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Flex ml={'10px'} h={'45px'} justify={'flex-end'} align={'flex-end'}>
                            <TooltipIcon
                                label={
                                    <Box px={'0px'} py={'5px'} >
                                        <Text align={'center'}>{'Frequência'}</Text>
                                        <Text align={'start'} lineHeight='100%' mt={'10px'}>{'De acordo com a frequência de sangria selecionada, será criado uma linha individual para cada tarefa.'}</Text>
                                        <Text align={'start'} lineHeight='100%' mt={'10px'}>{'OBS:'}</Text>
                                        <Text align={'start'} lineHeight='100%' ml={'10px'}>{'Se preferir, clique em Inserir valor total para inserir somente os valores totais'}</Text>
                                    </Box>
                                }
                                height={'23px'} />
                        </Flex>
                    </Flex>
                    <Box>
                        {idPessoaP != null &&
                            <Box>
                                {!isExisteProgramacao ?
                                    <Flex flexDirection={'column'} px={'10px'} py={'5px'} justify='center' align='center' bg={'#ffff66'} borderRadius={'10px'} borderWidth={'1px'}>
                                        <Text color={'black'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Sem Programação'}</Text>
                                    </Flex>
                                    :
                                    <Flex flexDirection={'column'} px={'10px'} py={'5px'} justify='center' align='center' bg={'#4dff4d'} borderRadius={'10px'} borderWidth={'1px'}>
                                        <Text color={'black'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Com Programação'}</Text>
                                    </Flex>
                                }
                            </Box>
                        }
                    </Box>
                </Flex>
            </Box>
        )
    }

    function renderCamposDinamicos() {
        const sizeF = '13px';
        const largTr = 2;
        return (
            <Box px={'10px'}>
                <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.400'}>
                    <Table size='sm' variant='unstyled' >
                        <Thead px={'0px'} bg={'blackAlpha.700'}>
                            <Tr h={'30px'} bgGradient={gradientHeader}>
                                <Th w={'10%'} sx={{ paddingX: largTr }}>
                                    <Box textTransform={'none'} >
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Tarefa/Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'15%'} sx={{ paddingX: largTr }}>
                                    <Box textTransform={'none'} >
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Árvores em Sangria'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'15%'} sx={{ paddingX: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Produção por Tarefa/Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'20%'} sx={{ paddingX: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Clone'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'20%'} sx={{ paddingX: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Tipo de Sangria'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'20%'} sx={{ paddingX: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Nomenclatura do Painel'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'20%'} sx={{ paddingX: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Idade Sangria'}</Text>
                                    </Box>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        {arrayTarefas.map((item, index) => {
                                            return (
                                                <Box key={index} mb={'10px'}>
                                                    <Input
                                                        key={index}
                                                        value={item.label}
                                                        variant="filled"
                                                        _focus={{ boxShadow: 'none' }}
                                                        _hidden={{ boxShadow: 'none' }}
                                                        _hover={{ boxShadow: 'none' }}
                                                        textAlign={'center'}
                                                        fontSize={'17px'}
                                                        fontWeight={'bold'}
                                                        bg={'transparent'}
                                                        h={'30px'}
                                                        textColor={desabilitaTodos ? 'gray.400' : textColor}
                                                        readOnly={true}
                                                    />
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        {tamanhoLoteSelects.length > 0 &&
                                            tamanhoLoteSelects.map((select, index) => (
                                                <Box key={index} mb={'10px'}>
                                                    <Input
                                                        key={index}
                                                        value={select.value}
                                                        onChange={e => changeSelectTamanhoLote(select.id, e)}
                                                        errorBorderColor='crimson'
                                                        variant="filled"
                                                        placeholder=""
                                                        _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                                        borderRadius="10px"
                                                        borderColor={textColor}
                                                        textColor={textColor}
                                                        maxLength={8}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        disabled={!desabilitaTodos && idTipoCadastroTarefa == '2' ? false : true}
                                                    />
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        {producaoLoteSelects.length > 0 &&
                                            producaoLoteSelects.map((select, index) => (
                                                <Box key={index} mb={'10px'}>
                                                    <Input
                                                        key={index}
                                                        value={select.value}
                                                        errorBorderColor='crimson'
                                                        variant="filled"
                                                        placeholder=""
                                                        _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                                        borderRadius="10px"
                                                        borderColor={textColor}
                                                        textColor={textColor}
                                                        maxLength={8}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        disabled={!desabilitaTodos && idTipoCadastroTarefa == '2' ? false : true}
                                                        onChange={e => changeSelectProducaoLote(select.id, e)}
                                                    />
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        {cloneSelects.length > 0 &&
                                            cloneSelects.map((select, index) => (
                                                <Box key={index} mb={'10px'}>
                                                    <Select
                                                        key={index}
                                                        value={select.value}
                                                        errorBorderColor='crimson'
                                                        placeholder="selecione"
                                                        variant="filled"
                                                        borderRadius="10px"
                                                        borderColor={textColor}
                                                        textColor={textColor}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        minW={'130px'}
                                                        disabled={desabilitaTodos}
                                                        onChange={e => changeSelectClone(select.id, e)}
                                                    >
                                                        {CLONES.map((item, indx) => {
                                                            return (
                                                                <option key={indx} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        {tipoSangriaSelects.length > 0 &&
                                            tipoSangriaSelects.map((select, index) => (
                                                <Box key={index} mb={'10px'}>
                                                    <Select
                                                        key={index}
                                                        value={select.value}
                                                        errorBorderColor='crimson'
                                                        placeholder="selecione"
                                                        variant="filled"
                                                        borderRadius="10px"
                                                        borderColor={textColor}
                                                        textColor={textColor}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        disabled={desabilitaTodos}
                                                        onChange={e => changeSelectTipoSangria(select.id, e)}
                                                    >
                                                        {PAINEL.map((item, indx) => {
                                                            return (
                                                                <option key={indx} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        {arrayNomenclatura.map((select, index) => {
                                            return (
                                                <Box key={index} mb={'10px'}>
                                                    <Box mb={'10px'}>
                                                        <Select
                                                            key={index}
                                                            value={select.value}
                                                            errorBorderColor='crimson'
                                                            placeholder="selecione"
                                                            variant="filled"
                                                            borderRadius="10px"
                                                            borderColor={textColor}
                                                            textColor={textColor}
                                                            fontSize={'13px'}
                                                            fontWeight={'bold'}
                                                            h={'30px'}
                                                            disabled={desabilitaTodos}
                                                            onChange={e => changeNomenclaturaPainel(select.id, e)}
                                                        >
                                                            {NOMENCLATURA_PAINEL.map((item, indx) => {
                                                                return (
                                                                    <option key={indx} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        {idadeSangriaSelects.length > 0 &&
                                            idadeSangriaSelects.map((select, index) => (
                                                <Box key={index} mb={'10px'}>
                                                    <Select
                                                        key={index}
                                                        value={select.value}
                                                        errorBorderColor='crimson'
                                                        placeholder="selecione"
                                                        variant="filled"
                                                        borderRadius="10px"
                                                        borderColor={textColor}
                                                        textColor={textColor}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        disabled={desabilitaTodos}
                                                        onChange={e => changeSelectIdadeSangria(select.id, e)}
                                                    >
                                                        {IDADE_SANGRIA.map((item, indx) => {
                                                            return (
                                                                <option key={indx} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td sx={{ paddingX: largTr }}>
                                    <Flex h={'40px'} w={'100%'} justify={'center'} align={'end'}>
                                        <TooltipIcon
                                            label={
                                                <Box px={'0px'} py={'5px'} >
                                                    <Text align={'center'}>{'Campos'}</Text>
                                                    <Text align={'start'} lineHeight='100%' mt={'5px'}>{'Tamanho Lote'}</Text>
                                                    <Text align={'start'} lineHeight='100%' ml={'10px'}>{'- Informe a quantidade de árvores para cada tarefa'}</Text>
                                                    <Text align={'start'} lineHeight='100%' mt={'10px'}>{'Produção Lote'}</Text>
                                                    <Text align={'start'} lineHeight='100%' ml={'10px'}>{'- Informe produção esperada por tarefa'}</Text>
                                                    <Text align={'start'} lineHeight='100%' mt={'10px'}>{'Quantidade Árvores'}</Text>
                                                    <Text align={'start'} lineHeight='100%' ml={'10px'}>{'- Total de árvores'}</Text>
                                                    <Text align={'start'} lineHeight='100%' mt={'10px'}>{'Produção Esperada'}</Text>
                                                    <Text align={'start'} lineHeight='100%' ml={'10px'}>{'- Total de produção por lote'}</Text>
                                                </Box>
                                            }
                                            height={'23px'} />
                                    </Flex>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box >
                                        <Text color={desabilitaTodos ? 'gray.400' : textColor} fontSize='13px' mb={'5px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Quantidade Árvores'}</Text>
                                        <Input
                                            value={totalQuantidadeArvores}
                                            isInvalid={isValidQtdArvore}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _focus={{ boxShadow: 'none' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            h={'30px'}
                                            disabled={!desabilitaTodos && idTipoCadastroTarefa == '1' ? false : true}
                                            onChange={e => setTotalQuantidadeArvores(e.target.value)}
                                        />
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        <Text color={desabilitaTodos ? 'gray.400' : textColor} fontSize='13px' mb={'5px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Produção Esperada'}</Text>
                                        <Input
                                            value={totalProducaoEsperada}
                                            isInvalid={isValidProducaoEsperada}
                                            errorBorderColor='crimson'
                                            variant="filled"
                                            placeholder=""
                                            _focus={{ boxShadow: 'none' }}
                                            borderRadius="10px"
                                            borderColor={textColor}
                                            textColor={textColor}
                                            fontSize={'13px'}
                                            fontWeight={'bold'}
                                            h={'30px'}
                                            disabled={!desabilitaTodos && idTipoCadastroTarefa == '1' ? false : true}
                                            onChange={e => setTotalProducaoEsperada(e.target.value)}
                                        />
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>{''}</Text>
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>{''}</Text>
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Box>
                                        <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>{''}</Text>
                                    </Box>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td sx={{ paddingX: largTr }}>
                                    <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>{''}</Text>
                                </Td>
                                <Td colSpan={2} sx={{ paddingX: largTr }}>
                                    <Box mt={'10px'} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between" >
                                        <RadioGroup onChange={rarioOpcoes} value={idTipoCadastroTarefa} defaultValue='2' isDisabled={desabilitaTodos}>
                                            <Stack spacing={5} direction='row'>
                                                <Radio size='md' name='1' colorScheme='blue' value='1'>
                                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Inserir valor total</Text>
                                                </Radio>
                                                <Radio size='md' name='1' colorScheme='blue' value='2'>
                                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Inserir por frequência</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </Box>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>{''}</Text>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>{''}</Text>
                                </Td>
                                <Td sx={{ paddingX: largTr }}>
                                    <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>{''}</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderCheckbox() {
        return (
            <Box>
                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Selecione os dias úteis'}</Text>
                <Wrap spacing='2px' mx={'15px'}>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={MONDAY}
                                    value={'MONDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Segunda - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={TUESDAY}
                                    value={'TUESDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Terça - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={WEDNESDAY}
                                    value={'WEDNESDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Quarta - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={THURSDAY}
                                    value={'THURSDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Quinta - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={FRIDAY}
                                    value={'FRIDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Sexta - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={SATURDAY}
                                    value={'SATURDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Sábado</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={SUNDAY}
                                    value={'SUNDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Domingo</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                </Wrap>
                {isValidDiasSemana &&
                    <Box p={2} display={{ md: 'flex' }} mx={'2px'} alignItems="start" justifyContent="start">
                        <Box flexDirection={'column'} >
                            <Text color={'red'} fontSize='18px' lineHeight='100%' p={'5px'}>Selecione pelo menos quatro dias</Text>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    function renderAtivo() {
        return (
            <Box display={{ md: 'flex' }} mx={'20px'} alignItems="end" justifyContent="end" >
                <Box flexDirection={'column'} >
                    <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Ativo</Text>
                    <Switch colorScheme='blue' size='lg' onChange={changeAtivo} isChecked={pessoaAtivaP} />
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} my={'15px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button
                    variant="brand"
                    disabled={desabilitaTodos}
                    onClick={validarAndcadastrarPessoa}>
                    Salvar
                </Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'} zIndex={2}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'10px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' py='0px' >
            {renderAlert()}
            <Flex px='25px' mb={'10px'} justify='start' align='center'>
                <Text color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>Propriedade:</Text>
                <Text ml={'2'} color={'orange.700'} fontSize='17px' fontWeight='700' lineHeight='100%'>{nomeFazenda}</Text>
            </Flex>
            {sangriaAtiva && isExisteProgramacao && renderMensagem()}
            <Card direction='column' mx='0px' px='0px' py='0px' borderWidth={3} >
                {renderDadosSeringueiro()}
                {renderAlternancia()}
                {renderCamposDinamicos()}
                {renderCheckbox()}
                {/* {renderAtivo()} */}
            </Card>
            {renderButton()}
            <ModalConfirmacao isOpen={openModalConfimacao} titulo={'Ok!'} onPress={() => { closeModalAndBoBack() }} />
        </Card>
    );
}