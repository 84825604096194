import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { QuestionIcon } from '@chakra-ui/icons';

const TooltipIcon = ({ label = '', height = '20px', color = 'black' }) => {

    return (
        <Tooltip label={label} borderRadius={'10px'} size="sm" bg={'black'}>
            <QuestionIcon w={height} h={height} color={color} />
        </Tooltip>
    );
};

export default TooltipIcon;
