import "assets/css/Chuvas.css";
import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Flex,
    Select,
    useColorModeValue,
    Icon,
} from "@chakra-ui/react";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import moment from 'moment';
import ReactApexChart from "react-apexcharts";
import { TbCircleFilled } from "react-icons/tb";

const ARRAY_ANOS = [
    { value: '2020', label: '2020' },
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
]

const ARRAY_CATEGORIA = [
    ['0', 'Jan'],
    ['0', 'Fev'],
    ['0', 'Mar'],
    ['0', 'Abr'],
    ['0', 'Mai'],
    ['0', 'Jun'],
    ['0', 'Jul'],
    ['0', 'Ago'],
    ['0', 'Set'],
    ['0', 'Out'],
    ['0', 'Nov'],
    ['0', 'Dez']
]

export default function ChuvasAnuais(props) {

    const { marginText } = props;
    const [colunaGrafico, setColunaGrafico] = useState([]);
    const [linhasGrafico, setLinhasGrafico] = useState([]);
    const [valorMaximoGrafico, setValorMaximoGrafico] = useState(100);
    const [legandaGrafico, setLegandaGrafico] = useState([]);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [anoSelecionado, setAnoSelecionado] = useState('');
    const [showCarregando, setShowCarregando] = useState(false);
    const [colorBackgroud, setColorBackgroud] = useState('white');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const heightG = 300;

    useEffect(() => {
        async function loaderScreen() {
            const ano = moment().startOf('month').format('YYYY');

            setAnoSelecionado(ano);
            await pesquisar(ano);
            setTelaCarregada(true);
        }
        loaderScreen();
    }, []);


    const pesquisar = async (ano) => {
        try {
            const ua = localStorage.getItem('@HE-idUa');
            const idSa = localStorage.getItem('@HE-idSafra');

            const url = `climaTempo/listaChuvasMes?idUa=${ua}&idSafra=${idSa}&ano=${ano}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                await constroiArrasGrafico(res.listChuvaMesItem);
                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
            }
        } catch (error) {
            setTelaCarregada(true);
            setShowCarregando(false);
            console.log('Erro na classe ChuvasAnuais metodo pesquisar', error);
        }
    }

    const changeMeses = async (e) => {
        const val = e.target.value;
        setShowCarregando(true);
        setColorBackgroud('blackAlpha.200');

        setAnoSelecionado(val);
        await pesquisar(val);

        setColorBackgroud('white');
        setShowCarregando(false);
    }

    const constroiArrasGrafico = async (arr) => {
        let result = null;
        try {
            if (arr.length > 0) {

                let mediaUltimosAnosLinha = [];
                let somaPorMesColuna = [];
                await new Promise(resolve => {
                    resolve(
                        arr.map(function (item, index) {
                            let media = item.mediaUltimosAnos != '' ? item.mediaUltimosAnos : 0;
                            let soma = item.somaPorMes != '' ? item.somaPorMes : 0;
                            mediaUltimosAnosLinha.push(media);
                            somaPorMesColuna.push(soma);
                            ARRAY_CATEGORIA[index][0] = `${soma}`;
                        })
                    )
                });

                let pp1 = null;
                let pp2 = null;

                let arr1 = mediaUltimosAnosLinha.map(x => x);       // COPIANDO
                let arr2 = somaPorMesColuna.map(x => x);

                const aaX1 = Math.max.apply(null, arr1);     // PEGANDO O MAIOR VALOR DO ARRAY
                const aaX2 = Math.max.apply(null, arr2);     // PEGANDO O MAIOR VALOR DO ARRAY

                const maiorValorEntreArrais = aaX1 > aaX2 ? aaX1 : aaX2;
                const valorFinal = maiorValorEntreArrais > 100 ? maiorValorEntreArrais : 100;

                const val1 = Math.ceil(valorFinal / 100) * 100;               // ARREDONDANDO O VALOR A CADA 100, EX: VALOR 55 ARREDONDA PARA 100, SE FOR 110 ARREDONDA PARA 200, E POR AI VAI
                const val3 = val1;

                setValorMaximoGrafico(val3);

                if (arr1[0] == 0) {
                    pp1 = mediaUltimosAnosLinha.map(x => 0);
                } else {
                    pp1 = mediaUltimosAnosLinha;
                }
                if (arr2[0] == 0) {
                    pp2 = somaPorMesColuna.map(x => 1);
                } else {
                    pp2 = somaPorMesColuna;
                }

                setLinhasGrafico(pp1);
                setColunaGrafico(pp2);
                setLegandaGrafico(ARRAY_CATEGORIA);
            }
        } catch (error) {
            console.log('Erro na classe ChuvasAnuais metodo constroiArrasGrafico', error);
        }
        return result;
    }

    const series = [
        {
            name: 'Soma dos lançamentos diários (ano atual)',
            //name: 'Precipitação (mm)',
            type: 'column',
            data: colunaGrafico,
            //data: [191, 73, 224, 51, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
            name: 'Média dos últimos anos',
            type: 'line',
            data: linhasGrafico,
            //data: [245, 212, 122, 51, 12, 0, 0, 44, 72, 80, 115, 240],
        }
    ]
    const options = {
        chart: {
            height: heightG,
            type: 'bar',
            stacked: false,     // VALORES ACIMA DO GRAFICO
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false, // Desativa o zoom via scroll do mouse
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                borderRadiusApplication: 'end',  // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                columnWidth: '50%',
                dataLabels: {
                    enabled: false,
                    position: 'bottom', // top, center, bottom
                    total: {
                        enabled: false,
                    },
                }
            },
        },
        colors: ['#00004d', '#FF0000', '#000000'],
        stroke: {
            width: [1, 3, 3],       // ESSE CARA MOSTRA A LINHA DO GRAFICO
            curve: 'smooth'      // ARREDONDA ALINHA DO GRAFICO, OPCOES 'smooth', 'straight', 'stepline'
        },
        dataLabels: {
            enabled: true,          // MOSTRA OS VALORES NA LINHA DO GRAFICO
            enabledOnSeries: [1],   // HHOOOOOOOOOOOOOO, ATENCAOOOOOO ESSE CARA DETERMINA QUAIS OS ROTULOS ENTRE COLUNAS E LINHAS SERAO EXIBIDAS EX: [1] SO EXIBE OS VALORES DAS LNHAS [0,1] EXIBE VALORES DAS LINHAS E COLUNAS
            position: 'bottom',     // Exibe os valores na parte inferior das colunas
            style: {
                fontSize: '11px',
                colors: ['#FFFFFF'],
            },
            background: {
                enabled: true,
                foreColor: '#000000',
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#000000',
                width: '10px'
            }
        },
        fill: {
            opacity: [0.85, 0.85, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        markers: {              // ESSE CARA CRIA OS PONTOS DE MARCACAO --->>> PARA QUE ELE FUCNIONE RETIRE ESSA LINHA DO ARRAY EM: dataLabels/enabledOnSeries
            size: 3,
            hover: {
                size: 10
            }
        },
        grid: {
            row: {
                colors: ['#FFFFFF', '#d9d9d9']      // LINAS ATRAS DO GRAFICO
            },
            padding: {
                left: 30,
                right: 5,
                top: 10,
                bottom: 10
            }
        },
        xaxis: {                                // LEGENDAS ABAIXO DAS COLUNAS
            type: 'category',
            categories: legandaGrafico,
            tickPlacement: 'between',
            offsetX: 0,
            offsetY: -8,
            min: 6,
            max: 6,
            floating: false,
            position: 'bottom',
            labels: {
                show: true,
                rotate: -45,
                style: {
                    colors: "#333333",
                    fontSize: "12px",
                    fontWeight: 500,
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        yaxis: {        // ESTILO DAS LEGENDAS A ESQUERDA DO GRAFICO
            show: true,
            max: valorMaximoGrafico,
            min: 0,
            logBase: 10,
            tickAmount: 5,
            floating: true,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: ['black'],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 15,        // LARGURA DO ESPACO DOS TEXTO NA LATERAL ESQUERDA
                offsetY: 0,
                rotate: 0,
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;
                }
            }
        },
        legend: {
            show: false,
            //position: 'top',
            //horizontalAlign: 'left'
        }
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'5px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box px={'5px'} w={'100%'} position={'relative'}>
            <Flex px={'5px'} mt={'10px'} h={'60px'} w={'100%'} justify='space-between' align='flex-start'>
                <Flex justify='start' align='start'>
                    <Select
                        value={anoSelecionado}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={changeMeses}
                        h={'30px'}
                        w={'150px'}
                    >
                        {ARRAY_ANOS.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value}>{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
                <Flex flexDirection={'column'} w={'100%'} ml={'20px'} justify='start' align='start'>

                    <Flex justify='start' align='center'>
                        <Icon as={TbCircleFilled} color={'#00004d'} w={'18px'} h={'18px'} mr={'1px'} />
                        <Text mb={marginText} color={'black'} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'}>{'Soma dos lançamentos diários (ano atual)'}</Text>
                    </Flex>
                    <Flex justify='start' align='center'>
                        <Icon as={TbCircleFilled} color={'#ff0000'} w={'18px'} h={'18px'} mr={'1px'} />
                        <Text mb={marginText} color={'black'} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'}>{'Média dos últimos anos'}</Text>
                    </Flex>

                </Flex>
            </Flex>
            <Box w={'100%'} bg={colorBackgroud}>
                <ReactApexChart options={options} series={series} type="bar" height={300} width={'100%'} />
            </Box>

            {showCarregando &&
                <Box position={'absolute'} left={'0px'} right={'0px'} top={'80px'}>
                    <Text as='cite' fontSize={'18px'} textShadow={border_white} fontWeight={'bold'} color={'black'} align={'center'}>{'Carregando...'}</Text>
                </Box>
            }
        </Box>
    );
}
