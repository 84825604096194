import { Box, SimpleGrid } from "@chakra-ui/react";
import GraficoProducaoDeCoagulos from "views/admin/producaoDeCoagulos/GraficoProducaoDeCoagulos";
import React from "react";

export default function Settings() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
                <GraficoProducaoDeCoagulos />
            </SimpleGrid>
        </Box>
    );
}
