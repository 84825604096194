import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    TableContainer,
    Select,
    Box,
    Center,
    FormLabel,
    Image,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import { ARRAY_MESES_SAFRA_SANGRIA } from "views/admin/default/variables/MockData";
import ReactApexChart from "react-apexcharts";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LoaderBlack from 'components/loading/LoaderBlack';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { RETORNA_ARRAY_COM_MESES_DA_SAFRA } from '../../../../util/CalculoRelatorioSangriaAndProducao';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import TooltipSeringueiro from 'components/tooltip/TooltipSeringueiro';

export default function AcompanhamentosSangriasDiarias() {

    const pdfRef = useRef();

    const [loading, setLoading] = useState(false);
    const [marginText, setMarginText] = useState('0px');
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [extratoSeringueiro, setExtratoSeringueiro] = useState([]);
    const [totalQtdTarefasSangraveis, setTotalQtdTarefasSangraveis] = useState(0);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [totalQtdTarefasSangradas, setTotalQtdTarefasSangradas] = useState(0);
    const [totalQtdTarefasNaoRecuperadas, setTotalQtdTarefasNaoRecuperadas] = useState(0);
    const [totalTarefasNaoSangradasPorChuva, setTotalTarefasNaoSangradasPorChuva] = useState(0);
    const [totalPercentPossiveisRealizadas, setTotalPercentPossiveisRealizadas] = useState(0);
    const [totalPercentIndiceficiencia, setTotalPercentIndiceficiencia] = useState(0);
    const [totalDiasNaoSangradosPorChuva, setTotalDiasNaoSangradosPorChuva] = useState(0);
    const [totalTotalFaltaslicencas, setTotalTotalFaltaslicencas] = useState(0);
    const [totalPercentDeAusenciasEmRelacaoAoTotal, setTotalPercentDeAusenciasEmRelacaoAoTotal] = useState(0);
    const [arrayProducaoCompleto, setarrayProducaoCompleto] = useState([]);
    const [listGraficoSangradas, setListGraficoSangradas] = useState([]);
    const [listGraficoSangraveis, setListGraficoSangraveis] = useState([]);
    const [listGraficoEficiencia, setListGraficoEficiencia] = useState([]);
    const [arrayMesesAndData, setArrayMesesAndData] = useState([]);

    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');
    const [nomePessoa, setNomePessoa] = useState('Todos');
    const [nomeRelatorio, setNomeRelatorio] = useState('Acompanhamento-sangria');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientPercent = 'linear-gradient(orange.300, orange.200, orange.50)';
    const gradientFocus = 'linear-gradient(blackAlpha.500, blackAlpha.400, blackAlpha.300)';

    useEffect(() => {
        async function loadScreen() {
            try {
                const nomeUa = localStorage.getItem('@HE-nomeUa');
                setNomeFazenda(nomeUa);
                const nmSafra = localStorage.getItem('@HE-nomeSafra');
                setNomeSafra(nmSafra);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Acompanhamento-sangria-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);

                const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
                const fim = localStorage.getItem('@HE-dtFimSafraAsync');
                const ARRAY_MESES_SANGRIA = await RETORNA_ARRAY_COM_MESES_DA_SAFRA(ini, fim);
                setArrayMesesAndData(ARRAY_MESES_SANGRIA);

                const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
                const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');

                const ua = localStorage.getItem('@HE-idUa');
                const idSafra = localStorage.getItem('@HE-idSafra');
                const idTipoPessoa = ActionTypes.ID_TIPO_PESSOA_SANGRADOR;

                const json = {
                    idUa: ua,
                    idSafra: idSafra,
                    idTipoPessoa: idTipoPessoa,
                    dtInicio: dtInicioSafra,
                    dtFim: dtFimSafra,
                    dtInicioSangria: dtInicioSafra,
                    arrayMesesAno: ARRAY_MESES_SANGRIA,
                }

                const url = `relatorioSangria/relatorioAcompanhamentoSangria`;
                const response = await Api.postRequest(url, json);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = response.respostaRequisicao.data;
                    const listSeringueiros = res.listSeringueiros;

                    setarrayProducaoCompleto(res);
                    await carregaDadosSeringueiro(res.listRelatorioFazenda[0], 'Todos');

                    if (listSeringueiros.length > 0) {
                        let arrayPess = [];
                        await new Promise(resolve => {
                            resolve(
                                listSeringueiros.map(function (item) {
                                    const obj = {
                                        label: item.nome,
                                        value: `${item.idPessoa}`
                                    }
                                    arrayPess.push(obj);
                                })
                            )
                        });
                        setListSeringueiro(arrayPess);
                    }
                    setTelaCarregada(true);
                } else {
                    console.log('');
                }
            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loadScreen();
    }, []);

    const carregaDadosSeringueiro = async (arraySangria, nomePess = 'Todos') => {
        try {
            const objProducao = arraySangria;
            const totalQtdTarefasSangraveis = objProducao.totalQtdTarefasSangraveis != null && objProducao.totalQtdTarefasSangraveis != '' ? objProducao.totalQtdTarefasSangraveis : '0';
            const totalQtdTarefasSangradas = objProducao.totalQtdTarefasSangradas != null && objProducao.totalQtdTarefasSangradas != '' ? objProducao.totalQtdTarefasSangradas : '0';
            const totalQtdTarefasNaoRecuperadas = objProducao.totalQtdTarefasNaoRecuperadas != null && objProducao.totalQtdTarefasNaoRecuperadas != '' ? objProducao.totalQtdTarefasNaoRecuperadas : '0';
            const totalTarefasNaoSangradasPorChuva = objProducao.totalTarefasNaoSangradasPorChuva != null && objProducao.totalTarefasNaoSangradasPorChuva != '' ? objProducao.totalTarefasNaoSangradasPorChuva : '0';
            const totalPercentPossiveisRealizadas = objProducao.totalPercentPossiveisRealizadas != null && objProducao.totalPercentPossiveisRealizadas != '' ? objProducao.totalPercentPossiveisRealizadas : '0';
            const totalPercentIndiceficiencia = objProducao.totalPercentIndiceficiencia != null && objProducao.totalPercentIndiceficiencia != '' ? objProducao.totalPercentIndiceficiencia : '0';
            const totalDiasNaoSangradosPorChuva = objProducao.totalDiasNaoSangradosPorChuva != null && objProducao.totalDiasNaoSangradosPorChuva != '' ? objProducao.totalDiasNaoSangradosPorChuva : '0';
            const totalTotalFaltaslicencas = objProducao.totalTotalFaltaslicencas != null && objProducao.totalTotalFaltaslicencas != '' ? objProducao.totalTotalFaltaslicencas : '0';
            const totalPercentDeAusenciasEmRelacaoAoTotal = objProducao.totalPercentDeAusenciasEmRelacaoAoTotal != null && objProducao.totalPercentDeAusenciasEmRelacaoAoTotal != '' ? objProducao.totalPercentDeAusenciasEmRelacaoAoTotal : '0';

            setTotalQtdTarefasSangraveis(totalQtdTarefasSangraveis);
            setTotalQtdTarefasSangradas(totalQtdTarefasSangradas);
            setTotalQtdTarefasNaoRecuperadas(totalQtdTarefasNaoRecuperadas);
            setTotalTarefasNaoSangradasPorChuva(totalTarefasNaoSangradasPorChuva);
            setTotalPercentPossiveisRealizadas(totalPercentPossiveisRealizadas);
            setTotalPercentIndiceficiencia(totalPercentIndiceficiencia);
            setTotalDiasNaoSangradosPorChuva(totalDiasNaoSangradosPorChuva);
            setTotalTotalFaltaslicencas(totalTotalFaltaslicencas);
            setTotalPercentDeAusenciasEmRelacaoAoTotal(totalPercentDeAusenciasEmRelacaoAoTotal);
            setPessoaSelecionado(`${objProducao.idPessoa}`);

            const listaMesesDaSafra = objProducao.listaMesesDaSafra;
            var array = [];
            let arraysGraficoP = {
                listGraficoSangradas: ['0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%'],
                listGraficoSangraveis: ['0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%'],
                listGraficoEficiencia: ['0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%']
            }
            if (listaMesesDaSafra.length > 0) {

                await new Promise(resolve => {
                    resolve(
                        array = listaMesesDaSafra.map(function (item) {

                            let qtdTarefasSangraveisGraficoP = 0;
                            let qtdTarefasSangradasGraficoP = 0;
                            if (item.qtdTarefasSangraveis != null && item.qtdTarefasSangraveis != 0 && item.qtdTarefasSangraveis != '') {
                                const p1 = Math.round((item.qtdTarefasSangraveis / 200) * 100);
                                qtdTarefasSangraveisGraficoP = p1;
                            }
                            if (item.qtdTarefasSangradas != null && item.qtdTarefasSangradas != 0 && item.qtdTarefasSangradas != '') {
                                const p1 = Math.round((item.qtdTarefasSangradas / 200) * 100);
                                qtdTarefasSangradasGraficoP = p1;
                            }

                            let mesdesc = item.mes.substring(0, 3);
                            return {
                                mes: mesdesc,
                                qtdTarefasSangraveis: item.qtdTarefasSangraveis != 0 && item.qtdTarefasSangraveis != null ? item.qtdTarefasSangraveis : '',
                                qtdTarefasSangradas: item.qtdTarefasSangradas != 0 && item.qtdTarefasSangradas != null ? item.qtdTarefasSangradas : '',
                                qtdTarefasNaoRecuperadas: item.qtdTarefasNaoRecuperadas != 0 && item.qtdTarefasNaoRecuperadas != null ? item.qtdTarefasNaoRecuperadas : '',
                                tarefasNaoSangradasPorChuva: item.faltasPorMotivoDeChuva != 0 && item.faltasPorMotivoDeChuva != null ? item.faltasPorMotivoDeChuva : '',
                                PossiveisRealizadas: item.percentualSangriasPossiveisRealizadas != 0 && item.percentualSangriasPossiveisRealizadas != null ? `${item.percentualSangriasPossiveisRealizadas}%` : '',
                                eficienciaDeSangria: item.percentualIndiceEficiencia != 0 && item.percentualIndiceEficiencia != null ? `${item.percentualIndiceEficiencia}%` : '',
                                diasNaoSangradosPorChuva: item.diasNaoSangradosPorChuva != 0 && item.diasNaoSangradosPorChuva != null ? item.diasNaoSangradosPorChuva : '',
                                TotalDeFaltasAndLicencas: item.faltas != 0 && item.faltas != null ? item.faltas : '',
                                percentDeAusenciasEmRelacaoAoTotal: item.percentualDeAusenciasEmRelacaoAoTotal != 0 && item.percentualDeAusenciasEmRelacaoAoTotal != null ? `${item.percentualDeAusenciasEmRelacaoAoTotal}%` : '',

                                qtdTarefasSangraveisGrafico: qtdTarefasSangraveisGraficoP,
                                qtdTarefasSangradasGrafico: qtdTarefasSangradasGraficoP,
                                eficienciaDeSangriaGrafico: item.percentualIndiceEficiencia != null ? item.percentualIndiceEficiencia : 0,
                                listSeringueiroDeletado: item.listSeringueiroDeletado != null ? item.listSeringueiroDeletado : [],
                            };
                        })
                    )
                    setExtratoSeringueiro(array);
                });
                arraysGraficoP = await constroiArrasGrafico(array);
            }

            const jsonSangria = {
                totalQtdTarefasSangraveis: totalQtdTarefasSangraveis,
                totalQtdTarefasSangradas: totalQtdTarefasSangradas,
                totalQtdTarefasNaoRecuperadas: totalQtdTarefasNaoRecuperadas,
                totalTarefasNaoSangradasPorChuva: totalTarefasNaoSangradasPorChuva,
                totalPercentPossiveisRealizadas: totalPercentPossiveisRealizadas,
                totalPercentIndiceficiencia: totalPercentIndiceficiencia,
                totalDiasNaoSangradosPorChuva: totalDiasNaoSangradosPorChuva,
                totalTotalFaltaslicencas: totalTotalFaltaslicencas,
                totalPercentDeAusenciasEmRelacaoAoTotal: totalPercentDeAusenciasEmRelacaoAoTotal,
                extratoSeringueiro: array,
                listAcompaGrafico: array,
                arraysGrafico: arraysGraficoP,
                nomePessoa: nomePess
            }
            localStorage.setItem('HE@-sangriasDiarias-personalizado', JSON.stringify(jsonSangria));

        } catch (error) {
            console.log('Erro na classe AcompanhamentoSangriasDiarias metodo carregaDadosSeringueiro', error);
        }
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        await html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    const constroiArrasGrafico = async (arr) => {
        let result = null;
        try {
            if (arr.length > 0) {
                let arrqySangradas = [];
                let arrqySangraveis = [];
                let arrqyEficiencia = [];
                await new Promise(resolve => {
                    resolve(
                        arr.map(function (item) {
                            let qtdSangradas = item.qtdTarefasSangradas != '' ? item.qtdTarefasSangradas : 0;
                            let qtdSangraveis = item.qtdTarefasSangraveis != '' ? item.qtdTarefasSangraveis : 0;
                            let qtdEficiencia = item.eficienciaDeSangriaGrafico != '' ? Number(item.eficienciaDeSangriaGrafico) : 0;
                            arrqySangradas.push(qtdSangradas);
                            arrqySangraveis.push(qtdSangraveis);
                            arrqyEficiencia.push(qtdEficiencia);
                        })
                    )
                });

                let pp1 = null;
                let pp2 = null;
                let pp3 = null;

                let arr1 = arrqySangradas.map(x => x);                     // COPIANDO ARRAY
                let arr2 = arrqySangraveis.map(x => x);
                let arr3 = arrqyEficiencia.map(x => x);

                arr1.sort(function (a, b) { return b - a });                      // RECUPRANDO O MAIOR VALOR NO ARRAY
                arr2.sort(function (a, b) { return b - a });
                arr3.sort(function (a, b) { return b - a });

                if (arr1[0] == 0) {
                    pp1 = arrqySangradas.map(x => '0%');
                } else {
                    pp1 = arrqySangradas;
                }
                if (arr2[0] == 0) {
                    pp2 = arrqySangraveis.map(x => '0%');
                } else {
                    pp2 = arrqySangraveis;
                }
                if (arr3[0] == 0) {
                    pp3 = [];
                } else {
                    pp3 = arrqyEficiencia;
                }

                setListGraficoSangradas(pp1);
                setListGraficoSangraveis(pp2);
                setListGraficoEficiencia(pp3);

                result = {
                    listGraficoSangradas: pp1,
                    listGraficoSangraveis: pp2,
                    listGraficoEficiencia: pp3,
                }
            }
        } catch (error) {
            console.log('Erro na classe AcompanhamentoDaProducao metodo constroiArrasGrafico', error);
        }
        return result;
    }

    const changePessoa = async (e) => {
        try {
            const val = e.target.value;
            setPessoaSelecionado(val);
            let nomePess = 'Todos';

            if (val != '') {
                const arraySeringueiros = arrayProducaoCompleto.listRelatorioAcompanhamentoSangria;
                const arrFilter = arraySeringueiros.filter(x => x.idPessoa == val);
                const arraySeringueirosComMeses = arrFilter.length > 0 ? arrFilter[0] : ARRAY_MESES_SAFRA_SANGRIA[0];

                nomePess = arraySeringueirosComMeses.nomePessoa;
                setNomePessoa(nomePess);
                await carregaDadosSeringueiro(arraySeringueirosComMeses, nomePess);
            } else {
                const arrayFazenda = arrayProducaoCompleto.listRelatorioFazenda;
                const xxx = arrayFazenda.length > 0 ? arrayFazenda[0] : ARRAY_MESES_SAFRA_SANGRIA[0];

                setNomePessoa('Todos');
                await carregaDadosSeringueiro(xxx, nomePess);
            }
        } catch (error) {
            setExtratoSeringueiro(ARRAY_MESES_SAFRA_SANGRIA[0].listaMesesDaSafra);
            console.log('Erro na classe AcompanhamentoSangriasDiarias metodo changePessoa', error);
        }
    };

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await criarRelatorioExcel();
            setLoading(false);
        }, 1500);
    }

    const criarRelatorioExcel = async () => {
        const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
        const fim = localStorage.getItem('@HE-dtFimSafraAsync');
        const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
        const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');

        const ua = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const idTipoPessoa = ActionTypes.ID_TIPO_PESSOA_SANGRADOR;
        const nomeUserLogado = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');

        const json = {
            idUa: ua,
            idSafra: idSafra,
            idTipoPessoa: idTipoPessoa,
            dtInicio: dtInicioSafra,
            dtFim: dtFimSafra,
            dtInicioSangria: dtInicioSafra,
            nomeUsuario: nomeUserLogado,
            arrayMesesAno: arrayMesesAndData,
        }

        const url = `relatorioSangria/downloadRelatorioExcelAcompanhamentoSangria`;
        await Api.postRequestDownloadExcel(url, json, nomeRelatorio);
    }

    const series = [
        {
            name: 'Tarefas Sangráveis',
            type: 'column',
            data: listGraficoSangraveis
        },
        {
            name: 'Tarefas Sangradas',
            type: 'column',
            data: listGraficoSangradas
        },
        {
            name: 'Eficiência de Sangria',
            type: 'line',
            data: listGraficoEficiencia
        }
    ]

    const options = {
        chart: {
            height: 350,
            type: 'line',
            foreColor: 'black',
            dropShadow: {
                enabled: false,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF",
            },
            toolbar: {      // ESSE CARA MOSTRA OU OCULTA OS BOTOES DE DOLWNLOAD, ZOOM, HOME...
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false, // Desativa o zoom via scroll do mouse
            },
        },
        colors: ['#00004d', '#0099ff', '#ff0000'],
        stroke: {
            width: [1, 1, 0],       // ESSE CARA MOSTRA A LINHA DO GRAFICO
            curve: 'smooth'         // ARREDONDA ALINHA DO GRAFICO, OPCOES 'smooth', 'straight', 'stepline'
        },
        plotOptions: {
            bar: {
                enabled: false,
                dataLabels: {
                    enabled: false, // Define como false para ocultar as etiquetas de dados (colunas)
                }
            }
        },
        tooltip: {
            enabled: true,
        },
        title: {
            text: ''
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
            type: 'category',
            categories: ['SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO'],
            position: 'bottom',
            labels: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                style: {
                    colors: "#000000",
                    fontSize: "11px",
                    fontWeight: "500",
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        legend: {
            show: true,
            floating: false,
            fontSize: '15px',
            fontFamily: 'Helvetica, Arial',
            horizontalAlign: 'center',
            fontWeight: 500,
            offsetX: 0,
            offsetY: 10,
            height: 40,
        },
        fill: {
            type: "gradient",
            colors: ['#00004d', '#0099ff', '#ff0000'],
        },
    }

    function renderTableSangrias() {
        const sizeF = '13px';
        let corLinha = false;
        return (
            <TableContainer>
                <Table size='sm' variant='unstyled'>
                    <Thead px={'0px'} bg={'blackAlpha.500'}>
                        <Tr h={'60px'} bgGradient={gradientHeader}>
                            <Th w={'60px'}>
                                <Box textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'start'} >Mês</Text>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Tarefas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Possíveis'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'de Sangria'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Total de'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Tarefas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Realizadas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'} bgGradient={gradientFocus}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Possíveis'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Realizadas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Qtd tarefas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Não'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Recuperadas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Tarefas Não'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Sangradas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Por Chuva'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'} bgGradient={gradientFocus}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Eficiência'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'De Sangria'}</Text>
                                    </Center>
                                </Box>
                            </Th>

                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Total de'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Faltas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'E Licenças'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'} bgGradient={gradientFocus}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Ausências'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Em Relação'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Ao Total'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {extratoSeringueiro.map((item, key) => {
                            corLinha = !corLinha;
                            return (
                                <Tr key={key + 1} bg={corLinha ? 'rgba(217, 217, 217, 0.2)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'}>
                                    <Td>
                                        <Box position={'relative'} w={'100%'}>
                                            <Flex justify='space-between' align='center' >
                                                <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' mb={marginText}>{item.mes}</Text>
                                                {item.listSeringueiroDeletado.length > 0 &&
                                                    <Box position={'absolute'} top={'-5px'} left={'35px'}>
                                                        <TooltipSeringueiro
                                                            label={
                                                                <Box py={'20px'} >
                                                                    {item.listSeringueiroDeletado.map((x, y) => {
                                                                        const dtDesativado = x.dtDeletado != null ? moment(x.dtDeletado).format('DD/MM/YYYY') : '';
                                                                        return (
                                                                            <Box key={y}>
                                                                                <Text align={'center'}>{`Seringueiro ${x.nomeSeringueiroDeletado} desativado, data: ${dtDesativado}`}</Text>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            }
                                                            height={'22px'} />
                                                    </Box>
                                                }
                                            </Flex>
                                        </Box>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.qtdTarefasSangraveis}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.qtdTarefasSangradas}</Text>
                                    </Td>
                                    <Td bgGradient={gradientPercent}>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.PossiveisRealizadas}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.qtdTarefasNaoRecuperadas}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.tarefasNaoSangradasPorChuva}</Text>
                                    </Td>
                                    <Td bgGradient={gradientPercent}>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.eficienciaDeSangria}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.TotalDeFaltasAndLicencas}</Text>
                                    </Td>
                                    <Td bgGradient={gradientPercent}>
                                        <Text color={'red.800'} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.percentDeAusenciasEmRelacaoAoTotal}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                        <Tr h={'30px'} alignItems={'center'} bgGradient={gradientHeader}>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' mb={marginText} >{'Total'}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalQtdTarefasSangraveis}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalQtdTarefasSangradas}</Text>
                            </Td>
                            <Td bgGradient={gradientFocus}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{`${totalPercentPossiveisRealizadas}%`}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalQtdTarefasNaoRecuperadas}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalTarefasNaoSangradasPorChuva}</Text>
                            </Td>
                            <Td bgGradient={gradientFocus}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{`${totalPercentIndiceficiencia}%`}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalTotalFaltaslicencas}</Text>
                            </Td>
                            <Td bgGradient={gradientFocus}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{`${totalPercentDeAusenciasEmRelacaoAoTotal}%`}</Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' py={'1'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }
    return (
        <Card direction='column' overflowX={{ sm: "scroll", lg: "hidden" }} w='100%' pt='0px' px='0px' borderRadius={'10px'}>
            <Flex px='5px' justify='space-between' mb='2px' align='center'>
                <Box w='100%' >
                    <Flex px='25px' justify='space-between' mb='2px' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>Seringueiros</Text>
                    </Flex>
                    <Select
                        value={pessoaSelecionado}
                        errorBorderColor='crimson'
                        placeholder="todos"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={changePessoa}
                        w={'400px'}
                        h={'35px'}
                        ml={'15px'}
                    >
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Box>
                <Box display={'flex'} w='100%' justifyContent={'flex-end'} alignItems={'center'} >
                    <Flex px='2px' justify='flex-end' mb='3px' align='center' >
                        <FormLabel htmlFor='balance' _hover={{ cursor: "pointer" }} mt={'-10px'} >
                            <Image boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/excel_icon_download.png`} alt='Dan Abramov' onClick={() => { gerarRelatorioExcel() }} />
                        </FormLabel>
                        <FormLabel htmlFor='balance' _hover={{ cursor: "pointer" }} mt={'-10px'} >
                            <Image boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/pdf_icon_download.png`} alt='Dan Abramov' onClick={downloadPdf} />
                        </FormLabel>
                    </Flex>
                </Box>
            </Flex>
            {extratoSeringueiro.length > 0 ?
                <Box ref={pdfRef} w='100%'>

                    <Box display={'flex'} w='100%' mb={'10px'} alignItems={'center'} justifyContent={'center'}>
                        <Text color={textColor} fontSize={'18px'} fontWeight={'bold'}>Acompanhamento Diário das Sangrias</Text>
                    </Box>
                    <Flex px='20px' justify='flex-start' mb='3px' align='center'>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'light'}>Propriedade:</Text>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} ml={'10px'}>{nomeFazenda}</Text>
                    </Flex>
                    <Flex px='20px' justify='flex-start' mb='3px' align='center' >
                        <Text color={textColor} fontSize={'15px'} fontWeight={'light'}>Safra:</Text>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} ml={'10px'}>{nomeSafra}</Text>
                    </Flex>
                    <Flex px='20px' justify='flex-start' mb='3px' align='center' >
                        <Text color={textColor} fontSize={'15px'} fontWeight={'light'}>Seringueiro:</Text>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} ml={'10px'}>{nomePessoa}</Text>
                    </Flex>
                    <Box w='100%' px={'2'} mt={'10px'}>
                        <Box mt='10px' bg={'white'} mb={'30px'} >
                            {renderTableSangrias()}
                        </Box>
                        <Box h='380px' mt='30px' bg={'white'} borderWidth={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.400'}>
                            <ReactApexChart options={options} series={series} type="line" height={350} width={'100%'} />
                        </Box>
                    </Box>
                </Box>
                :
                <Flex px='25px' justify='center' mb='2px' align='center' >
                    <Text mt={'20px'} color={'blue.900'} fontSize='20px' fontWeight='700' lineHeight='100%'>Carregandoooo...</Text>
                </Flex>
            }
            <LoaderBlack isOpen={loading} />
        </Card>
    );
}
