import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
} from "@chakra-ui/react";

function ModalDialog({ isOpen, onPressOk, onPressCancel, titulo = 'OBS', descricao = 'Deseja Realmente desativar esse seringueiro?' }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bg={'white'}>
            <ModalHeader>{titulo}</ModalHeader>
                <ModalBody>
                    <Text color={'blackAlpha.900'} fontSize={'15px'} fontWeight={'bold'}>{descricao}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant="brand" mr={3} onClick={onPressCancel}>Não</Button>
                    <Button variant="brand" onClick={onPressOk}>Sim</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalDialog;
