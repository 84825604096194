import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Box,
    Center,
    Wrap,
    SimpleGrid,
    TableContainer,
    Table,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import { LIST_UFS } from 'model/MockData'
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { retiraMascaraMaterNumeros, mascaraCep } from '../../../../util/Masck';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import TooltipIcon from 'components/tooltip/TooltipIcon';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';
import MessageSreen from 'components/message/MessageSreen';
import Loader from 'components/loading/Loader';

export default function FormPropriedades() {

    const history = useHistory();
    const [isValidUf, setIsValidUf] = useState(false);
    const [ufSelecionado, setUfSelecionado] = useState('');
    const [isValidMunicipio, setIsValidMunicipio] = useState(false);
    const [listMunicipio, setListMunicipio] = useState([]);
    const [municipioSelecionado, setMunicipioSelecionado] = useState('');
    const [isDisabledMunicipio, setIsDisabledMunicipio] = useState(true);
    const [idUsuarioProdutor, setIdUsuarioProdutor] = useState(null);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [operacao, setOperacao] = useState(null);
    const [disabledComponent, setdisabledComponent] = useState(false);
    const [inpuNomeFazenda, setInpuNomeFazenda] = useState('');
    const [isValidNomeFazenda, setIsValidNomeFazenda] = useState(false);
    const [imputAreaTotal, setImputAreaTotal] = useState('');
    const [isValidAreaTotal, setIsValidAreaTotal] = useState(false);
    const [inputLocalizacao, setInputLocalizacao] = useState('');
    const [isLocalizacao, setIsLocalizacao] = useState(false);
    const [idUaP, setIdUaP] = useState(null);

    const [imputCep, setImputCep] = useState('');
    const [nomeProdutor, setNomeProdutor] = useState('');
    const [listaSafra, setListaSafra] = useState([]);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isExisteSangriaAtiva, setIsExisteSangriaAtiva] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const shadowBlack = ('0px 2px 5px 2px rgba(0, 0, 0, 0.30)');            // 1-posicao horizontal  2-possicao vertical  3-desfoque 4-largura

    const acaoUpdate = 'update';
    const acaoInsert = 'insert';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dados = localStorage.getItem('@HE-propriedadeParaEdicao');
            const param = JSON.parse(dados);
            const idUser = param.idUsuarioProdutor;
            const nomeProdutor = param.nomeProdutor;
            const idUa = param.idUa;
            setIdUsuarioProdutor(idUser);
            setNomeProdutor(nomeProdutor);
            setIdUaP(idUa !== null && idUa !== '' ? idUa : null);

            if (idUa != null) {
                await opcaoUpdateCarregaTela(idUser, idUa);
                setTelaCarregada(true);

            } else {
                setOperacao(acaoInsert);
                setdisabledComponent(true);
                setListaSafra([]);
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const opcaoUpdateCarregaTela = async (idUser, idUa) => {
        try {
            if (idUa != null) {
                setOperacao(acaoUpdate);
                setIdUaP(idUa);

                const idTipoUsuarioLogado = localStorage.getItem('@HE-idTipoUsuarioLogado');
                const url = `api/ua/buscaUaAndSafrasParaEdicao?idTipoUsuario=${idTipoUsuarioLogado}&idUsuario=${idUser}&idUa=${idUa}&deletado=false`;
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    if (res.length > 0) {
                        const res2 = res[0];

                        if (res2.listaUaSafraPessoaDto.length > 0) {
                            const objUa = res2.listaUaSafraPessoaDto[0];

                            setInpuNomeFazenda(objUa.descricao !== null && objUa.descricao !== '' ? objUa.descricao : '');
                            setInputLocalizacao(objUa.localizacao !== null ? objUa.localizacao : '');
                            setImputCep(objUa.cep !== null ? mascaraCep(objUa.cep) : '');
                            setMunicipioSelecionado(objUa.idMunicipio !== null ? objUa.idMunicipio : '');
                            carregarUf(objUa.idUf !== null && objUa.idUf !== '' ? objUa.idUf : '');
                            setImputAreaTotal(objUa.areaTotal !== null ? objUa.areaTotal : 0);

                            if (objUa.listaSafraDetail.length > 0) {
                                setListaSafra(objUa.listaSafraDetail);

                                const listAtiva = objUa.listaSafraDetail.filter(x => x.sangriaAtiva == true);
                                const isEx = listAtiva.length > 0 ? true : false;
                                setIsExisteSangriaAtiva(isEx);
                            }
                        }
                    }
                } else {
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            } else {
                setNumStatusResposta(ActionTypes.ERRO_INESPERADO);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe FormPropriedades metodo opcaoUpdateCarregaTela', error);
        }
    }

    const changeUf = async (e) => {
        const val = e.target.value;
        carregarUf(val);
        setIsValidUf(val == '' ? true : false);
    }

    const carregarUf = async (e) => {
        const uf = e;
        setUfSelecionado(`${uf}`)
        const response = await Api.getRequest(`api/ua/listaMunicipios?idUf=${uf}`);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;

            if (res.length > 0) {
                let arr = res.map((item, index) => {
                    return {
                        value: `${item.idMunicipio}`,
                        label: item.nomeMunicipio,
                    }
                })
                setListMunicipio(arr);
                setIsDisabledMunicipio(false);
            }
        }
    }

    const changeMunicipio = async (e) => {
        const val = e.target.value
        setMunicipioSelecionado(val);
        setIsValidMunicipio(val == '' ? true : false);
    }

    const salvarPropriedade = async () => {
        try {
            let valid = false;

            if (inpuNomeFazenda.trim().length < 3) {
                setIsValidNomeFazenda(true);
                valid = true;
            } else {
                setIsValidNomeFazenda(false);
            }

            if (inputLocalizacao.trim().length < 2) {
                setIsLocalizacao(true);
                valid = true;
            } else {
                setIsLocalizacao(false);
            }
            if (ufSelecionado === null || ufSelecionado == '') {
                setIsValidUf(true);
                valid = true;
            } else {
                setIsValidUf(false);
            }
            if (municipioSelecionado === null || municipioSelecionado == '') {
                setIsValidMunicipio(true);
                valid = true;
            } else {
                setIsValidMunicipio(false);
            }

            if (imputAreaTotal.length < 1) {
                setIsValidAreaTotal(true);
                valid = true;
            } else {
                const valor = Number(imputAreaTotal);
                if (valor == 0) {
                    setIsValidAreaTotal(true);
                    valid = true;
                } else {
                    setIsValidAreaTotal(false);
                }
            }

            if (valid) return;

            const cepSemMascara = imputCep == '' ? null : retiraMascaraMaterNumeros(imputCep);
            const jsonUa = {
                descricao: inpuNomeFazenda,    // POR ENQUANTO A DESCRICAO RECEBE O NOME DA FAZENDA MESMO
                idUsuario: idUsuarioProdutor,
                idCidade: null,
                idUf: ufSelecionado,
                nomeFazenda: inpuNomeFazenda,
                endereco: null,
                bairro: null,
                cep: cepSemMascara,
                localizacao: inputLocalizacao,
                areaTotal: imputAreaTotal,
                idMunicipio: municipioSelecionado,
                idTipoUsuario: ActionTypes.TIPO_USUARIO_PRODUTOR,
            }
            let response = null;

            setLoading(true);

            if (operacao == acaoUpdate) {
                response = await Api.putRequest(`api/ua/alterarPropriedade/${idUaP}`, jsonUa);
            } else {
                response = await Api.postRequest(`api/ua/cadastrarPropriedade`, jsonUa);
            }

            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                const idUsuario = res.idUsuario;
                const idUa = res.idUa;

                await opcaoUpdateCarregaTela(idUsuario, idUa);

                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();

            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe FormPropriedades metodo salvarPropriedades', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    const changeCEP = (e) => {
        const cep = e.target.value;
        const cepComMascara = mascaraCep(cep);
        setImputCep(cepComMascara);
    }

    const changeAreaTotal = (e) => {
        const val = e.target.value;
        const soNumeros = retiraMascaraMaterNumeros(val);
        setImputAreaTotal(soNumeros);
        setIsValidAreaTotal(val == 0 ? true : false);
    }

    const novaSafra = () => {
        const jsonParam = {
            idUa: idUaP,
            idUsuarioProdutor: idUsuarioProdutor,
            nomeProdutor: nomeProdutor,
            idSafra: null,
            nomeFazenda: inpuNomeFazenda,
            insertOuUpdate: ActionTypes.OPERACAO_INSERT,
        }
        //localStorage.setItem('@HE-dadosPropriedadeParaTelaCadastrarSafra', JSON.stringify(jsonParam));
        localStorage.setItem('@HE-propriedadeParaEdicao', JSON.stringify(jsonParam));
        history.push("/admin/cadastrar-safra");
    }

    const editarSafra = (item) => {
        const jsonParam = {
            idUa: item.idUa,
            idUsuarioProdutor: idUsuarioProdutor,
            nomeProdutor: nomeProdutor,
            idSafra: item.idSafra,
            nomeFazenda: inpuNomeFazenda,
            insertOuUpdate: ActionTypes.OPERACAO_UPDATE,
        }
        //localStorage.setItem('@HE-dadosPropriedadeParaTelaCadastrarSafra', JSON.stringify(jsonParam));
        localStorage.setItem('@HE-propriedadeParaEdicao', JSON.stringify(jsonParam));
        history.push("/admin/cadastrar-safra");
    }

    const changeNomeFazenda = (e) => {
        const val = e.target.value;
        setInpuNomeFazenda(val);
        setIsValidNomeFazenda(val == '' ? true : false);
    }

    const changeLocalizacao = (e) => {
        const val = e.target.value;
        setInputLocalizacao(val);
        setIsLocalizacao(val == '' ? true : false);
    }

    function renderPropriedade() {
        return (
            <Box px={'5px'} mt={'10px'} borderWidth={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.600'}>
                <Text color={'black'} mt={'10px'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Propriedade</Text>
                <Card direction='column' px='5px' py='5px'>
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='10px'>
                        <Box mt={{ base: 2, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Nome Fazenda *</Text>
                            <Input
                                value={inpuNomeFazenda}
                                onChange={(e) => changeNomeFazenda(e)}
                                isInvalid={isValidNomeFazenda}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box mt={{ base: 2, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Localização *</Text>
                            <Input
                                value={inputLocalizacao}
                                onChange={(e) => changeLocalizacao(e)}
                                isInvalid={isLocalizacao}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='5px'>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }}>
                                <Flex justify='start' align='center'>
                                    <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Área Total *</Text>
                                    <Center ml={'5px'} justifyContent={'start'} alignItems={'center'}>
                                        <TooltipIcon
                                            label={
                                                <Box px={'0px'} py={'5px'} >
                                                    <Text align={'center'}>{'Área Total'}</Text>
                                                    <Text align={'start'} mt={'5px'}>{'Medida da área em hectare'}</Text>
                                                </Box>
                                            }
                                            height={'18px'} />
                                    </Center>
                                </Flex>
                                <InputGroup size='md'>
                                    <Input
                                        value={imputAreaTotal}
                                        onChange={changeAreaTotal}
                                        isInvalid={isValidAreaTotal}
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        placeholder=""
                                        _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        type="text"
                                        maxLength={10}
                                        fontSize={'13px'}
                                        fontWeight={'bold'}
                                        h={'35px'}
                                    />
                                    <InputRightElement display='flex' alignItems='center'>
                                        <Text color={'gray.400'} fontSize={'15px'} fontWeight={'bold'} mr={'0px'}>{'(ha)'}</Text>
                                    </InputRightElement>
                                </InputGroup>
                            </Box>
                            <Box mt={{ base: 4, md: 0 }}>
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>CEP</Text>
                                <Input
                                    value={imputCep}
                                    onChange={changeCEP}
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                        </SimpleGrid>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }}>
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>UF *</Text>
                                <Select
                                    value={ufSelecionado}
                                    isInvalid={isValidUf}
                                    errorBorderColor='crimson'
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    onChange={changeUf}
                                >
                                    {LIST_UFS.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Box mt={{ base: 4, md: 0 }}>
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Município *</Text>
                                <Select
                                    value={municipioSelecionado}
                                    isInvalid={isValidMunicipio}
                                    errorBorderColor='crimson'
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    onChange={changeMunicipio}
                                    disabled={isDisabledMunicipio}
                                >
                                    {listMunicipio.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </Card>
                <Flex px={'10px'} justify={'end'} align={'center'} mb={'10px'}>
                    <Button variant="brand" onClick={salvarPropriedade}>Salvar</Button>
                </Flex>
            </Box>
        )
    }

    function renderListaSafra() {
        const fontP = '13px';
        const colorTextHeader = 'white';
        const espacoW = 0;
        return (
            <Box
                pointerEvents={operacao == acaoInsert ? 'none' : 'auto'}
                opacity={operacao == acaoInsert ? '0.5' : '1'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.600'}
                borderRadius={'20px'}
                boxShadow={shadowBlack}
                mt={'20px'}>
                <Card px='10px' py='10px'>
                    <Box position={'relative'}>
                        <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Safras</Text>
                        <Box position={'absolute'} top={'-1px'} right={'10px'}>
                            <Flex justify={'center'} align="center">
                                <Center mr={'5px'} justifyContent={'center'} alignItems={'center'}>
                                    <TooltipIcon
                                        label={
                                            <Box px={'0px'} py={'5px'} >
                                                <Text align={'center'}>{'Nova Safra'}</Text>
                                                <Text align={'start'} mt={'5px'}>{'Condições:'}</Text>
                                                <Text align={'start'} mt={'5px'} ml={'20px'} lineHeight='100%'>{'Para novo cadastro, não pode existir outra safra ATIVA para essa propriedade'}</Text>
                                            </Box>
                                        }
                                        height={'18px'} />
                                </Center>
                                <Button isDisabled={isExisteSangriaAtiva} variant="miniBrand" onClick={() => { novaSafra() }}>Nova Safra</Button>
                            </Flex>
                        </Box>
                    </Box>
                    <Flex direction="column" align="center" mt={'10px'}>
                        <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Table variant="striped" colorScheme='blackAlpha' size="sm" >
                                <Thead>
                                    <Tr h={'35px'} bgGradient={gradientHeader}>
                                        <Th w={'20%'} pl={'10px'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='start' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{'Nome'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'15%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='start' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Safra'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'15%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='start' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Sangria'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'20%'} pl={'10px'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Status'}</Text>
                                                <Center ml={'5px'} justifyContent={'end'} alignItems={'center'}>
                                                    <TooltipIcon
                                                        label={
                                                            <Box px={'0px'} py={'5px'} >
                                                                <Text align={'center'}>{'Status'}</Text>
                                                                <Text align={'start'} mt={'5px'}>{'Vigente:'}</Text>
                                                                <Text align={'start'} ml={'20px'} lineHeight='100%'>{'Indica que a safra está dentro do período de início e fim'}</Text>
                                                                <Text align={'start'} mt={'5px'}>{'Finalizada:'}</Text>
                                                                <Text align={'start'} ml={'20px'} lineHeight='100%'>{'Pode ocorrer poque ultrapassou a data fim, ou finalizada pelo usuário'}</Text>
                                                            </Box>
                                                        }
                                                        height={'18px'} color={'white'} />
                                                </Center>
                                            </Flex>
                                        </Th>
                                        <Th w={'20%'} pl={'10px'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Programação'}</Text>
                                                <Center ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                                    <TooltipIcon
                                                        label={
                                                            <Box px={'0px'} py={'5px'} >
                                                                <Text align={'center'}>{'Programação'}</Text>
                                                                <Text align={'start'} mt={'5px'}>{'Com programação:'}</Text>
                                                                <Text align={'start'} ml={'20px'} lineHeight='100%'>{'Indica que existe uma programação em andamento'}</Text>
                                                                <Text align={'start'} mt={'5px'}>{'Sem programação:'}</Text>
                                                                <Text align={'start'} ml={'20px'} lineHeight='100%'>{'O usuário ainda não iniciou uma programação'}</Text>
                                                                <Text align={'start'} mt={'5px'} color={'gray.400'}>{'Importante:'}</Text>
                                                                <Text align={'start'} ml={'20px'} lineHeight='100%' color={'gray.400'}>{`A programação deve ser processada a primeira vez pelo usuário, 
                                                                para iniciar acesse a safra e clique em programação, após iniciada, o sistema processará diariamente de forma automática, iniciando na data de início da sangria e finalizando na data fim da sangria`}</Text>
                                                            </Box>
                                                        }
                                                        height={'18px'} color={'white'} />
                                                </Center>
                                            </Flex>
                                        </Th>
                                        <Th w={'10%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center'>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{''}</Text>
                                            </Flex>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {listaSafra.map((item, index) => {

                                        const SHADOW_GREEN = '1px 1px #00b300';
                                        const SHADOW_RED = '1px 1px #ff0000';
                                        const SHADOW_YELLOW = '1px 1px #ffff00';

                                        const sangriaAtiv = item.sangriaAtiva != null ? item.sangriaAtiva : false;
                                        const programacaoEmAndamentoP = item.programacaoEmAndamento != null && item.programacaoEmAndamento != '' ? item.programacaoEmAndamento : false;
                                        const dtInicioSafra = item.dtInicio !== null && item.dtInicio !== '' ? moment(item.dtInicio).format('DD/MM/YYYY') : 'N/A';
                                        const dtFimSafra = item.dtFim !== null && item.dtFim !== '' ? moment(item.dtFim).format('DD/MM/YYYY') : 'N/A';
                                        const dtInicioSangria = item.dtInicioSangria !== null && item.dtInicioSangria !== '' ? moment(item.dtInicioSangria).format('DD/MM/YYYY') : 'N/A';
                                        const dtFimSangria = item.dtFimSangria !== null && item.dtFimSangria !== '' ? moment(item.dtFimSangria).format('DD/MM/YYYY') : 'N/A';

                                        let descStatus = '';
                                        let descProgramacao = '';
                                        let shadowColorProgramacao = '';
                                        let shadowStatus = '';

                                        if (sangriaAtiv == true) {
                                            if (item.vigente == true) {
                                                descStatus = 'Vigente';
                                                shadowStatus = SHADOW_GREEN;
                                            } else {
                                                descStatus = 'Finalizado';
                                                shadowStatus = SHADOW_RED;
                                            }
                                            if (programacaoEmAndamentoP == true) {
                                                descProgramacao = 'Com Programação';
                                                shadowColorProgramacao = SHADOW_GREEN;
                                            } else {
                                                descProgramacao = 'Sem Programação';
                                                shadowColorProgramacao = SHADOW_YELLOW;
                                            }
                                        } else {
                                            descStatus = 'Finalizado';
                                            shadowStatus = SHADOW_RED;
                                            descProgramacao = 'Sem Programação';
                                            shadowColorProgramacao = SHADOW_YELLOW;
                                        }
                                        return (
                                            <Tr key={index}>
                                                <Td pl={'10px'} sx={{ paddingX: espacoW }}>
                                                    <Flex justify='start' align='center'>
                                                        <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{item.nomeSafra}</Text>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex flexDirection={'column'} justify='center' align='start'>
                                                        <Flex justify='start' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSafra}</Text>
                                                        </Flex>
                                                        <Flex mt={'5px'} justify='center' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSafra}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex flexDirection={'column'} justify='center' align='start'>
                                                        <Flex justify='start' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSangria}</Text>
                                                        </Flex>
                                                        <Flex mt={'5px'} justify='start' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSangria}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex justify='center' align='center'>
                                                        <Text mr={'10px'} textShadow={shadowStatus} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descStatus}</Text>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex justify='center' align='center'>
                                                        <Text textShadow={shadowColorProgramacao} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descProgramacao}</Text>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex justify='center' align='center'>
                                                        <Button variant="miniBrand" onClick={() => { editarSafra(item) }}>Acessar</Button>
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                </Card>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                <Flex px='25px' justify='center' my='5px' align='center' >
                    <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                </Flex>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='start'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>

            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'10px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card px='0px' py='10px'>
            <Box position={'relative'}>
                <Flex px='25px' justify='space-between' mb='5px' align='center'>
                    <Flex px='2px' justify='start' mb='5px' align='center'>
                        <Text color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>Produtor:</Text>
                        <Text ml={'10px'} color='orange.700' fontSize='17px' fontWeight='700' lineHeight='100%'>{nomeProdutor}</Text>
                    </Flex>
                </Flex>
                {renderPropriedade()}
                {renderListaSafra()}
                {renderButton()}
                {openModalConfimacao && <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />}
                {loading && <Loader isOpen={loading} />}
                {isVisibleAlert && renderAlert()}
            </Box>
        </Card>
    );
}
